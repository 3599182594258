<template>
  <v-container fluid>
    <!-- <v-expansion-panel class="mb-3" inset v-model="custP">
      <v-expansion-panel-content class="CSP_BoarderStyle">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
          </div>
        </template>
        <v-layout column wrap>
          <v-flex class="mx-3">
            <v-layout>
              <v-flex xs12>
                <v-row>
                  <template>
                    <v-form v-model="valid">
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="firstname"
                              label="Item Name"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="4">
                            <v-select
                              :items="unitStatusList"
                              label="Find by status"
                              item-text="value"
                              item-value="id"
                              v-model="item_status"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </template>
                </v-row>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-flex xs12 sm12 md12 class="my-3">
              <v-layout justify-center row fill-height align-center>
                <v-btn outline round large>
                  <v-icon left dark>search</v-icon>Search
                </v-btn>
              </v-layout>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel> -->

    <v-container fluid>
      <v-card color="white">
        <v-layout row>
          <v-card flat>
            <v-badge left overlap color="transparent">
              <template v-slot:badge>
                <v-btn
                  fab
                  color="primary"
                  small
                  style="margin-top: -0.8em"
                  class="elevation-0"
                  >{{ getExamStoreItemUnitList.length }}</v-btn
                >
              </template>
              <v-card-text class="title">Unit List</v-card-text>
            </v-badge>
          </v-card>
          <v-divider class="my-2" vertical></v-divider>
          <v-spacer />
          <v-card-actions>
            <template>
              <v-row justify="center">
                <v-dialog v-model="dialogUnit" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="dialogUnit = true"
                    >
                      Add New Unit
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar>
                      <v-badge left overlap>
                        <v-card-text class="title">Add New Unit</v-card-text>
                      </v-badge>
                      <v-spacer />
                      <v-card-actions>
                        <v-btn
                          fab
                          class="ma-1 pa-0"
                          small
                          color="indigo white--text"
                          @click="(dialogUnit = false), cleartada()"
                        >
                          X
                        </v-btn>
                      </v-card-actions>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            label="Unit Name*"
                            required
                            v-model="unit.item_name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="itemStatusList"
                            label="Status*"
                            item-text="value"
                            item-value="id"
                            v-model="unit.id"
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                      <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="indigo white--text"
                        text
                        @click="editUnit(unit)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-card-actions>
        </v-layout>
      </v-card>

      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="getExamStoreItemUnitList"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:items="props">
          <tr>
            <td>{{ props.index + 1 }}</td>
            <td>{{ props.item.unit_name }}</td>
            <td v-if="props.item.unit_status == 1">Active</td>
            <td v-if="props.item.unit_status == 0">Inactive</td>
            <td align="center" justify="space-around">
              <!-- ....................................................................................... -->
              <v-btn fab class="ma-1 pa-0" small color="indigo white--text">
                <v-icon dark>edit</v-icon>
              </v-btn>
              <!-- ....................................................................................... -->
              <v-btn fab class="ma-1 pa-0" small color="red white--text">
                <v-icon dark>delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      unit: {
        item_name: "",
        id: "",
      },
      loading: false,
      dialogUnit: false,
      headers: [
        {
          text: "SL",
          value: "",
        },
        {
          text: "Unit Name",
          value: "unit_name",
        },
        {
          text: "Unit Status",
          value: "unit_status",
        },
        {
          text: "Action",
          value: "",
        },
      ],
      itemStatusList: [
        {
          id: 1,
          value: "Active",
        },
        {
          id: 0,
          value: "Inactive",
        },
      ],
    };
  },

  created() {
    console.log("kutta");
    // this.fetchExamStoreUnitsList();
  },
  mounted() {
    this.fetchExamStoreUnitsList();
  },
  computed: {
    getExamStoreItemUnitList() {
      return this.$store.getters.getExamStoreUnitList;
    },
  },
  watch: {
    getExamStoreItemUnitList(cv) {
      console.log("resp", cv);
    },
  },
  methods: {
    fetchExamStoreUnitsList() {
      this.loading = true;
      this.$store.dispatch("fetchExamStoreUnit").then(() => {
        this.loading = false;
      });
    },

    editUnit(unit) {
      console.log("dikkichiki=>", unit);
      this.$store.dispatch("postExamStoreUnit", unit).then(() => {
        this.fetchExamStoreUnitsList();
      });
    },

    cleartada(){
      this.unit = {};
    },
  },
};
</script>

<style>
</style>