<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm8 md8 lg8 class="mt-5 ml-3">
        <h3 class="display-2 text-sm-left">
          <b>Welcome to NU-EMS</b>
        </h3>
        <p class="title text-sm-left mt-1 ml-0">
          National University Examination Management System
        </p>
      </v-flex>
      <v-flex xs2></v-flex>
    </v-layout>
    <!-- <v-divider class="my-2"></v-divider> -->
    <!-- <h1>{{getAdminDashboardRoutin}}</h1>
       <h1>{{this.todaysDate}}</h1> -->
    <!-- <DashboardSummary></DashboardSummary> -->
    <!-- <v-card class="my-0"> </v-card> -->
    <v-layout row wrap class="mt-4" v-if="true">
      <v-container fluid>
        <v-card>
          <v-card color="white">
            <v-layout row>
              <v-card flat>
                <v-card-text class="title" style="margin-top:16px;"
                  >Today's Exam</v-card-text
                >
              </v-card>
              <v-divider class="my-2" vertical></v-divider>
              <v-spacer />
              <v-flex class="customeDateSearch" xs2>
                <v-menu :close-on-content-click="true">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      label="Select Exam date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="getRoutineatDasboard(date)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card>
          <v-data-table
            :headers="Rheaders"
            :items="getAdminDashboardRoutin"
            class="elevation-1"
            hide-actions
          >
            <template v-slot:items="props">
              <td class="text-xs-center">
                {{ props.item.exam_date ? props.item.exam_date : "" }}
              </td>
              <td class="text-xs-center">
                {{ props.item.exam_code ? props.item.exam_code : "" }}
              </td>
              <td class="text-xs-center">
                {{ props.item.exam_name ? props.item.exam_name : "" }}
              </td>
              <td class="text-xs-center">
                {{ props.item.exam_year ? props.item.exam_year : "" }}
              </td>

              <td class="text-xs-center">
                {{ props.item.start_time ? props.item.start_time : "" }}
              </td>
              <td class="text-xs-center">
                {{ props.item.exam_duration ? props.item.exam_duration : "" }}
              </td>
              <td class="text-xs-center">
                <v-layout row justify-center>
                  <v-dialog v-model="dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        @click="
                          getPaper(props.item.exam_code, props.item.papers)
                        "
                        dark
                        v-on="on"
                        >View</v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Exam Papers of </span
                        ><span class="headline"
                          ><b style="margin-left:5px;">{{ ExamCode }}</b></span
                        >
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-3" @click="dialog = false"
                          ><span style="color:#fff;">Close</span></v-btn
                        >
                      </v-card-title>
                      <v-card-text style="margin-top:-40px;">
                        <v-container>
                          <table id="rcitemList">
                            <thead
                              style="background-color:#2497A7; color:#fff;"
                            >
                              <th id="RCitem"><span>SL</span></th>
                              <th id="RCitem">Paper Code</th>
                              <th id="RCitem">Paper Name</th>
                              <th id="RCitem">Exam Date</th>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(paperitem, index) in getitemData"
                                :key="index"
                              >
                                <td id="RCitem">
                                  {{ index + 1 }}
                                </td>
                                <td id="RCitem">
                                  {{
                                    paperitem && paperitem.paper_code
                                      ? paperitem.paper_code
                                      : "Not found"
                                  }}
                                </td>
                                <td id="RCitem">
                                  {{
                                    paperitem && paperitem.paper_name
                                      ? paperitem.paper_name
                                      : "Not found"
                                  }}
                                </td>
                                <td id="RCitem">
                                  {{
                                    paperitem && paperitem.date
                                      ? paperitem.date
                                      : "Not found"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-container>
                      </v-card-text>
                      <!-- <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          flat
                          @click="dialog = false"
                          >Close 
                          </v-btn>
                      </v-card-actions> -->
                    </v-card>
                  </v-dialog>
                </v-layout>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-layout>
    <!-- {{ getDashboardData.exam_status }} -->
    <v-layout row wrap class="mt-4" v-if="true">
      <v-container fluid>
        <v-card>
          <v-card color="white">
            <v-layout row>
              <v-card flat>
                <v-card-text class="title">Exams Status</v-card-text>
              </v-card>
              <v-divider class="my-2" vertical></v-divider>
              <v-spacer />
            </v-layout>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="getDashboardData.exam_status"
            class="elevation-1"
            hide-actions
          >
            <template v-slot:headers>
              <tr>
                <th
                  v-for="(h, i) in headers"
                  :key="i"
                  class="text-center parent-header td-border-style"
                  :rowspan="h.children ? 1 : 3"
                  :colspan="h.children ? h.children.length : 1"
                >
                  {{ h.text }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="(h1, i1) in getSubHeader(headers)"
                  :key="i1"
                  :rowspan="i1 == 0 ? 2 : 1"
                  class="text-center child-header td-border-style"
                >
                  {{ h1.text }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="(h1, i1) in getSubHeader2(headers)"
                  :key="i1"
                  class="text-center child-header td-border-style"
                >
                  {{ h1.text }}
                </th>
              </tr>
            </template>
            <template v-slot:items="props">
              <td class="text-xs-left">
                {{ props.item.exam_code }} - {{ props.item.exam_name }}
              </td>
              <td class="text-xs-center">{{ props.item.exam_year }}</td>
              <td class="text-xs-center">
                {{ props.item.exam_start_date }}
              </td>
              <td class="text-xs-center">
                <div>{{ props.item.ff_start_date }}</div>
                {{ props.item.ff_end_date }}
              </td>
              <td class="text-xs-center">
                <div>{{ props.item.rou_start_date }}</div>
                {{ props.item.rou_end_date }}
              </td>
              <!-- <td class="text-xs-center"></td> -->
              <!-- <td class="text-xs-center">
                <div>{{ props.item.prob_start_date }}</div>
                {{ props.item.prob_end_date }}
              </td> -->
              <!-- <td class="text-xs-center"></td> -->
              <td class="text-xs-center">
                <div>{{ props.item.rg_start_date }}</div>
                {{ props.item.rg_end_date }}
              </td>
               <!-- center -->
              <td class="text-xs-center">
                <div>{{ props.item.cen_start_date }}</div>
                {{ props.item.cen_end_date }}
              </td>
             
              <!-- <td class="text-xs-center">{{ props.item.cen_end_date }}</td> -->
    
             
              <!-- <td class="text-xs-center">{{ props.item.rou_end_date }}</td> -->
              <td class="text-xs-center">
                <div>{{ props.item.adm_start_date }}</div>
                {{ props.item.adm_end_date }}
              </td>
              <!-- <td class="text-xs-center">{{ props.item.adm_end_date }}</td> -->
              <td class="text-xs-center">
                <div>{{ props.item.em_start_date }}</div>
                {{ props.item.em_end_date }}
              </td>
              <!-- <td class="text-xs-center">{{ props.item.em_end_date }}</td> -->
              <td class="text-xs-center">
                <div>{{ props.item.pv_start_date }}</div>
                {{ props.item.pv_end_date }}
              </td>
              <!-- <td class="text-xs-center">{{ props.item.pv_end_date }}</td> -->
              
              <!-- <td class="text-xs-center">{{ props.item.rg_end_date }}</td> -->
              <!-- <td class="text-xs-center">
                <div>
                  {{ props.item.ra_start_date }}
                </div>
                {{ props.item.ra_end_date }}
              </td> -->
              <!-- <td class="text-xs-center">{{ props.item.ra_end_date }}</td> -->
              <!-- <td class="text-xs-center">
                <div>{{ props.item.rrp_start_date }}</div>
                {{ props.item.rrp_end_date }}
              </td> -->
              <!-- <td class="text-xs-center">{{ props.item.rrp_end_date }}</td> -->
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import common_login_getters from "@/mixin/common_login_getters";
import DashboardSummary from "./DashboardSummary";
export default {
  mixins: [common_login_getters],
  components: {
    DashboardSummary
  },
  data: () => ({
    date: "",
    ExamCode: "",
    peparData: [],
    dialog: false,
    calendarStatus: false,
    todaysDate: "",
    Pheaders: [
      {
        text: "Paper Code",
        value: "paper_code",
        align: "left",
        sortable: false
      },
      {
        text: "Paper Name",
        value: "paper_name",
        align: "center",
        sortable: false
      },
      {
        text: "Exam Date",
        value: "date",
        align: "center",
        sortable: false
      }
    ],
    Rheaders: [
      {
        text: "Start Date",
        value: "exam_date",
        align: "center",
        sortable: false
      },
      {
        text: "Exam Code",
        value: "exam_code",
        align: "center",
        sortable: false
      },
      {
        text: "Exam Name",
        value: "exam_name",
        align: "center",
        sortable: false
      },
      {
        text: "Exam Year",
        value: "exam_year",
        align: "center",
        sortable: false
      },
      {
        text: "Exam Time",
        value: "start_time",
        align: "center",
        sortable: false
      },
      {
        text: "Exam Duration",
        value: "exam_duration",
        align: "center",
        sortable: false
      },
      {
        text: "Action",
        value: "",
        align: "center",
        sortable: false
      }
    ],
    headers: [
      {
        text: "Exam code & Name",
        value: "exam_code",
        align: "left",
        sortable: false
      },
      {
        text: "Year",
        value: "year",
        align: "center",
        sortable: false
      },
      {
        text: "Examination",
        value: "total_student",
        align: "center",
        sortable: false,

        children: [
          {
            text: "Start Date"
          }
        ]
      },
      {
        text: "Form fill-up ",
        value: "total_student",
        align: "center",
        sortable: false,

        children: [
          {
            text: "Start Date"
          }
        ],
        children2: [
          {
            text: "End Date"
          }
        ]
      },
      {
        text: "Theory Exam",
        value: "total_student",
        align: "center",
        sortable: false,
        children2: [
          {
            text: "End Date"
          }
        ],
        children: [
          {
            text: "Start Date"
          }
        ]
      },
      // text: "Probable (Start Date - End Date)",
      // {
      //   text: "Probable",
      //   value: "total_student",
      //   align: "center",
      //   sortable: false,
      //   children: [
      //     {
      //       text: "Start Date"
      //     }
      //   ],
      //   children2: [
      //     {
      //       text: "End Date"
      //     }
      //   ]
      // },
      {
        text: "Result Generate",
        value: "total_student",
        align: "center",
        sortable: false,
        children: [
          {
            text: "Start Date"
          }
        ],
        children2: [
          {
            text: "End Date"
          }
        ]
      },
      {
        text: "Center Allocation ",
        value: "total_student",
        align: "center",
        sortable: false,
        children: [
          {
            text: "Start Date"
          }
        ],
        children2: [
          {
            text: "End Date"
          }
        ]
      },
      
      
      {
        text: "Admit Card",
        value: "total_student",
        align: "center",
        sortable: false,
        children: [
          {
            text: "Start Date"
          }
        ],
        children2: [
          {
            text: "End Date"
          }
        ]
      },
      {
        text: "Examiner Mark  ",
        value: "total_student",
        align: "center",
        sortable: false,
        children: [
          {
            text: "Start Date"
          }
        ],
        children2: [
          {
            text: "End Date"
          }
        ]
      },
      {
        text: "Practical-Viva Mark",
        value: "total_student",
        align: "center",
        sortable: false,
        children: [
          {
            text: "Start Date"
          }
        ],
        children2: [
          {
            text: "End Date"
          }
        ]
      },
      // {
      //   text: "Rescrutiny Apply",
      //   value: "total_student",
      //   align: "center",
      //   sortable: false,
      //   children: [
      //     {
      //       text: "Start Date"
      //     }
      //   ],
      //   children2: [
      //     {
      //       text: "End Date"
      //     }
      //   ]
      // },
      // {
      //   text: "Rescrutiny Result Processing",
      //   value: "total_student",
      //   align: "center",
      //   sortable: false,
      //   children: [
      //     {
      //       text: "Start Date"
      //     }
      //   ],
      //   children2: [
      //     {
      //       text: "End Date"
      //     }
      //   ]
      // }
    ]
  }),
  watch: {
    calendarStatus(val) {
      console.log("calendarStatus", val);
    }
  },
  created() {
    this.currentDate();
    this.getdata();
    this.getRoutineatDasboard();
  },
  computed: {
    getAdminDashboardRoutin() {
      return this.$store.getters.getAdminDashboardRoutin;
    },
    getitemData() {
      return this.peparData;
    },
    getDashboardData() {
      return this.$store.getters.getDashboardData;
    }
  },
  methods: {
    // currentDate(date){
    //   console.log("iLA, Tanvir, Jadu!!!!!!!!!", date)
    // },
    getPaper(ExamCode, peparData) {
      this.ExamCode = ExamCode;
      this.peparData = peparData;
      console.log("tanvir pepear", this.peparData);
    },

    currentDate() {
      const current = new Date().toJSON().slice(0, 10);
      this.todaysDate = current;
      console.log("Tanvir_Date", this.todaysDate);
      return this.todaysDate;
    },
    goto(link) {
      this.$router.push(link);
    },
    getRoutineatDasboard(date) {
      if (date) {
        this.$store
          .dispatch("getRoutineAtAdminDashboard", {
            exam_date: date
          })
          .then(() => {
            console.log("tanvir", date);
          });
      } else {
        this.$store
          .dispatch("getRoutineAtAdminDashboard", {
            exam_date: this.todaysDate
          })
          .then(() => {
            console.log("tanvir", this.todaysDate);
          });
      }
    },
    // SearchByDate(date){
    //   // console.log(date);
    //   this.$store
    //     .dispatch("getRoutineAtAdminDashboard",  {exam_date:date} )
    //     .then(() => {
    //       console.log("tanvir", date);
    //     });
    // },
    getdata() {
      // this.getProgressLoading = true;
      this.$store.dispatch("getDashData").then(() => {
        // this.getProgressLoading = false;
      });
    },

    getMainHeader(headers) {
      return headers.filter(i => i.children);
    },
    getSubHeader(headers) {
      let result = [];
      headers
        .filter(i => i.children)
        .forEach(v => {
          result = result.concat(v.children);
        });
      console.log("result", result);
      return result;
    },
    getSubHeader2(headers) {
      let result = [];
      headers
        .filter(i => i.children2)
        .forEach(v => {
          result = result.concat(v.children2);
        });
      console.log("result", result);
      return result;
    }
  }
};
</script>

<style>
.customeDateSearch {
  margin-top: 10px;
  margin-right: 20px;
}

#rcitemList {
  text-align: center;
  /* margin-left: 3%; */
}

#RCitem {
  padding: 10px;
  font-size: 18px;
}
</style>
