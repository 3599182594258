<template>
  <v-container fluid>
    <v-card color="white">
      <v-layout row>
        <v-card flat>
          <v-badge left overlap color="transparent">
            <template v-slot:badge>
              <v-btn
                fab
                color="primary"
                small
                style="margin-top: -0.8em"
                class="elevation-0"
                ></v-btn
              >
            </template>
            {{ getExamStoreItemIssueList.length}}
            <v-card-text class="title">Issued Items Return List</v-card-text>
          </v-badge>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-card-actions>
          <template>
            <issuedItemReturnFrom />
          </template>
        </v-card-actions>
      </v-layout>
    </v-card>

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="getExamStoreItemIssueList"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:items="props">
        <tr>
        </tr>
        <tr>
          <td>{{ props.index + 1 }}</td>
          <td>{{ props.item.center_code }}</td>
          <td>{{ props.item.college_name }}</td>
          <td>{{ props.item.college_code }}</td>
          <td>{{ props.item.received_by }}</td>
          <td>{{ props.item.remarks }}</td>
          <td v-if="props.item.issue_type == 1">External</td>
          <td v-if="props.item.issue_type == 0">Internal</td>
          <td>{{ props.item.issue_no}}</td>
          <td>{{ props.item.issue_date}}</td>
          <td>{{ props.item.challan_no}}</td>
          <td>{{ props.item.get_pass_no}}</td>
          <td align="center">
            <template>
                  <!-- <v-row justify="center"> -->
                  <v-dialog v-model="dialog" persistent max-width="528px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-1 pa-0"
                        color="indigo white--text"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="getreceiveditem(props.item.item_details)"
                      >
                        <v-icon dark>visibility</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar>
                        <v-badge left overlap>
                        <v-card-text class="title">Issued Item</v-card-text>
                        </v-badge>
                        <v-spacer />
                        <v-card-actions>
                          <v-btn
                            fab
                            class="ma-1 pa-0"
                            small
                            color="indigo white--text"
                            @click="dialog = false"
                          >
                            X
                          </v-btn>
                        </v-card-actions>
                      </v-toolbar>
                      <v-card-text>
                        <!-- <v-row> -->
                        <template align="center">
                            <table id="rcitemList">
                              <thead>
                                <tr>
                                  <th id="RCHitem">SL</th>
                                  <th id="RCHitem">Item Code</th>
                                  <th id="RCHitem">Item Name</th>
                                  <th id="RCHitem">Issued Quantity</th>
                                  <th id="RCHitem">Unit In</th>
                                  <th id="RCHitem">Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(rcitem, index) in updateItemListtada"
                                  :key="index"
                                >
                                  <td id="RCitem">
                                    {{ index + 1 }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem &&
                                      rcitem.item &&
                                      rcitem.item.item_code
                                        ? rcitem.item.item_code
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem &&
                                      rcitem.item &&
                                      rcitem.item.item_name
                                        ? rcitem.item.item_name
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem && rcitem.issued_quantity
                                        ? rcitem.issued_quantity
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem && rcitem.unit.unit_name
                                        ? rcitem.unit.unit_name
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem && rcitem.remarks
                                        ? rcitem.remarks
                                        : "Not found"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import issuedItemReturnFrom from "./issuedItemReturnFrom.vue";
import commonGet from "../../mixin/common_getters_mixin";
import createCenterAllocation from "@/components/center/createCenterAllocationForm.vue";
import common_login_getters from "@/mixin/common_login_getters";

export default {
  mixins: [
    commonGet,
    common_login_getters,
  ],
  components: {
    createCenterAllocation,
    issuedItemReturnFrom,
  },
  data() {
    return {
      searchParam: {},
      loading: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      updateItemListtada: [],
      headers:[
        {
          text: "SL",
          value: "",
        },
        {
          text: "Center Code",
          value: "",
        },
        {
          text: "Center Name",
          value: "",
        },
        {
          text: "College Code",
          value: "",
        },
        {
          text: "Returned By",
          value: "",
        },
        {
          text: "Received By",
          value: "",
        },
        {
          text: "Remarks",
          value: "",
        },
        {
          text: "Slip No",
          value: "",
        },
        {
          text: "Returnd Date",
          value: "",
        },
        {
          text: "View Returnd Items",
          value: "",
        },
      ],
    };
  },

  created(){
    this.searchParam.exam_initiate_id = this.getExamInitiateId;
    this.searchParam.exam_code = this.getLoggedInExam.code;
    this.searchParam.year = this.getLoggedInYear.year;
    //all  list
    console.log("pramrams->", this.$route.params);
    if (this.getRouterParam === "practical") {
      this.searchParam.type = 2;
    } else if (this.getRouterParam === "viva") {
      this.searchParam.type = 3;
    } else {
      this.searchParam.type = 1;
    }
    // this.fetchexamissueditemlistdata();
    this.searchCenterAllocationList();
  },

  computed:{
    getExamStoreItemIssueList(){
      return this.$store.getters.getExamStoreItemIssueList ?
      this.$store.getters.getExamStoreItemIssueList:[];
    },
     getitemData() {
      return this.updateItemListtada;
    },
  },

  watch:{

  },

  methods:{
    //get issued list and issued item  list
    fetchexamissueditemlistdata(){
      this.loading = true;
      this.$store.dispatch("fetchExamStoreItemIssueList").then(()=>{
        this.loading = false;
      })
    },

    async getreceiveditem(tada) {
      this.updateItemListtada = await tada;
      console.log(tada);
      return updateItemListtada
      
    },

    searchCenterAllocationList() {
      console.log("this.searchParam", this.searchParam)
      this.$store
        .dispatch("searchCenterAllocationData",this.searchParam)
    },
  },
};

</script>

<style>
#rcitemList {
  text-align: center;
  /* margin-left: 2%; */
}
#RCHitem{
  font-size: 18px;
  padding-left:5px;
   padding-bottom:5px;
}
#RCitem {
  padding: 10px;
  font-size: 16px;
}
</style>