import Axios from "axios";

import { GET_STUDENT_INFO } from "../../../mutation-types";
import { RegistrationBasics,updataStudents, ExportRegCardCorHistory } from "../../../api";
import { saveMsg } from "../../../erpUtil";
import { failedMsg } from "../../../erpUtil";

const state = {
  studentInfo: []
};

const getters = {
  getStudentData(state) {
    return state.studentInfo;
  }
};

const mutations = {
  //get data from api
  [GET_STUDENT_INFO](state, result) {
    state.studentInfo = result;
  }
};

const actions = {
  fetchStudentInfo({ commit }, searchParam) {
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios.get(RegistrationBasics, { params: searchParam })
        .then(function(response) {
          commit("UNSET_LOADING");
          let result = response.data.data;
          commit("GET_STUDENT_INFO", result);

          if(response.data.meta.total == 0){
            commit("SET_SNACKBAR", {
              msg: 'Data Not Found !',
              color: "red"
            });
          }         
          resolve(response.data);
        })
        .catch(function(error) {            
          console.log(error);
          reject();
        });
    });
  },

  updateStudents({ commit }, data) {
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", {
        color: "primary"
      }); // loading Opening
      Axios.post(updataStudents, data)
        .then(function(response) {
          commit("UNSET_LOADING");
          commit("GET_STUDENT_INFO", []);
          if (response.data.status === "success") {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
          } else {
            commit("SET_SNACKBAR", {
              msg: failedMsg,
              color: "red"
            });
          }
          resolve();
        })
        .catch(function(error) {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: failedMsg,
            color: "red"
          });
          console.log(error);
          reject();
        });
    });
  },

  exportCorrectionRgCardHistory({ commit, state }, schema) {
    const search = schema;
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios({
        url: ExportRegCardCorHistory,
        method: "GET",
        responseType: "arraybuffer",
        params: search
      })
        .then(response => {
          commit("UNSET_LOADING"); // loading Closing

          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "reg_card_corr_history" + Date.now() + ".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          console.log(error);
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
