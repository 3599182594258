<template>
  <v-container fluid>
    <v-container fluid>
      <v-expansion-panel v-model="panel" expand>
        <v-expansion-panel-content class="mycustomExpansionPanel">
          <template v-slot:actions>
            <v-icon large color="primary">$vuetify.icons.expand</v-icon>
          </template>
          <template v-slot:header>
            <div>
              <v-icon color="primary">search</v-icon>
              <span class="pl-2 subheading">Custom Search Panel</span>
            </div>
          </template>
          <v-layout row wrap align-center>
            <v-layout row wrap align-center>
              <v-flex>
                <v-card class="px-3">
                  <v-form>
                    <v-layout row wrap align-center class="py-3">
                      <v-flex md10>
                        <v-text-field
                          v-model="getTCStdFm.reg_no"
                          prepend-inner-icon="search"
                          label="Registration Number"
                          class="mt-0"
                          v-validate="'required'"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md2>
                        <v-layout justify-center row fill-height align-center>
                          <v-btn color="primary" large @click.stop.prevent="validationFrom()"
                          >Search</v-btn
                          >
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>
    <v-container fluid class="mt-2">
      <v-card color="white" v-if="authStudent">
        <v-layout row wrap>
          <v-flex xs8 sm8 md8 layout>
            <div class="mx-4 my-2">
              <h3>
                <span class="display-1">{{ authStudent.name }}</span>
              </h3>
              <div>ID:{{ authStudent.std_id }}</div>
              <div>REG. No:{{ authStudent.reg_no }}</div>
              <div>Roll No:{{ authStudent.admission_roll }}</div>
<!--              <div v-if="authStudent.hasOwnProperty('student_types')">-->
<!--                Student Type: {{ authStudent.student_types.student_type }}-->
<!--              </div>-->
            </div>
          </v-flex>
          <v-flex xs4 sm4 md4 layout>
            <v-layout>
              <v-avatar
                style="float: right"
                lass="mr-4"
                :tile="tile"
                :size="avatarSize"
                color="grey lighten-4"
              >
                <img
                  :src="
                authStudent.photo_url
                  ?  authStudent.photo_url
                  : 'https://via.placeholder.com/1000'
              "
                  alt="avatar"
                />
              </v-avatar>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-tabs v-model="active" slider-color="black">
          <v-tab ripple>Basic Info</v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-list>
                  <v-list-tile-sub-title
                    v-if="authStudent.hasOwnProperty('college')"
                  >
                    <span class="text--primary">College :</span>
                    {{ authStudent.college.college_name }}({{
                      authStudent.college_code
                    }})
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title
                    v-if="authStudent.hasOwnProperty('degree')"
                  >
                    <span class="text--primary">Degree :</span>
                    {{ authStudent.degree.degree_name }}({{
                      authStudent.degree.degree_code
                    }})
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title
                    v-if="authStudent.hasOwnProperty('degree_group')"
                  >
                    <span class="text--primary">Degree Group :</span>
                    {{ authStudent.degree_group.degree_group_name }}({{
                      authStudent.degree_group.degree_group_code
                    }})
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title
                    v-if="authStudent.hasOwnProperty('subjects')"
                  >
                    <span class="text--primary">Subject :</span>
                    <slot v-if="authStudent.subject">
                  <span
                  >{{ authStudent.subject.subject_name }} ({{
                      authStudent.subject.subject_code
                    }})</span
                  >
                    </slot>
                    <slot v-else-if="authStudent.subjects">
                      <slot v-for="(subject, index) in authStudent.subjects">
                    <span v-if="!index" :key="index"
                    >{{ subject.subject_name }} ({{
                        subject.subject_code
                      }})</span
                    >
                        <span v-else :key="index"
                        >,{{ subject.subject_name }} ({{
                            subject.subject_code
                          }})</span
                        >
                      </slot>
                    </slot>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <span class="text--primary">Session :</span>
                    <span>{{ authStudent.ac_session }}</span>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <span class="text--primary">Session Expire :</span>
                    <span>{{ authStudent.ac_session_expire }}</span>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <span class="text--primary">Email :</span>
                    <span>{{ authStudent.email }}</span>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <span class="text--primary">Mobile :</span>
                    <span>{{ authStudent.mobile }}</span>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <span class="text--primary">Father Name :</span>
                    <span>{{ authStudent.father_name }}</span>
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                <span v-if="authStudent.mother_name" class="text--primary"
                >Mother Name :</span
                >
                    <span>{{ authStudent.mother_name }}</span>
                  </v-list-tile-sub-title>
                </v-list>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab ripple>Academic Info</v-tab>
          <v-tab-item>
            <v-card flat>
              <v-data-table
                :hide-actions="true"
                :headers="headers"
                :items="authStudent.student_academic_info"
                class="elevation-1"
              >
                <template v-slot:items="props">
                  <td>{{ props.index + 1 }}</td>
                  <td class="text-xs">{{ props.item.std_id }}</td>
                  <td class="text-xs">{{ props.item.prev_degree_name }}</td>

                  <td class="text-xs">{{ props.item.prev_reg_no }}</td>
                  <td class="text-xs">{{ props.item.prev_roll_no }}</td>
                  <td class="text-xs">{{ props.item.prev_passing_year }}</td>
                  <td class="text-xs">{{ props.item.prev_board }}</td>
                  <td class="text-xs">{{ props.item.prev_institute_name }}</td>
                  <td class="text-xs">{{ props.item.prev_gpa }}</td>
                  <td class="text-xs">{{ props.item.prev_total_mark }}</td>
                  <td class="text-xs">{{ props.item.prev_result }}</td>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <v-form style="padding: 1.2rem;">
          <v-layout row wrap align-center>
            <v-flex md12>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="getTCStdFm.migration_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="getTCStdFm.migration_date"
                    label="Select Migration Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="getTCStdFm.migration_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(getTCStdFm.migration_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex md12>
              <v-text-field
                v-model="getTCStdFm.tracking_id"
                label="Tracking NUmber"
                class="mt-0"
                v-validate="'required'"
              ></v-text-field>
            </v-flex>

            <v-flex md12>
              <v-textarea
                v-model="getTCStdFm.remark"
                label="Remark"
                class="mt-0"
                rows="1"
                v-validate="'required'"
              ></v-textarea>
            </v-flex>

            <v-flex md12>
              <v-checkbox v-model="getTCStdFm.check_condition" v-validate="'required'" label="ছাড়পত্র অনুমোদন আছে?"></v-checkbox>
            </v-flex>

            <v-flex md2 v-if="getTCStdFm.check_condition">
              <v-layout row fill-height>
                <v-btn color="success" large @click.stop.prevent="submitTCStdRecord()"
                >Submit</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import commonGet from "@/mixin/college_common_getters_mixin";
export default {
  name: "TCStudent",
  mixins: [commonGet],
  data() {
    return {
      active: null,
      panel: [true],
      searchParam: {},
      totalDataItems: 0,
      loading: true,
      loadData: false,
      limit: {},
      authStudent: null,
      slider: 156,
      tile: true,
      modal: false,
      menu: false,
    }
  },
  computed: {
    getProbableSchema() {
      return this.$store.getters.getProbableSchema;
    },
    getTCStdParams() {
      return this.$store.getters.getTCParms;
    },
    getTCStdFm() {
      return this.$store.getters.getTCStdFm;
    },
    avatarSize() {
      return `${this.slider}px`;
    }
  },
  methods: {
    validationFrom() {
      this.authStudent = null;
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.studentSearchRecord();
        }
      })
    },
    submitTCStdRecord() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.requestTCStdSubmit();
        }
      })
    },
    requestTCStdSubmit() {
      try {
        this.loading = true;
        this.$store.dispatch("requestTCStdFm").then(data => {
          this.loading = false;
          console.log(data)
        })
      }catch (e) {
        console.log(e)
      }
    },
    studentSearchRecord() {
      try {
        this.loading = true;
        this.$store.dispatch("getTCStudentRecords").then(data => {
          this.loading = false;
          this.authStudent = data.data;
        });
      } catch (ex) {
        console.log(ex)
      }
    }
  }
};
</script>

<style scoped>

</style>