import Axios from "axios";
import {
  SCRUTINIZER_DATA_LIST,
  DESIGNATION_LIST_DATA,
  SCRUTINIZER_DATA_LIST_EDIT,
} from "../../mutation-types";
import { scrutinizerList, designationList, saveScrutData } from "../../api";

const state = {
  scrutinizerData: [],
  scrutinizerDataEdit: [],
  designationData: [],
};

const getters = {
  dataOfscrutinizerList() {
    return state.scrutinizerData;
  },
  dataOfscrutinizerListEdit() {
    return state.scrutinizerDataEdit;
  },
  dataOfdesignationList() {
    return state.designationData;
  },
};

const mutations = {
  [SCRUTINIZER_DATA_LIST](state, result) {
    state.scrutinizerData = result;
  },
  [SCRUTINIZER_DATA_LIST_EDIT](state, result) {
    state.scrutinizerDataEdit = result;
  },
  [DESIGNATION_LIST_DATA](state, result) {
    state.designationData = result;
  },
};

const actions = {
  fetchScrutinizerData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      // loading  Opening Scrutinizer List
      Axios.get(scrutinizerList, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data;
          console.log("Tanvir, this is scrutinizer->", result);
          commit("SCRUTINIZER_DATA_LIST", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  fetchDesignationData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      Axios.get(designationList)
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data;
          console.log("Tanvir, this is designation->", result);
          commit("DESIGNATION_LIST_DATA", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  // fetchScrutinizerData({ commit }, searchParam) {
  //   return new Promise((resolve, reject) => {
  //     commit("SET_LOADING", {
  //       color: "primary",
  //     });
  //     Axios.get(scrutinizerList,  {
  //       params: searchParam,
  //     })
  //       .then(function (response) {
  //         commit("UNSET_LOADING"); // loading Closing
  //         var result = response.data;
  //         console.log("Tanvir, this is designation->", result);
  //         commit("DESIGNATION_LIST_DATA", result);
  //         resolve(response.data);
  //       })
  //       .catch(function (error) {
  //         commit("UNSET_LOADING"); // loading Closing
  //         console.log(error);
  //         reject();
  //       });
  //   });
  // },

  postScrutinizerData({ commit }, peram) {
    console.log("This is from api", peram);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary"
      });
      Axios.post(scrutinizerList, peram)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, scrutinizer created success!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Scrutinizer create failed. Please try again !!!",
            color: "red"
          });
          reject(error)
        });
    });
  },

  fetchEditScrutData({ commit }, searchParam){
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      // loading Opening Scrutinizer Edit
      Axios.get(scrutinizerList, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data;
          console.log("Tanvir, this is scrutinizer->", result);
          commit("SCRUTINIZER_DATA_LIST_EDIT", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  updateScrutData({ commit }, schema){
    let data = schema;
    //const url = `${faqDataApi}/${data.id}`;
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      }); // loading Opening
      Axios.put(scrutinizerList, data)
        .then(function(response) {
          commit("UNSET_LOADING"); // loading Closing
          if (response.data.status === "success") {
            commit("SET_SNACKBAR", {
              msg: updateMsg,
              color: "green"
            });
            resolve();
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red"
            });
            reject();
          }
        })
        .catch(function(error) {
          commit("UNSET_LOADING"); // loading Closing
          commit("SET_SNACKBAR", {
            msg: failedMsg,
            color: "red"
          });
          console.log(error);
          reject();
        });
    });
  },
  
  postScrutinizerMarksData({ commit }, peram) {
    console.log("This is from api", peram);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary"
      });
      Axios.post(saveScrutData, peram)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, scrutinizer mark save success!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Scrutinizer mark save failed. Please try again !!!",
            color: "red"
          });
          reject(error)
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
