import Axios from "axios";
import {
  GET_ADMIT_CARD_DATA_FOR_CURRECTION,
  GET_ADMIT_CARD_CURRECTIONED_DATA,
  SET_ADMIT_CARD_PEP_CORRECTIONED_DATA,
  SET_ADMIT_CARD_PEP_CORRECTIONED_DETAILS_DATA,
} from "../../mutation-types";
import {
  AdmitCardUrl_All,
  AdmitCorrectionData,
  generateCorrAdmit,
  AdmitCardPepCorrectionData,
  AdmitCardPepCorrectionSave,
  AdmitCardPepCorrectionDetails,
  ApproveAdmitCardPepCorrection
} from "../../api";


const state = {
  admitCardCurrection: [],
  admitCardCorrectionedData: [],
  admitCardPepCorrData: [],
  admitCardPepCorrDetails: [],
};
const getters = {
  getAdmitCardDataForCurrection() {
    return state.admitCardCurrection;
  },
  getAdmitCardCurrectionData() {
    return state.admitCardCorrectionedData;
  },
  getAdmitCardPaperCorrData() {
    return state.admitCardPepCorrData;
  },
  getAdmitCardPaperCorrDetailsData() {
    return state.admitCardPepCorrDetails;
  },
};
const mutations = {
  [GET_ADMIT_CARD_DATA_FOR_CURRECTION](state, result) {
    state.admitCardCurrection = result;
  },
  [GET_ADMIT_CARD_CURRECTIONED_DATA](state, result) {
    state.admitCardCorrectionedData = result;
  },
  [SET_ADMIT_CARD_PEP_CORRECTIONED_DATA](state, result) {
    state.admitCardPepCorrData = result;
  },
  [SET_ADMIT_CARD_PEP_CORRECTIONED_DETAILS_DATA](state, result) {
    state.admitCardPepCorrDetails = result;
  },
};
const actions = {
  fetchAdmitDataForCurrection({
    commit
  }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      // loading  Opening AdmitCardUrl_All
      Axios.get(AdmitCardUrl_All, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data;
          console.log("Tanvir, this is admit data->", result);
          commit("GET_ADMIT_CARD_DATA_FOR_CURRECTION", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  fecthAdmitCorrectedData({
    commit
  }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      // loading  Opening
      Axios.get(AdmitCorrectionData, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data.data;
          console.log("Tanvir, this is admit data->", result);
          commit("GET_ADMIT_CARD_CURRECTIONED_DATA", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  fecthAdmitPepCorrectedData({
    commit
  }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      // loading  Opening
      Axios.get(AdmitCardPepCorrectionData, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data.data;
          console.log("Tanvir, this is admit data->", result);
          commit("SET_ADMIT_CARD_PEP_CORRECTIONED_DATA", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  postAdmitCorrectedData({ commit }, peram) {
    console.log("This is from api", peram);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary"
      });
      Axios.post(AdmitCorrectionData, peram)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, Admin assinged subject to Department successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Subject assigne failed. Please try again !!!",
            color: "red"
          });
          reject(error)
        });
    });
  },

  fecthAdmitPepCorrectionDetailsData({
    commit
  }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      // loading  Opening
      Axios.get(AdmitCardPepCorrectionDetails, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data.data;
          console.log("Tanvir, this is admit correction details data->", result);
          commit("SET_ADMIT_CARD_PEP_CORRECTIONED_DETAILS_DATA", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  approveAdmitCorrectedData({
    commit
  }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      // loading  Opening
      Axios.get(ApproveAdmitCardPepCorrection, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          if (response.data.status == "success") {
            console.log("Tanvir, Admit Approve successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  postAdmitPepCorrectedData({ commit }, peram) {
    console.log("This is from api", peram);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary"
      });
      Axios.post(AdmitCardPepCorrectionSave, peram)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, Admin Pepar corrected successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Subject assigne failed. Please try again !!!",
            color: "red"
          });
          reject(error)
        });
    });
  },

  generateAdmitCorrectedData({ commit }, items) {
    console.log("This is from api", items);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary"
      });
      Axios.post(generateCorrAdmit, items)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, Admin generated successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Admit Generate failed. Please try again !!!",
            color: "red"
          });
          reject(error)
        });
    });
  },












};


export default {
  state,
  getters,
  actions,
  mutations,
};