<template>
<v-container fluid>
    <v-layout row wrap align-center>
        <v-flex xs12 md12 style="background-color:#3A4BA9; color:#fff;">
            <v-card-text class="title">Select Recipents</v-card-text>
        </v-flex>
        <v-flex xs12 md12>
            <v-card class="px-3">
                <v-divider class="my-2" vertical></v-divider>
                <v-layout row wrap align-center>
                    <v-flex xs12 md8>
                        <v-layout row wrap align-center style="margin-left: 45%; padding-bottom: 20px; padding-top: 20px">
                            <v-select v-model="searchParam.type" :items="recipentsData" label="Recipents here" prepend-icon="list" @input="getrecipents()" outline></v-select>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>
        <!-- {{ getRecipentsData }} -->
    </v-layout>
    <v-card color="white" v-if="searchParam.type && searchParam.type !== 'Others'">
        <v-layout row style="margin-top: 40px">
            <v-card flat>
                <v-badge left overlap color="transparent">
                    <template v-slot:badge>
                        <v-btn fab color="primary" small style="margin-top: -0.8em" class="elevation-0">{{ getRecipentsData.length }}</v-btn>
                    </template>
                    <v-card-text class="title">Recipents List</v-card-text>
                </v-badge>
            </v-card>
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
            <!-- <v-flex xs6 sm4 v-if="searchParam.type == 'Colleges'">
                <v-text-field label="College code" v-model="filterData" single-line></v-text-field>
            </v-flex>
            <v-flex xs6 sm4 v-if="searchParam.type == 'Theory Center'">
                <v-text-field label="Center code" v-model="filterData" single-line></v-text-field>
            </v-flex>
            <v-flex xs6 sm4 v-if="searchParam.type == 'Practical/Viva Center'">
                <v-text-field label="PV Center code" v-model="filterData" single-line></v-text-field>
            </v-flex>
            <v-flex xs6 sm4 v-if="searchParam.type == 'Examiner'">
                <v-text-field label="Examiner TMIS ID" v-model="filterData" single-line></v-text-field>
            </v-flex>
            <v-flex xs6 sm4 v-if="searchParam.type == 'Head Examiner'">
                <v-text-field label="H-Examiner TMIS ID" v-model="filterData" single-line></v-text-field>
            </v-flex> -->
        </v-layout>
    </v-card>
    <template v-if="searchParam.type == 'Colleges' && searchParam.type !== 'Others'">
        <v-flex class="customeDateSearch text-xs-left" xs2 style="margin-bottom: -41px; margin-left: 16px">
            <input type="checkbox" @click="selectAll()" v-model="allSelected" style="font-size: 18px; padding: 10px; width: 15px; height: 15px" />
        </v-flex>
        <div class="tbody">
            <v-data-table :headers="cheaders" :items="getRecipentsData" class="custom-table elevation-1" hide-actions>
                <template v-slot:no-data>
                    <v-alert :value="true" color="error" icon="warning">
                        Sorry, nothing to display here :(
                    </v-alert>
                </template>
                <template v-slot:items="props">
                    <tr>
                        <td style="width: 8%;">
                            <input class="largerCheckbox" type="checkbox" v-model="userIds" @click="select()" :value="props.item" />
                        </td>
                        <td>{{ props.item.college_code }} - {{ props.item.name }}</td>
                        <td>{{ props.item.email }}</td>
                        <td>{{ props.item.mobile }}</td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </template>
    <template v-if="searchParam.type == 'Theory Center' && searchParam.type !== 'Others'">
        <v-flex class="customeDateSearch text-xs-left" xs2 style="margin-bottom: -41px; margin-left: 16px">
            <input type="checkbox" @click="selectAll()" v-model="allSelected" style="font-size: 18px; padding: 10px; width: 15px; height: 15px" />
        </v-flex>
        <v-data-table :headers="theaders" :items="getRecipentsData" class="elevation-1" hide-actions>
            <template v-slot:no-data>
                <v-alert :value="true" color="error" icon="warning">
                    Sorry, nothing to display here :(
                </v-alert>
            </template>
            <template v-slot:items="props">
                <tr>
                    <td style="width: 8%">
                        <input class="largerCheckbox" type="checkbox" v-model="userIds" @click="select()" :value="props.item" />
                    </td>
                    <td>{{ props.item.center_code }} - {{ props.item.name }}</td>
                    <td>{{ props.item.email }}</td>
                    <td>{{ props.item.mobile }}</td>
                </tr>
            </template>
        </v-data-table>
    </template>
    <template v-if="
        searchParam.type == 'Practical/Viva Center' &&
        searchParam.type !== 'Others'
      ">
        <v-flex class="customeDateSearch text-xs-left" xs2 style="margin-bottom: -41px; margin-left: 16px">
            <input type="checkbox" @click="selectAll()" v-model="allSelected" style="font-size: 18px; padding: 10px; width: 15px; height: 15px" />
        </v-flex>
        <v-data-table :headers="PVheaders" :items="getRecipentsData" class="elevation-1" hide-actions>
            <template v-slot:no-data>
                <v-alert :value="true" color="error" icon="warning">
                    Sorry, nothing to display here :(
                </v-alert>
            </template>
            <template v-slot:items="props">
                <tr>
                    <td style="width: 8%">
                        <input class="largerCheckbox" type="checkbox" v-model="userIds" @click="select()" :value="props.item" />
                    </td>
                    <td>{{ props.item.center_code }} - {{ props.item.name }}</td>
                    <td>{{ props.item.email }}</td>
                    <td>{{ props.item.mobile }}</td>
                </tr>
            </template>
        </v-data-table>
    </template>
    <template v-if="searchParam.type == 'Examiner' && searchParam.type !== 'Others'">
        <v-flex class="customeDateSearch text-xs-left" xs2 style="margin-bottom: -41px; margin-left: 16px">
            <input type="checkbox" @click="selectAll()" v-model="allSelected" style="font-size: 18px; padding: 10px; width: 15px; height: 15px" />
        </v-flex>
        <div class="tbody">
            <v-data-table :headers="EXheaders" :items="getRecipentsData" class="elevation-1" hide-actions>
                <template v-slot:no-data>
                    <v-alert :value="true" color="error" icon="warning">
                        Sorry, nothing to display here :(
                    </v-alert>
                </template>
                <template v-slot:items="props">
                    <tr>
                        <td style="width: 8%">
                            <input class="largerCheckbox" type="checkbox" v-model="userIds" @click="select()" :value="props.item" />
                        </td>
                        <td>{{ props.item.tims_id }} - {{ props.item.examiner_name }}</td>
                        <td>{{ props.item.email }}</td>
                        <td>{{ props.item.mobile }}</td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </template>
    <template v-if="
        searchParam.type == 'Head Examiner' && searchParam.type !== 'Others'
      ">
        <v-flex class="customeDateSearch text-xs-left" xs2 style="margin-bottom: -41px; margin-left: 16px">
            <input type="checkbox" @click="selectAll()" v-model="allSelected" style="font-size: 18px; padding: 10px; width: 15px; height: 15px" />
        </v-flex>
        <v-data-table :headers="HEXheaders" :items="getRecipentsData" class="elevation-1" hide-actions>
            <template v-slot:no-data>
                <v-alert :value="true" color="error" icon="warning">
                    Sorry, nothing to display here :(
                </v-alert>
            </template>
            <template v-slot:items="props">
                <tr>
                    <td style="width: 8%">
                        <input class="largerCheckbox" type="checkbox" v-model="userIds" @click="select()" :value="props.item" />
                    </td>
                    <td>{{ props.item.tims_id }} - {{ props.item.examiner_name }}</td>
                    <td>{{ props.item.email }}</td>
                    <td>{{ props.item.mobile ? props.item.mobile : "" }}</td>
                </tr>
            </template>
        </v-data-table>
    </template>

    <v-layout row wrap align-center style="margin-top: 40px;" v-if="searchParam.type || searchParam.type == 'Others'">
        <v-flex xs6 md6 style="padding-right:16px; height:500px;">
            <v-card>
                <v-flex xs12 md12 style="background-color:#2F8A98; color:#fff; display: flex;">
                    <v-checkbox v-model="checkbox_phone" style="margin-left:20px; margin-right:-20px;"></v-checkbox>
                    <v-card-text class="title" style="margin-top:6px;">Write SMS here</v-card-text>
                </v-flex>
                <v-flex xs12 md12 style="padding-left:20px; padding-right:20px; padding-bottom:82px;">
                    <v-layout row wrap align-center>
                        <v-flex xs12 md12 v-if="searchParam.type && searchParam.type == 'Others'" style="padding-top:16px; margin-bottom:-31px;">
                            <v-text-field :disabled="checkbox_phone == false" label="Phone" v-model="othersNumber" outline></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12 style="padding-top:16px;">
                            <v-textarea :disabled="checkbox_phone == false" box name="input-7-4" v-model="smsData" label="SMS" value=""></v-textarea>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-card>
        </v-flex>
        <v-flex xs6 md6 style="padding-left:16px; height:500px;">
            <v-card>
                <v-flex xs12 md12 style="background-color:#2F8A98; color:#fff; display: flex;">
                    <v-checkbox v-model="checkbox_Email" style="margin-left:20px; margin-right:-20px;"></v-checkbox>
                    <v-card-text class="title" style="margin-top:6px;">Write Email here </v-card-text>
                </v-flex>
                <v-flex xs12 md12 style="padding-left:20px; padding-right:20px;">
                    <v-layout row wrap align-center>
                        <v-flex xs12 md12 v-if="searchParam.type && searchParam.type == 'Others'">
                            <v-text-field :disabled="checkbox_Email == false" label="To (Example: tanvir.eatlbd@gmail.com, tanvir@nu.ac.bd)" v-model="othersEmail" single-line></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                            <v-text-field :disabled="checkbox_Email == false" outline label="Mail Subject" v-model="EmailSubject" style="margin-top:14px;"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                            <v-textarea :disabled="checkbox_Email == false" box name="input-7-4" label="Mail Body" v-model="Emailbody"></v-textarea>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-card>
        </v-flex>
    </v-layout>

    <v-flex xs12 sm12 style="margin-top:-76px; margin-left: 45%;" v-if="searchParam.type && searchParam.type !== 'Others'">
        <v-btn color="indigo white--text" style="margin-bottom: 20px; padding-left:40px; padding-right:40px;" @click="getSelectedData()">
            <v-icon>mail</v-icon>&nbsp;SEND
        </v-btn>
    </v-flex>
    <v-flex xs12 sm12 style="margin-top:-76px; margin-left: 45%;" v-if="searchParam.type == 'Others'">
        <v-btn color="indigo white--text" style="margin-bottom: 20px; padding-left:40px; padding-right:40px;" @click="getOthersSelectedData()">
            <v-icon>mail</v-icon>&nbsp;SEND
        </v-btn>
    </v-flex>
</v-container>
</template>

<script>
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import commonGet from "@/mixin/common_getters_mixin";
export default {
    mixins: [commonGet, commonExamYear],
    data() {
        return {
            filterData:'',
            checkbox_Email: false,
            checkbox_phone: false,
            text: 0,
            smsData: '',
            EmailSubject: '',
            Emailbody: '',
            othersNumber: '',
            othersEmail: '',
            searchParam: {},
            recipentsData: [
                "Colleges",
                "Theory Center",
                "Practical/Viva Center",
                "Examiner",
                "Head Examiner",
                "Others",
            ],
            cheaders: [{
                    text: "All",
                    align: "right",
                    sortable: false,
                    value: ""
                },
                {
                    text: "College Code & Name",
                    align: "left",
                    value: "college_code",
                },
                {
                    text: "Email",
                    value: "email"
                },
                {
                    text: "Mobile",
                    value: "mobile"
                },
            ],

            theaders: [{
                    text: "All",
                    align: "right",
                    sortable: false,
                    value: ""
                },
                {
                    text: "Center Code & Name",
                    align: "left",
                    value: "center_code",
                },
                {
                    text: "Email",
                    value: "email"
                },
                {
                    text: "Mobile",
                    value: "mobile"
                },
            ],
            PVheaders: [{
                    text: "All",
                    align: "right",
                    sortable: false,
                    value: ""
                },
                {
                    text: "PV Center Code & Name",
                    align: "left",
                    value: "center_code",
                },
                {
                    text: "Email",
                    value: "email"
                },
                {
                    text: "Mobile",
                    value: "mobile"
                },
            ],
            EXheaders: [{
                    text: "All",
                    align: "right",
                    sortable: false,
                    value: ""
                },
                {
                    text: "Ex TMIS ID & Name",
                    align: "left",
                    value: "tims_id",
                },
                {
                    text: "Email",
                    value: "email"
                },
                {
                    text: "Mobile",
                    value: "mobile"
                },
            ],
            HEXheaders: [{
                    text: "All",
                    align: "right",
                    sortable: false,
                    value: ""
                },
                {
                    text: "H-Ex TMIS ID & Name",
                    align: "left",
                    value: "tims_id",
                },
                {
                    text: "Email",
                    value: "email"
                },
                {
                    text: "Mobile",
                    value: "mobile"
                },
            ],
            selected: [],
            allSelected: false,
            userIds: [],
        };
    },
    created() {},
    computed: {
        getRecipentsData() {
            return this.$store.getters.getRecipents;
        },
    },
    watch: {},
    methods: {
        getrecipents() {
            //console.log("This is recipents", this.searchParam);
            let peram = {};
            peram.exam_code = this.searchParam.exam_code;
            peram.exam_year = this.searchParam.year;
            if (this.searchParam.type == "Colleges") {
                peram.type = "colleges";
            } else if (this.searchParam.type == "Theory Center") {
                peram.type = "theoryCenter";
            } else if (this.searchParam.type == "Practical/Viva Center") {
                peram.type = "practicalVibaCenter";
            } else if (this.searchParam.type == "Examiner") {
                peram.type = "examiner";
            } else if (this.searchParam.type == "Head Examiner") {
                peram.type = "headExaminer";
            } else {
                console.log("Thank You!");
            }

            if (this.searchParam.type !== "Others") {
                this.$store.dispatch("fetchEmailSmsRecipents", peram).then(() => {
                    console.log("Tanvir, Got recipents List!");
                    this.EmailSubject = '';
                    this.Emailbody = '';
                    this.othersEmail = '';
                    this.smsData = '';
                    this.othersNumber = '';
                    this.checkbox_Email = false;
                    this.checkbox_phone = false;
                });
            };
            if (this.searchParam.type == "Others") {
                this.EmailSubject = '';
                this.Emailbody = '';
                this.othersEmail = '';
                this.smsData = '';
                this.othersNumber = '';
                this.checkbox_Email = false;
                this.checkbox_phone = false;
            }
        },
        selectAll() {
            this.userIds = [];
            if (this.allSelected == false) {
                for (var dessert in this.getRecipentsData) {
                    this.userIds.push(this.getRecipentsData[dessert]);
                    this.admitParam = this.getRecipentsData[dessert];
                }
            }
        },
        select() {
            this.allSelected = false;
        },

        getSelectedData() {
            let peram = {};
            if (this.EmailSubject && this.Emailbody && !this.smsData) {
                peram.type = "email";
            } else if (!this.EmailSubject && !this.Emailbody && this.smsData) {
                peram.type = "sms";
            } else if (this.EmailSubject && this.Emailbody && this.smsData) {
                peram.type = "both";
            };
            if (this.searchParam.type !== 'Others') {
                peram.recipients = this.userIds;
            };
            if(this.checkbox_phone == true){
                peram.smsbody = this.smsData;
            };
            if(this.checkbox_Email == true){
                peram.mailbody = {
                subject: this.EmailSubject,
                body: this.Emailbody
            };
            }
            console.log("Tanvir this is selected recipents", peram);
            this.$store.dispatch("sendEmailSms", peram).then(() => {
                console.log("Tanvir, Sent!");
                this.clearBody();
            });
        },
        getOthersSelectedData() {
            let peram = {};
            if (this.EmailSubject && this.Emailbody && this.checkbox_Email == true && this.checkbox_phone == false) {
                peram.type = "email";
            } else if (this.smsData && this.checkbox_phone == true && this.checkbox_Email == false) {
                peram.type = "sms";
            } else if (this.checkbox_phone == true && this.checkbox_Email == true) {
                peram.type = "both";
            }
            if (this.othersNumber && this.checkbox_phone == true) {
                peram.smsRecipients = this.othersNumber;
                peram.smsbody = this.smsData;
            };
            if (this.othersEmail && this.checkbox_Email == true) {
                peram.emailRecipients = this.othersEmail;
                peram.mailbody = {
                subject: this.EmailSubject,
                body: this.Emailbody
                };
            }; 
            console.log("Tanvir this is Others recipents", peram);
            this.$store.dispatch("sendEmailSmsOthers", peram).then(() => {
                console.log("Tanvir, Sent!");
                this.clearBody();
            });
        },
        showSmsEmail(x) {
            this.text = x;
            console.log("Tanvir! Hellow", this.text);
        },
        clearBody(){
            this.checkbox_Email = false;
            this.othersEmail = '';
            this.EmailSubject = '';
            this.Emailbody = '';
            this.checkbox_phone = false;
            this.othersNumber = '';
            this.smsData = '';
        }
    },
};
</script>

<style scoped>
.custom-table {
    padding: 0;
    /* Remove default padding */
}

.tbody {
    max-height: 500px;
    /* Set a maximum height for the table body */
    overflow-y: auto;
    /* Add vertical scrollbar when content overflows */
}

th,
td {
    padding: 2px;
    /* Adjust cell padding as needed */
}
</style>
