<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card grey darken-10 height="40">
          <marquee>
            <slot v-for="(spIns, i) in speIns">
              <span :key="'d' + i" v-if="i">{{ "||" }}</span>
              <span
                style="font-weight: bold"
                :key="i"
                :class="spIns.display_type == 1 ? 'red--text' : ''"
              >
                {{ spIns.instruction }}
              </span>
            </slot>
          </marquee>
        </v-card>
      </v-flex>

      <v-flex xs8 class="pr-1 mt-2">
        <v-card>
          <v-toolbar grey darken-10 height="40">
            <v-toolbar-title>General Instruction</v-toolbar-title>
          </v-toolbar>
          <v-card-text justify-center>
            <v-alert
              :value="true"
              color="rgba(255, 0, 0, 0.5)"
              v-for="(gen, i) in genIns"
              v-html="gen.instruction"
              :key="i"
              style="
                background-color: #eceff187;
                border-color: #3f51b5 !important;
              "
            ></v-alert>
            <h3 style="color:red">কলেজ ইউজার তৈরি, পরীক্ষার অনুমতি প্রদান এবং পরীক্ষার্থীদের ফরম ফিলাপ নিশ্চয়ন ও ইনকোর্স নম্বর প্রদানের নিয়মাবলী</h3>
            <template>
              <v-layout row justify-end>
                <v-dialog
                  v-model="dialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">Read Now</v-btn>
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon color="red accent-4" @click="dialog = false">
                        <v-icon>close</v-icon>
                      </v-btn>
                      <v-toolbar-title>
                         কলেজ ইউজার তৈরি, পরীক্ষার অনুমতি প্রদান এবং পরীক্ষার্থীদের ফরম ফিলাপ নিশ্চয়ন ও ইনকোর্স নম্বর প্রদানের নিয়মাবলী</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn flat style="background-color: #004D40" @click="dialog = false">
                          Go Back</v-btn
                        >
                      </v-toolbar-items>
                    </v-toolbar>

                    <v-container fluid grid-list-md color="white">
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-card>
                            <v-card-text class="px-0">
                              <div class="headLineMoc">কলেজ ইউজার তৈরি ও পরীক্ষার অনুমতি প্রদানের নিয়মাবলীঃ</div><br>
                              <tr>
                                <td style="padding-left:20px;"> 
                                  ১)
                                </td>
                                <td style="padding-left:10px;"> 
                                  যেকোন ওয়েব ব্রাউজারের এড্রেসবারে http://ems.nu.ac.bd/  টাইপ করে “College Login” কলেজ লগ-ইন ম্যানুতে ক্লিক করতে হবে 
                                     অথবা http://ems.nu.ac.bd/c-login লিংকে যেতে হবে।
                                </td>
                              </tr>
                              <tr>
                                <td style="padding-left:20px;"> 
                                  ২)
                                </td>
                                <td style="padding-left:10px;"> 
                                  কলেজ কোড (xxxx) ও পাসওয়ার্ড (xxxx) দিয়ে “Login” বাটনে ক্লিক করে কলেজ প্যানেলে প্রবেশ করতে হবে। কলেজ প্যানেলে লগ-ইন করার জন্য জাতীয় বিশ্ববিদ্যালয়ের ওয়েবসাইটের College Profile থেকে (লিংকঃ http://nubd.info/college/)  অথবা সংশ্লিষ্ট শাখা থেকে পাসওয়ার্ড সংগ্রহ করা যাবে। 
                                </td>
                              </tr>
                              <tr>
                                <td style="padding-left:20px;"> 
                                  ৩)
                                </td>
                                <td style="padding-left:10px;"> 
                                   কলেজ প্যানেলে প্রবেশ করার পর বাম পাশের মেনু থেকে “College User” মেনুতে ক্লিক করে নিচের নির্দেশনা অনুযায়ী কলেজ ইউজার তৈরী করা যাবে।
                                   <ul style="margin-top:5px;">
                                  <li style="text-decoration:none;">
                                    “College User” ম্যানুতে ক্লিক করার পর “ADD USER” বাটনে ক্লিক করতে হবে ।
                                  </li>
                                  <li style="margin-top:5px;">
                                    “ADD USER” বাটনে ক্লিক করার পর, ইউজার এর প্রয়োজনীয় তথ্য (Name, Email, Mobile, Exam, Degree Group, Username and Password) এবং একই সাথে কোন নির্দিষ্ট একটি পরীক্ষার অনুমতি সহ ইউজার তৈরি করতে পরীক্ষার তথ্য সহ “SUBMIT” বাটনে ক্লিক করতে হবে । অন্যথায় শুধুমাত্র ইউজার তৈরি করতে (Exam, Degree Group Code, Subject) ব্যতীত প্রয়োজনীয় তথ্য দিয়ে “SUBMIT” বাটনে ক্লিক করতে হবে ।
                                  </li>
                                </ul>
                                </td>
                              </tr>
                            </v-card-text>
                          </v-card>

                           <v-card style=" margin-top:20px;">
                            <v-card-text class="px-0">
                              <div class="headLineMoc">পূর্বে তৈরিকৃত ইউজার এর পরীক্ষার অনুমতি প্রদানের ক্ষেত্রেঃ</div> <br>
                              <tr>
                                <td style="padding-left:20px;"> 
                                  ১)
                                </td>
                                <td style="padding-left:10px;"> 
                                  “College User” ম্যানুতে ক্লিক করলে “College User List” থেকে যে কোন ইউজারের ”Acction” কলাম থেকে ”Assign Privilege” বাটনে ক্লিক করে Exam, Degree Group Code এবং Subject সিলেক্ট করে “SUBMIT” বাটনে ক্লিক করতে হবে ।
                                </td>
                              </tr>
                              <tr>
                                <td style="padding-left:20px;"> 
                                  ২)
                                </td>
                                <td style="padding-left:10px;"> 
                                  ইউজার কে সক্রিয় করতে ইউজার এর “STATUS” অবশ্যই  ACTIVE রাখতে হবে ।
                                </td>
                              </tr>

                              <tr style=" margin-bottom:10px;"> 
                                <td style="padding-left:20px;"> 
                                   ৩)
                                </td>
                                <td style="padding-left:10px;"> 
                                  	পরীক্ষার অনুমতি সফলভাবে সম্পন্ন হলে উক্ত ইউজার এর পরীক্ষা অনুমতি লিস্টে পরীক্ষার তথ্য দেখাবে ।
                                </td>
                              </tr>
                              <strong style="color:red; padding-left:20px;">বিঃদ্রঃ পূর্বে তৈরিকৃত ইউজার না থাকলে নতুন ইউজার তৈরি করতে হবে ।</strong>
                            </v-card-text>
                          </v-card>


                          <v-card style=" margin-top:20px;">
                            <v-card-text class="px-0">
                              <div class="headLineMoc">কলেজ ইউজার কর্তৃক পরীক্ষার্থীদের ফরম ফিলাপ নিশ্চয়ন ও ইনকোর্স নম্বর প্রদানের নিয়মাবলীঃ</div><br>
                              <tr>
                                <td style="padding-left:20px;"> 
                                   ১)
                                </td>
                                <td style="padding-left:10px;"> 
                                  যেকোন ওয়েব ব্রাউজারের এড্রেসবারে http://ems.nu.ac.bd/  টাইপ করে এন্টার বাটন প্রেস করে “College User Login” ম্যানুতে ক্লিক করতে হবে 
                                অথবা http://ems.nu.ac.bd/cu-login লিংকে যেতে হবে।
                                </td>
                              </tr>

                              <tr>
                                <td style="padding-left:20px;"> 
                                  ২)	
                                </td>
                                <td style="padding-left:10px;"> 
                                 ইউজারনেম ও পাসওয়ার্ড দিয়ে কলেজ ইউজার প্যানেলে প্রবেশ করতে হবে। শুধৃমাত্র কলেজ ইউজারই ইনকোর্স মার্ক প্রদান করতে পারবে

                                </td>
                              </tr>

                              <tr>
                                <td style="padding-left:20px;"> 
                                  ৩)
                                </td>
                                <td style="padding-left:10px;"> 
                                    	কলেজ ইউজার প্যানেলে প্রবেশ করার পর কোন নির্দিষ্ট পরীক্ষার পরীর্ক্ষীদের ফরম ফিলাপ নিশ্চয়ন ও ইনকোর্স নাম্বার প্রদানের জন্য নির্দিষ্ট পরীক্ষার ”Action” বাটনে  ক্লিক করতে হবে।
                                </td>
                              </tr>

                              <tr>
                                <td style="padding-left:20px;"> 
                                  ৪)	
                                </td>
                                <td style="padding-left:10px;"> 
                                  ”Action” বাটনে ক্লিক করার পর বাম পাশের ম্যানু থেকে “Confirm Data Entry” ম্যানুতে ক্লিক করে নির্দিষ্ট ছাত্র/ছাত্রীর রেজিস্ট্রেশন নাম্বার টাইপ করে “SEARCH” বাটনে ক্লিক করতে হবে ।
                                </td>
                              </tr>

                              <tr>
                                <td style="padding-left:20px;"> 
                                  ৫)	
                                </td>
                                <td style="padding-left:10px;"> 
                                   “SEARCH” বাটনে ক্লিক করার পর উক্ত পরীর্ক্ষীর ফরম ফিলাপকৃত বিষয় সমূহের ইনকোর্স নম্বর ও “APPLICATION NUMBER” দিয়ে “CONFIRM APPLICATION” বাটনে   ক্লিক. করতে হবে।
                                </td>
                              </tr>

                              <tr>
                                <td style="padding-left:20px;"> 
                                  ৬)
                                </td>
                                <td style="padding-left:10px;"> 
                                  বাম পাশের ম্যানুবার থেকে “Confirmed Candidate List” ম্যানুতে ক্লিক করলে Confirmed Candidate List পাওয়া যাবে এবং ডাউনলোড করা যাবে। 
                                </td>
                              </tr>
                            </v-card-text>
                          </v-card>
                          
                          <div class="headLineMoc" style="margin-top:20px;">EMS সফটওয়্যার সংক্রান্ত যে কোন সমম্যায় নিম্নোক্ত ফোন নাম্বারে যোগাযোগ করুন</div><br>
                            <p style="color:#000;padding-left:20px;">১. মোঃ রানা মিয়া -> ০১৮৮৯৯৬৭৫১৪  </p>
                            <p style="color:#000;padding-left:20px;">২. মোহাম্মদ মস্তফা -> ০১৯৫০-৯২৬০৭০ </p>
                            <p style="color:#000;padding-left:20px;">৩. মোঃ ইব্রাহীম খন্দকার -> ০১৭১০৬৫৯০৬৮   </p>
                            <p style="color:#000;padding-left:20px;">৪. মোঃ মেহেদী হাসান -> ০১৬৭৬২২৭০১৯  </p>
                            <p style="color:#000;padding-left:20px;">৫. এস. এম. তানভীর সিদ্দিকী -> ০১৩২৬৬০২৪৫৮</p>

 
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-layout>
            </template>
          </v-card-text>
          <!-- <v-card-actions class="pa-3">
            <v-btn small flat color="info" float="right" @click="collegeGenIns"
              >View All</v-btn
            >
          </v-card-actions> -->
        </v-card>

        <v-card>
          <v-toolbar grey darken-10 height="40">
            <v-toolbar-title>Faq </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-expansion-panel expand v-model="panel">
              <v-expansion-panel-content
                v-for="(faq, i) in faqs"
                :key="`faq_${i}`"
              >
                <template v-slot:header>
                  <div style="font-weight: bold;">{{ faq.question }}</div>
                </template>
                <v-card>
                  <v-card-text class="ml-2">{{ faq.answer }}</v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-card-text>
          <v-card-actions>
            <v-btn small flat color="info" float="right" @click="collegeFaq"
              >View All</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card>
          <v-toolbar grey darken-10 height="40">
            <v-toolbar-title>Current Exam List</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-card color="white">
              <v-layout row>
                <!--
                <v-badge left overlap>
                  <template v-slot:badge>
                    <v-btn
                      round
                      color="primary"
                      fab
                      small
                      style="margin-top: -1.3em;"
                      class="elevation-0"
                      v-if="getAllOpenExamMasterList.length"
                      >{{ getAllOpenExamMasterList.length }}</v-btn
                    >
                  </template>
                  <v-card-text class="title">Exam List</v-card-text>
                </v-badge>
                -->
                <v-divider class="my-2" vertical></v-divider>
                <v-text-field
                  v-model="search"
                  label="Search"
                  class="mt-1"
                  hide-details
                  solo
                  flat
                  single-line
                ></v-text-field>
                <v-spacer />
                <v-btn
                  @click="searchList()"
                  class="ml-3"
                  fab
                  small
                  flat
                  dark
                  color="primary"
                >
                  <v-icon dark large>search</v-icon>
                </v-btn>
              </v-layout>
            </v-card>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="getAllOpenExamMasterList"
              class="elevation-1"
              item-key="id"
            >
              <template v-slot:items="props">
                <tr>
                  <td>{{ props.index + 1 }}</td>
                  <td class="text-xs">{{ props.item.exam_name }}</td>
                  <td class="text-xs">{{ props.item.exam_code }}</td>
                  <td class="text-xs">{{ props.item.exam_year }}</td>
                  <!-- <td class="text-xs">{{ props.item.start_date|dateFormat('ll') }}</td> -->
                  <!-- <td class="text-xs">{{ props.item.end_date|dateFormat('ll') }}</td> -->
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs4 class="pr-1 mt-2">
        <v-card>
          <v-toolbar grey darken-10 height="40">
            <v-toolbar-title>Notice</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ul>
              <li
                v-for="(notice, index) in notices"
                :key="`notice_${index}`"
                style="list-style: circle"
              >
                <a
                  :href="getBaseUrl + '/' + notice.attachment"
                  target="_blank"
                  style="text-decoration: none"
                  >{{ notice.title }}</a
                >
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn small flat color="info" align="right" @click="collegeNotice"
              >View All</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs12 class="pr-1 mt-2">
        <v-card>
          <v-toolbar grey darken-10 height="40">
            <v-toolbar-title>Exam Routine</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ExamRoutine></ExamRoutine>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <pre>{{selected}}</pre> -->
    <!-- <pre>{{confirm_by_college}}</pre> -->
  </v-container>
</template>
<script>
import Axios from "axios";
import ExamRoutine from "@/views/collegePanel/ExamRoutine";
export default {
  components: {
    ExamRoutine,
  },
  data() {
    return {
      dialog: false,
      panel:[true, true, true, true, true, true,
      true, true, true, true, true, true, true, 
      true, true, true, true, true, true, true],
      search: '',
      notices: [],
      faqs: [],
      genIns: [],
      speIns: [],
      headers: [
        {
          text: "SL.",
          align: "left",
          value: "id",
          width: "10",
          sortable: false,
        },
        { text: "Name", value: "exam_name" },
        { text: "Eaxm Code", value: "exam_code" },
        { text: "Year", value: "exam_year" },
        // { text: "Start Date", value: "start_date" },
        // { text: "Close Date", value: "end_date" }
      ],
    };
  },
  computed: {
    getBaseUrl() {
      return this.$store.getters.getBaseUrl;
    },
    getAllOpenExamMasterList() {
      return this.$store.getters.getAllOpenExamMasterList;
    },
  },
  methods: {
    getNotice() {
      let self = this;
      Axios.get(self.getBaseUrl + "/api/college/college-notice").then(
        (response) => {
          self.notices = response.data.data;
        }
      );
    },
    getFaq() {
      let self = this;
      Axios.get(self.getBaseUrl + "/api/college/all-faq").then((response) => {
        self.faqs = response.data.data;
      });
    },
    getGenIns() {
      let self = this;
      Axios.get(
        self.getBaseUrl + "/api/college/instruction?" + "instruction_type=1"
      ).then((response) => {
        self.genIns = response.data.data;
      });
    },
    getSpeIns() {
      let self = this;
      Axios.get(
        self.getBaseUrl + "/api/college/instruction?" + "instruction_type=2"
      ).then((response) => {
        self.speIns = response.data.data;
      });
    },
    collegeNotice() {
      this.$router.push("/college/notices");
    },
    collegeGenIns() {
      this.$router.push("/college/general-instructions");
    },
    collegeSpeIns() {
      this.$router.push("/college/special-instructions");
    },
    collegeFaq() {
      this.$router.push("/college/faqs");
    },
  },
  created() {
    this.$store.dispatch("getAllOpenExamMasterList");
  },
  mounted() {
    this.getNotice();
    this.getFaq();
    this.getGenIns();
    this.getSpeIns();
  },
};
</script>

<style lang="scss">
.headLineMoc{
  background-color:#3F51B5;
  color: #fff;
  padding: 10px;
}
.scroll-div {
  height: 150px;
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
marquee {
  font-size: 15px;
  font-weight: 100;
  font-family: sans-serif;
  padding: 10px 0;
}

.color {
  color: red;
}
.pointer {
  corsor: pointer;
}

.v-alert {
  border-radius: 5;
  border-width: 4px 0 0 0;
  border-style: solid;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  margin: 4px auto;
  padding: 16px;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-alert.v-alert {
  background-color: #eceff187;
}
</style>
