<template>
  <v-container fluid>
    <v-card color="white">
      <v-layout row>
        <v-layout row wrap align-center>
          <v-flex class="mx-5">
            <v-layout
              row
              wrap
              align-center
              style="margin-top: 20px; margin-bottom: 20px"
            >
              <span
                v-if="ifCenterUser == true"
                class="ma-2 title"
                style="
                  width: 49%;
                  border: 1px solid #bdbdbd;
                  padding: 16px;
                  color: #7b7b7b;
                "
              >
                {{ centerName.centerCode }} - {{ centerName.centerName }}
              </span>

              <v-flex style="padding-top: 24px" v-if="ifCenterUser == false">
                <v-autocomplete
                  :disabled="ifCenterUser"
                  class="ma-2"
                  v-model="center_code"
                  :item-text="
                    (item) => item.center_code + ' - ' + item.center_name
                  "
                  item-value="center_code"
                  :items="getTheoryCenterList"
                  menu-props="auto"
                  label="Select Center"
                  prepend-icon="list"
                  outline
                  @change="getSammaryofTodayExam()"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      close
                      @input="clearCenter()"
                      :selected="data.selected"
                      class="chip--select-multi"
                    >
                      {{
                        data.item.center_code + " - " + data.item.center_name
                      }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-tile-content>
                        <v-list-tile-title
                          v-html="
                            data.item.center_code +
                            ' - ' +
                            data.item.center_name
                          "
                        ></v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-flex>

              <v-menu full-width max-width="290" :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    color="cyan"
                    class="ma-2 title"
                    v-model="date"
                    label="Select Attendance Date"
                    readonly
                    v-on="on"
                    outline
                    hide-details
                    prepend-icon="calendar_today"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="SearchByDate(date)"
                ></v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>

    <!-- <v-flex xs12 class="ma-3">
      
    </v-flex> -->

    <v-card color="white" style="margin-top: 40px">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Exam Sammary</v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-btn color="#3F51B5" @click="getSammaryofTodayExamPDF(1)">
          <v-icon size="30" color="#fff">download</v-icon>
          <p style="margin-top: 16px; color: #fff">Download Sammary</p>
        </v-btn>
        <v-btn color="#3F51B5" @click="getSammaryofTodayExam()">
          <v-icon size="30" color="#fff">replay</v-icon>
          <p style="margin-top: 16px; color: #fff">Reload</p>
        </v-btn>
      </v-layout>
    </v-card>
    <v-data-table
      v-if="this.date"
      :loading="loading"
      :headers="headers"
      :items="getTodayExamSummaryDataAtCenter"
      class="elevation-1"
      hide-actions
    >
      <template v-slot:items="props">
        <td>{{ props.index + 1 }}</td>
        <td class="text-xs-left">
          {{ props.item.subject_code }} - {{ props.item.subject_name }}
        </td>
        <td class="text-xs-left">
          {{ props.item.paper_code }} - {{ props.item.paper_name }}
        </td>
        <td class="text-xs-center">{{ props.item.total_student }}</td>
        <td class="text-xs-center">
          <span>
            {{ props.item.total_present }}
          </span>
        </td>
        <td class="text-xs-center">
          <v-layout row justify-center>
            <v-dialog
              v-model="dialog"
              persistent
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="#B71C1C"
                  dark
                  v-on="on"
                  @click="
                    examSubPepWiseAbcList(
                      props.item.subject_code,
                      props.item.paper_code
                    )
                  "
                  ><v-icon left>visibility</v-icon
                  >{{ props.item.total_absent }}</v-btn
                >
              </template>
              <v-card>
                <v-toolbar dark color="primary">
                  <!-- <v-btn icon dark @click="dialog = false">
                    <v-icon>close</v-icon>
                  </v-btn> -->
                  <v-toolbar-title
                    >{{ getLoggedInExam.code }} - ({{ date }}) Absent Examinee
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                      fab
                      class="ma-1 pa-0"
                      small
                      color="red darken-3 white--text"
                      @click="dialog = false"
                      ><v-icon>close</v-icon></v-btn
                    >
                  </v-toolbar-items>
                </v-toolbar>
                <v-card
                  style="
                    padding-top: 40px;
                    padding-left: 40px;
                    padding-right: 40px;
                    padding-bottom: 80px;
                  "
                >
                  <!-- {{ getExamineeAbsentListDataBYExamdate.total }} -->
                  <v-layout row>
                    <v-card flat>
                      <v-badge left overlap>
                        <template v-slot:badge>
                          <v-btn
                            round
                            color="primary"
                            fab
                            small
                            style="margin-top: -1.3em"
                            class="elevation-0"
                            v-if="getExamineeAbsentListDataBYExamdate.total"
                            >{{
                              getExamineeAbsentListDataBYExamdate.total
                            }}</v-btn
                          >
                        </template>
                        <!-- <v-card style="border-top:1px solid #000;" class="title">Absent Examinee List</v-card> -->
                      </v-badge>
                    </v-card>
                  </v-layout>

                  <v-data-table
                    :loading="loading"
                    :headers="Absentheaders"
                    :items="getExamineeAbsentListDataBYExamdate.data"
                    :pagination.sync="pagination"
                    :rows-per-page-items="pagination.rowsPerPageItems"
                    :total-items="getExamineeAbsentListDataBYExamdate.total"
                    class="elevation-1"
                  >
                    <template v-slot:items="props">
                      <td class="text-xs-center">{{ props.index+1 }}</td>
                      <td>{{ props.item.student_name }}</td>
                      <td class="text-xs-center">
                        {{ props.item.exam_roll_no }}
                      </td>
                      <td class="text-xs-center">{{ props.item.reg_no }}</td>
                      <td class="text-xs-center">
                        {{ props.item.subject_code }} -
                        {{ props.item.subject_name }}
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.paper_code }} -
                        {{ props.item.paper_name }}
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-card>
            </v-dialog>
          </v-layout>
        </td>
      </template>
    </v-data-table>

    <v-card v-if="!this.date" color="white">
      <v-layout row>
        <span
          style="
            margin-left: 33%;
            font-size: 24px;
            padding-top: 40px;
            padding-bottom: 40px;
            color: red;
          "
          >Please, select "Attendance Date" first !!!</span
        >
      </v-layout>
    </v-card>

    <v-card color="white">
      <v-layout row>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <span
          v-if="this.date"
          style="
            margin-right: 6%;
            font-size: 18px;
            padding-top: 10px;
            padding-bottom: 20px;
          "
          ><b>Total:</b></span
        >
        <span
          v-if="this.date"
          style="
            margin-left: 9%;
            font-size: 16px;
            padding-top: 10px;
            padding-bottom: 20px;
          "
          ><b>{{ totalExaminee }}</b></span
        >
        <span
          v-if="this.date"
          style="
            margin-left: 13%;
            font-size: 16px;
            padding-top: 10px;
            padding-bottom: 20px;
          "
          ><b>{{ totalPresent }}</b></span
        >
        <span
          v-if="this.date"
          style="
            margin-left: 8%;
            margin-right: 3.5%;
            font-size: 16px;
            padding-top: 10px;
            padding-bottom: 20px;
          "
          ><b>{{ totalAbsent }}</b></span
        >
      </v-layout>
    </v-card>

    <v-card color="white" style="margin-top: 40px">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Center Change Attendance </v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
      </v-layout>
    </v-card>
    <v-data-table
      v-if="this.date"
      :loading="loading"
      :headers="centerChangeheaders"
      :items="getCenterChangeAttList"
      class="elevation-1"
      hide-actions
    >
      <template v-slot:items="props">
        <td>{{ props.index + 1 }}</td>
        <td class="text-xs-left">
          {{ props.item.student.name }}
        </td>
        <td class="text-xs-left">
          {{ props.item.exam_roll }}
        </td>
        <td class="text-xs-left">
          {{ props.item.student.reg_no }}
        </td>
        <td class="text-xs-left">
          {{ props.item.student.subject.subject_code }} -
          {{ props.item.student.subject.subject_name }}
        </td>
        <td class="text-xs-left">
          {{ props.item.paper.paper_code }} - {{ props.item.paper.paper_name }}
        </td>
        <td class="text-xs-left">
          {{ props.item.student.college.college_code }} -
          {{ props.item.student.college.college_name }}
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import common_getters_mixin from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import formNotification from "@/components/utils/formNotification";
import admitCardCountByCenter from "@/components/admitCard/admitCardCountByCenter.vue";
import todaySummary from "@/components/todaySummary/todaySummary.vue";
import routinePdf from "@/components/admin/routinePdf.vue";
import draggable from "vuedraggable";

// import common_login_getters from "../../mixin/common_login_getters";
import common_exam_lg from "../../mixin/common_login_getters.vue";
export default {
  mixins: [common_getters_mixin, commonExamYear, common_exam_lg],
  components: {
    draggable,
    admitCardCountByCenter,
    todaySummary,
    formNotification,
    routinePdf,
  },
  data() {
    return {
      searchParam: {
        center_code: "",
      },
      totalExaminee: 0,
      totalPresent: 0,
      totalAbsent: 0,
      pagination: {
        page: 1,
        rowsPerPage: 50, // -1 for All",
        totalItems: 200,
        rowsPerPageItems: [50, 100, 150, { text: "All", value: -1 }],
      },
      subPapData: {
        subCode: "",
        pepCode: "",
      },
      examDetails: {},
      dialog: false,
      date: "",
      centerName: {},
      loading: false,
      headers: [
        {
          text: "SL",
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: "Subject Code and Name",
          align: "center",
          value: "subject_name",
        },
        { text: "Paper Code and Name", align: "center", value: "paper_name" },
        { text: "Total Examinee", align: "center", value: "total_student" },
        { text: "Total Present", align: "center", value: "total_present" },
        { text: "Total Absent", align: "center", value: "total_absent" },
      ],

      Absentheaders: [
        { text: "SL", align: "center", value: "rn" },
        { text: "Examinee Name", align: "center", value: "student_name" },
        { text: "Exam Roll No", align: "center", value: "exam_roll_no" },
        { text: "Registration No", align: "center", value: "reg_no" },
        { text: "Subject Code & Name", align: "center", value: "subject_name" },
        { text: "Pepar Code & Name", align: "center", value: "paper_name" },
      ],

      centerChangeheaders: [
        { text: "SL", align: "left", sortable: false },
        { text: "Examinee Name", align: "left", value: "student_name" },
        { text: "Exam Roll No", align: "left", value: "exam_roll_no" },
        { text: "Registration No", align: "left", value: "reg_no" },
        { text: "Subject Code & Name", align: "left", value: "subject_name" },
        { text: "Pepar Code & Name", align: "left", value: "paper_name" },
        { text: "College Code & Name", align: "left", value: "college_code" },
      ],
    };
  },
  created() {
    this.theworyyCenterData();
    this.setCenterNameAndCode();
  },
  computed: {
    getTheoryCenterList() {
      return this.$store.getters.getTheoryCenterList;
    },

    getTodayExamSummaryDataAtCenter() {
      return this.$store.getters.getTodayExamSummaryData &&
        Array.isArray(this.$store.getters.getTodayExamSummaryData)
        ? this.$store.getters.getTodayExamSummaryData
        : [];
    },

    getExamineeAbsentListDataBYExamdate() {
      return this.$store.getters.getPeparWiseAbsentExamineeData
        ? this.$store.getters.getPeparWiseAbsentExamineeData
        : [];
    },

    getCenterChangeAttList() {
      return this.$store.getters.getCenterChangeExamineeAttData;
    },
  },
  watch: {
    pagination(cv) {
      console.log("gugaga", cv);
      this.viewAbsentListPeparWise();
    },

    getTodayExamSummaryDataAtCenter(cv) {
      this.totalExaminee = 0;
      this.totalPresent = 0;
      this.totalAbsent = 0;
      cv.forEach((resp) => {
        // console.log("dsdfvsdvdvfdfvdfv", resp);
        this.totalExaminee += Number(resp.total_student);
        this.totalPresent += Number(resp.total_present);
        this.totalAbsent += Number(resp.total_absent);
      });
    },
  },
  methods: {
    theworyyCenterData() {
      let param = {};
      param.exam_initiate_id = this.getExamInitiateId;
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      this.$store.dispatch("fetchTheoryWiseCenterData", param);
    },

    setCenterNameAndCode() {
      if (this.ifCenterUser === true) {
        this.centerName.centerName = this.getAuthUser.center.center_name;
        this.centerName.centerCode = this.getAuthUser.center.center_code;
      } else {
        console.log("Tanvir,This is an  admin user!");
      }
    },
    getSammaryofTodayExam() {
      console.log("Tanvir Date", this.center_code);
      let peram = {};
      if (this.ifCenterUser === false) {
        peram.center_code = this.center_code;
      } else if (this.ifCenterUser === true) {
        peram.center_code = this.getAuthUser.center.center_code;
      }
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.attendance_date = this.date;
      console.log("Tanvir", peram);
      peram.download = 0;
      if (this.date) {
        this.loading = true;
        this.$store.dispatch("fetchTodayExamSummaryData", peram).then(() => {
          this.loading = false;
        });
      }
    },

    getSammaryofTodayExamPDF(parameter) {
      const current = "2023-02-22";
      //const current = new Date().toJSON().slice(0, 10);
      console.log("Tanvir Date", current);
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      if (this.ifCenterUser == false) {
        peram.center_code = this.center_code;
      } else {
        peram.center_code = this.getAuthUser.center.center_code;
      }
      peram.attendance_date = this.date;
      console.log("Tanvir", peram);
      peram.download = parameter;
      if (this.date) {
        this.$store
          .dispatch("fetchTodayExamSummaryData", peram)
          .then((resp) => {
            window.open(resp);
          });
      }
    },

    SearchByDate(date) {
      console.log("tanvir, This is selected date", this.ifCenterUser);
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      if (this.ifCenterUser == false) {
        peram.center_code = this.center_code;
      } else {
        peram.center_code = this.getAuthUser.center.center_code;
      }
      peram.attendance_date = date;
      console.log("Tanvir", peram);
      peram.download = 0;
      this.loading = true;
      if (date) {
        this.$store.dispatch("fetchTodayExamSummaryData", peram).then(() => {
          this.loading = false;
          this.getCenterChangeData();
        });
        
      }
    },

    viewAbsentListPeparWise() {
      //console.log('Tanvir, This is Subject and Paper code-->', subjectCode , paperCode);
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      if (this.ifCenterUser == false) {
        peram.center_code = this.center_code;
      } else {
        peram.center_code = this.getAuthUser.center.center_code;
      }
      peram.attendance_date = this.date;
      peram.subject_code = this.subPapData.subCode;
      peram.paper_code = this.subPapData.pepCode;
      peram.limit = this.pagination.rowsPerPage;
      peram.page = this.pagination.page;
      peram.download = 0;
      console.log(
        "Tanvir, This is Pepar wise absent list peram from center-->",
        peram
      );
      this.loading = true;
      this.$store
        .dispatch("fetchExamineeabsentListPeparwise", peram)
        .then(() => {
          this.loading = false;
        });
    },

    examSubPepWiseAbcList(subCode, pepCode) {
      this.subPapData = { subCode, pepCode };
      console.log("This in next page---->", subCode, pepCode);
      this.viewAbsentListPeparWise();
    },
    getPageData(event) {
      console.log("This is the page index---->", event);
    },

    getCenterChangeData() {
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      if (this.ifCenterUser == false) {
        peram.center_code = this.center_code;
      } else {
        peram.center_code = this.getAuthUser.center.center_code;
      }
      peram.attendance_date = this.date;
      peram.paper_code = this.subPapData.pepCode;
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      
      this.$store.dispatch("fetchCenterChangeAttListPeparwise", peram);
    },
  },
};
</script>

<style>
/* .pagination{
    padding: 8px;
    margin: 2px;
    border-radius: 3px;
    font-size: 1em;
  } */
</style>
