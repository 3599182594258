<template>
  <v-container fluid>
    <v-layout row wrap align-center>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-layout row>
            <v-card-text class="title">Certificate Lot Print</v-card-text>
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
          </v-layout>
        </v-card>
        <v-card class="px-3">
          <v-layout row wrap>
            <v-flex xs12 sm12>
              <div>
                <v-tabs
                  v-model="nextCMTtab"
                  color="#0097A7"
                  dark
                  slider-color="yellow"
                  style="text-align: center"
                >
                  <v-tab>College & Subject Wise</v-tab>
                  <v-tab>Serial wise</v-tab>
                  <v-tab>Exam roll/ Reg wise</v-tab>
                  <v-tab-item>
                    <v-card flat>
                      <v-layout row wrap style="margin-top: 10px">
                        <v-flex xs12 sm6 style="padding: 10px">
                          <v-text-field
                            v-model="crt.col_code_from"
                            label="From College"
                            prepend-inner-icon="account_balance"
                            outline
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 style="padding: 10px">
                          <v-text-field
                            v-model="crt.col_code_to"
                            label="To college"
                            prepend-inner-icon="account_balance"
                            outline
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex
                          xs12
                          sm6
                          style="padding: 10px; margin-top: -16px"
                        >
                          <v-text-field
                            v-model="crt.sub_code_from"
                            label="From Subject"
                            prepend-inner-icon="file_copy"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          style="padding: 10px; margin-top: -16px"
                        >
                          <v-text-field
                            v-model="crt.sub_code_to"
                            label="To Subject"
                            prepend-inner-icon="file_copy"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout
                        row
                        wrap
                        style="
                          text-align: right;
                          margin-top: -10px;
                          margin-bottom: 10px;
                        "
                      >
                        <v-flex xs12 sm12>
                          <v-btn
                            color="primary"
                            @click="getCertificateRegister((is_count = '1'))"
                            >Search</v-btn
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: left">
                        <v-flex xs12 sm12 v-if="this.valueOfTotal !== ''">
                          <div
                            class="px-3"
                            style="
                              margin-top: -50px;
                              padding-bottom: 10px;
                              font-size: x-large;
                            "
                          >
                            <b>Total Printable Certificate:</b>&nbsp;
                            <span style="color: red">
                              <b> {{ valueOfTotal }}</b></span
                            >
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        row
                        wrap
                        style="text-align: center"
                        v-if="this.valueOfTotal !== ''"
                      >
                        <v-flex
                          xs12
                          sm4
                          style="margin-left: 10%; padding-top: 20px"
                        >
                          <v-text-field
                            label="Certificate Print Serial Start From"
                            v-model="sumOfc"
                            prepend-inner-icon="file_copy"
                            @input="totalCprintCol()"
                            type="number"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          style="margin-top: 30px; font-size: x-large"
                        >
                          <b>Last Serial:</b>&nbsp;
                          <span style="color: red"
                            ><b>{{ totalSum }}</b></span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: center">
                        <v-flex
                          xs12
                          sm12
                          style="padding-top: 10px; padding-bottom: 10px"
                        >
                          <v-btn
                            @click="getCertificateRegister()"
                            color="primary"
                            :disabled="this.totalSum == ''"
                            ><v-icon color="light" style="padding-right: 5px"
                              >print</v-icon
                            >
                            Print</v-btn
                          >
                        </v-flex>
                      </v-layout>

                      <v-card style="margin-top: 20px">
                        <v-layout row>
                          <v-card-text class="title"
                            >Printed Certificates</v-card-text
                          >
                          <v-divider class="my-2" vertical></v-divider>
                          <v-spacer />
                        </v-layout>
                      </v-card>

                      <v-layout row wrap align-center>
                        <v-flex xs12 sm12 md12>
                          <template>
                            <v-data-table
                              :headers="headers"
                              :items="getCertificateRegisterPrintDataSet"
                              class="elevation-1"
                            >
                              <template v-slot:items="props">
                                <td>{{ props.index + 1 }}</td>
                                <td class="text-xs-left">
                                  {{ props.item.name }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.exam_roll }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.reg_no }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.session }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.subject_code }} -
                                  {{ props.item.subject }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.college }}
                                </td>
                                <td class="text-xs-center">{{ props.item.crt_sl }}</td>
                                <td class="text-xs-center"></td>
                              </template>
                            </v-data-table>
                          </template>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-tab-item>

                  <!-- c-peint with comSL and com_endSL -->
                  <v-tab-item>
                    <v-card flat>
                      <v-layout row wrap style="margin-top: 0px">
                        <v-flex xs12 sm6 style="padding: 10px">
                          <v-text-field
                            label="From Serial"
                            prepend-inner-icon="format_list_numbered_rtl"
                            v-model="com_start"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 style="padding: 10px">
                          <v-text-field
                            label="To Serial"
                            prepend-inner-icon="format_list_numbered_rtl"
                            v-model="com_end"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout
                        row
                        wrap
                        style="
                          text-align: right;
                          margin-top: -10px;
                          margin-bottom: 10px;
                        "
                      >
                        <v-flex xs12 sm12>
                          <v-btn
                            color="primary"
                            @click="getCertificatewithComSL((is_count = '1'))"
                            >Search</v-btn
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: left">
                        <v-flex xs12 sm12 v-if="this.valueOfTotalSl !== ''">
                          <div
                            class="px-3"
                            style="
                              margin-top: -50px;
                              padding-bottom: 10px;
                              font-size: x-large;
                            "
                          >
                            <b>Total Printable Certificate:</b>
                            <span style="color: red"
                              ><b> {{ valueOfTotalSl }}</b></span
                            >
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        row
                        wrap
                        style="text-align: center"
                        v-if="this.valueOfTotalSl !== ''"
                      >
                        <v-flex
                          xs12
                          sm4
                          style="margin-left: 10%; padding-top: 20px"
                        >
                          <v-text-field
                            label="Certificate Print Serial Start From"
                            v-model="sumOfsl"
                            prepend-inner-icon="file_copy"
                            @input="totalCprintSl()"
                            type="number"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          style="margin-top: 30px; font-size: x-large"
                        >
                          <b>Last Serial:</b>
                          <span style="color: red"
                            ><b>{{ totalSumsl }}</b></span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: center">
                        <v-flex
                          xs12
                          sm12
                          style="padding-top: 10px; padding-bottom: 10px"
                        >
                          <v-btn
                            color="primary"
                            :disabled="this.totalSumsl == ''"
                            @click="getCertificatewithComSL()"
                            ><v-icon color="light" style="padding-right: 5px"
                              >print</v-icon
                            >
                            Print</v-btn
                          >
                        </v-flex>
                      </v-layout>

                      <v-card style="margin-top: 20px">
                        <v-layout row>
                          <v-card-text class="title"
                            >Printed Certificates</v-card-text
                          >
                          <v-divider class="my-2" vertical></v-divider>
                          <v-spacer />
                        </v-layout>
                      </v-card>

                      <v-layout row wrap align-center>
                        <v-flex xs12 sm12 md12>
                          <template>
                            <v-data-table
                              :headers="headersWithSl"
                              :items="getCertificateRegisterPrintDataSlData"
                              class="elevation-1"
                            >
                              <template v-slot:items="props">
                                <td>{{ props.index + 1 }}</td>
                                <td class="text-xs-left">
                                  {{ props.item.name }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.exam_roll }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.reg_no }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.session }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.subject_code }} -
                                  {{ props.item.subject }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.college }}
                                </td>
                                <td class="text-xs-center">{{ props.item.crt_sl }}</td>
                                <td class="text-xs-center"></td>
                              </template>
                            </v-data-table>
                          </template>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-tab-item>

                  <!-- c-print with roll and reg -->
                  <v-tab-item>
                    <v-card flat>
                      <v-layout row wrap style="margin-top: 30px">
                        <v-flex xs12 sm5 style="margin-left: 5%">
                          <v-text-field
                            label="Exam Roll"
                            prepend-inner-icon="numbers"
                            v-model="exam_roll_no"
                            outline
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm5 style="margin-left: 5%">
                          <v-text-field
                            label="Registration"
                            prepend-inner-icon="numbers"
                            v-model="reg_no"
                            outline
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout row wrap style="text-align: center">
                        <v-flex
                          xs12
                          sm12
                          style="padding-top: 10px; padding-bottom: 10px"
                        >
                          <v-btn
                            color="primary"
                            @click="getCertificatewithRollReg()"
                            ><v-icon color="light" style="padding-right: 5px"
                              >print</v-icon
                            >
                            Print</v-btn
                          >
                        </v-flex>
                      </v-layout>
                      <v-card style="margin-top: 20px">
                        <v-layout row>
                          <v-card-text class="title"
                            >Printed Certificates</v-card-text
                          >
                          <v-divider class="my-2" vertical></v-divider>
                          <v-spacer />
                        </v-layout>
                      </v-card>

                      <v-layout row wrap align-center>
                        <v-flex xs12 sm12 md12>
                          <template>
                            <v-data-table
                              :headers="headersWithReg"
                              :items="getCertificateRegisterPrintDataRegData"
                              class="elevation-1"
                            >
                              <template v-slot:items="props">
                                <td>{{ props.index + 1 }}</td>
                                <td class="text-xs-left">
                                  {{ props.item.name }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.exam_roll }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.reg_no }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.session }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.subject_code }} -
                                  {{ props.item.subject }}
                                </td>
                                <td class="text-xs-center">
                                  {{ props.item.college }}
                                </td>
                                <td class="text-xs-center">{{ props.item.crt_sl }}</td>
                                <td class="text-xs-center"></td>
                              </template>
                            </v-data-table>
                          </template>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import common_login_getters from "@/mixin/common_login_getters";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import commonGet from "../../mixin/common_getters_mixin";

export default {
  mixins: [common_login_getters, commonGet, commonExamYear],
  data() {
    return {
      headers: [
        {
          text: "SL",
          align: "center",
          sortable: false,
        },
        { text: "name", align: "center", value: "name", sortable: false },
        { text: "Exam Roll", align: "center", value: "exam_roll" },
        { text: "Reg No", align: "center", value: "reg_no" },
        { text: "Session", align: "center", value: "session" },
        { text: "Subject Code & Name", align: "center", value: "subject_code" },
        { text: "College Name", align: "center", value: "college" },
        { text: "Crt Sl", align: "center", value: "crt_sl" },
        { text: "Print Status", align: "center", value: "" },
      ],

      headersWithSl: [
        {
          text: "SL",
          align: "center",
          sortable: false,
        },
        { text: "name", align: "center", value: "name", sortable: false },
        { text: "Exam Roll", align: "center", value: "exam_roll" },
        { text: "Reg No", align: "center", value: "reg_no" },
        { text: "Session", align: "center", value: "session" },
        { text: "Subject Code & Name", align: "center", value: "subject_code" },
        { text: "College Name", align: "center", value: "college" },
        { text: "Crt Sl", align: "center", value: "crt_sl" },
        { text: "Print Status", align: "center", value: "" },
      ],

      headersWithReg: [
        {
          text: "SL",
          align: "center",
          sortable: false,
        },
        { text: "name", align: "center", value: "name", sortable: false },
        { text: "Exam Roll", align: "center", value: "exam_roll" },
        { text: "Reg No", align: "center", value: "reg_no" },
        { text: "Session", align: "center", value: "session" },
        { text: "Subject Code & Name", align: "center", value: "subject_code" },
        { text: "College Name", align: "center", value: "college" },
        { text: "Crt Sl", align: "center", value: "crt_sl" },
        { text: "Print Status", align: "center", value: "" },
      ],
     
      nextCMTtab: 0,
      text1: "Exam roll/ Reg wise",
      text2: "College & Subject Wise",
      text3: "Serial wise",

      valueOfTotal: "",
      valueOfTotalSl: "",
      valueOfTotalReg: "",

      sumOfc: "",
      sumOfsl: "",

      totalSum: "",
      totalSumsl: "",

      crt: {},

      pdfUrl: "",
      pdfUrlSl: "",
      pdfUrlRoll: "",

      com_start: "",
      com_end: "",

      exam_roll_no: "",
      reg_no: "",
    };
  },

  created(){
    //this.getCertificateRegisterPrint();
  },

  computed: {
    getCertificateRegisterPrintDataSet() {
      return this.$store.getters.getCertificateRegisterPrintData;
    },

    getCertificateRegisterPrintDataSlData(){
      return this.$store.getters.getCertificateRegisterPrintDataSl;
    },

    getCertificateRegisterPrintDataRegData(){
      return this.$store.getters.getCertificateRegisterPrintDataReg;
    }
  },

  methods: {
    totalCprintCol() {
      const sumOfcNumber = parseFloat(this.sumOfc);
      if (!isNaN(sumOfcNumber)) {
        this.totalSum = sumOfcNumber + this.valueOfTotal;
      } else {
        this.totalSum = "";
      }
      console.log("Tanvir, This is sum data", this.totalSum);
    },

    totalCprintSl() {
      const sumOfslNumber = parseFloat(this.sumOfsl);
      if (!isNaN(sumOfslNumber)) {
        this.totalSumsl = sumOfslNumber + this.valueOfTotalSl;
      } else {
        this.totalSumsl = "......";
      }
      console.log("Tanvir, This is sum data", this.totalSumsl);
    },

    getCertificateRegister(is_count) {
      this.loading = true;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      param.exam_initiate_id = this.getExamInitiateId;
      param.college_code_start = this.crt.col_code_from;
      param.college_code_end = this.crt.col_code_to;
      param.subject_code_start = this.crt.sub_code_from;
      param.subject_code_end = this.crt.sub_code_to;
      if (is_count) {
        param.is_count = is_count;
      } else {
        param.is_count = "";
      }
      this.$store
        .dispatch("fetchCertificateRegisterData", param)
        .then((data) => {
          if (data.total_count) {
            this.valueOfTotal = data.total_count;
            console.log("Tanvir this is total count", this.valueOfTotal);
          } else {
            window.open(data);
            this.getCertificateRegisterPrint();
          }
          console.log("Tanvir data of CMT", data);
        });
    },

    getCertificatewithComSL(is_count) {
      let SlParam = {};
      SlParam.com_start = this.com_start;
      SlParam.com_end = this.com_end;
      SlParam.exam_code = this.getLoggedInExam.code;
      SlParam.year = this.getLoggedInYear.year;
      SlParam.exam_initiate_id = this.getExamInitiateId;
      if (is_count) {
        SlParam.is_count = is_count;
      } else {
        SlParam.is_count = "";
      }
      this.$store
        .dispatch("fetchCertificateRegisterData", SlParam)
        .then((data) => {
          if (data.total_count) {
            this.valueOfTotalSl = data.total_count;
          } else {
            window.open(data);
            this.getCertificateRegisterPrintSl();
          }
        });
    },

    getCertificatewithRollReg() {
      let rollParam = {};
      rollParam.exam_code = this.getLoggedInExam.code;
      rollParam.year = this.getLoggedInYear.year;
      rollParam.exam_initiate_id = this.getExamInitiateId;
      rollParam.exam_roll_no = this.exam_roll_no;
      rollParam.reg_no = this.reg_no;
      console.log("Tanvir this is C-print with roll/reg", rollParam);
      this.$store
        .dispatch("fetchCertificateRegisterData", rollParam)
        .then((data) => {
          window.open(data);
          this.getCertificateRegisterPrintReg();
        });
    },

    getCertificateRegisterPrint() {
      let printCrt = {};
      printCrt.exam_code = this.getLoggedInExam.code;
      printCrt.year = this.getLoggedInYear.year;
      printCrt.exam_initiate_id = this.getExamInitiateId;
      printCrt.college_code_start = this.crt.col_code_from;
      printCrt.college_code_end = this.crt.col_code_to;
      printCrt.subject_code_start = this.crt.sub_code_from;
      printCrt.subject_code_end = this.crt.sub_code_to;
      printCrt.is_data = "1";
      console.log("Tanvir this is C-print with print data", printCrt);
      this.$store
        .dispatch("fetchCertificateRegisterPrintData", printCrt)
        .then((data) => {
          console.log("Ptint data called", data);
        });
    },

    getCertificateRegisterPrintSl() {
      let printCrt = {};
      printCrt.exam_code = this.getLoggedInExam.code;
      printCrt.year = this.getLoggedInYear.year;
      printCrt.exam_initiate_id = this.getExamInitiateId;
      printCrt.com_start = this.com_start;
      printCrt.com_end = this.com_end;
      printCrt.is_data = "1";
      console.log("Tanvir this is C-print with print data", printCrt);
      this.$store
        .dispatch("fetchCertificateRegisterPrintData", printCrt)
        .then((data) => {
          console.log("Ptint data called", data);
        });
    },

    getCertificateRegisterPrintReg() {
      let printCrt = {};
      printCrt.exam_code = this.getLoggedInExam.code;
      printCrt.year = this.getLoggedInYear.year;
      printCrt.exam_initiate_id = this.getExamInitiateId;
      printCrt.exam_roll_no = this.exam_roll_no;
      printCrt.reg_no = this.reg_no;
      printCrt.is_data = "1";
      console.log("Tanvir this is C-print with print data", printCrt);
      this.$store
        .dispatch("fetchCertificateRegisterPrintData", printCrt)
        .then((data) => {
          console.log("Ptint data called", data);
        });
    },
  },
};
</script>
