<template>
    <v-container fluid>
        <v-card style="margin-top: 60px">
            <v-card flat style="margin-bottom: 24px">
                <v-layout row>
                    <v-card-text class="title" style="margin-top: 10px">Admit Card Installment List</v-card-text>
                    <v-divider class="my-2" vertical></v-divider>
                    <v-spacer />
                    <v-flex class="customeDateSearch text-xs-right" xs2 style="margin-top: 16px; margin-left: 24px">
                        <v-layout row justify-center>
                            <v-dialog v-model="dialog" persistent max-width="600px">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" small dark v-on="on"><v-icon>add</v-icon> New
                                        Installment</v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline"><span v-if="editAdmitInstallmentsDataSet != ''">Edit</span> <span v-else>New</span> Installments</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container grid-list-md>
                                            <v-layout wrap>
                                                <v-flex xs12 sm6 md6>
                                                    <v-text-field v-model="searchParam.title" label="Installment Title*"
                                                        required></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 sm6 md6>
                                                    <v-text-field v-model="searchParam.installment_no"
                                                        label="Installment No*" type="number" required></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 sm6 md6>
                                                    <v-text-field v-model="searchParam.start_date" label="Start date*"
                                                        type="date" required></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 sm6 md6>
                                                    <v-text-field v-model="searchParam.end_date" label="End date*"
                                                        type="date" required></v-text-field>
                                                </v-flex>
                                                <v-flex xs12>
                                                    <v-select v-model="searchParam.status" :items="statusItems"
                                                        item-text="value" item-value="id" label="Select" persistent-hint
                                                        single-line></v-select>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" flat @click="dialog = false;">Close</v-btn>
                                        <v-btn v-if="editAdmitInstallmentsDataSet != ''" color="blue darken-1" flat @click="updateInstallment()">update</v-btn>
                                        <v-btn v-else color="blue darken-1" flat @click="createNewInstallment()">Save</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-data-table :headers="headers" :items="getadmitInstallmentsDataSet.data" class="elevation-1">
                    <template v-slot:items="props">
                        <td class="text-xs-left">{{ props.item.installment_no }}</td>
                        <td class="text-xs-left">{{ props.item.title }}</td>
                        <td class="text-xs-left">{{ props.item.start_date }}</td>
                        <td class="text-xs-left">{{ props.item.end_date }}</td>
                        <td class="text-xs-center">
                            <v-chip color="#fb2200" text-color="white" v-if="props.item.status == 0">Inactive</v-chip>
                            <v-chip color="#388E3C" text-color="white" v-if="props.item.status == 1">Active</v-chip>
                        </td>
                        <td class="text-xs-center">
                            <v-btn color="primary" fab small @click="editInstallmentDataSet(props.item.id)">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <v-alert :value="true" color="error" icon="warning">
                            Sorry, nothing to display here :(
                        </v-alert>
                    </template>
                </v-data-table>
            </v-card>
        </v-card>

    </v-container>
</template>
<script>
import commonGet from "@/mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
export default {
    mixins: [commonGet, commonExamYear],
    data() {
        return {
            searchParam: {
                status: 1,
                exam_initiate_id: null,
                exam_year: null,
                id:null,
            },
            headers: [
                { text: 'Installment No', value: 'installment_no', sortable: false },
                { text: 'Title', value: 'title', sortable: false },
                { text: 'Start Date', value: 'start_date', sortable: false },
                { text: 'End Date', value: 'end_date', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: 'Action', value: '', sortable: false },
            ],
            loading: false,
            dialog: false,
            statusItems: [
                {
                    id: 1,
                    value: "Active",
                },
                {
                    id: 0,
                    value: "Inactive",
                },
            ]
        }
    },
    created() {
        this.searchParam.exam_initiate_id = this.getExamInitiateId;
        this.searchParam.exam_year = this.getLoggedInYear.year;;
        this.fetchAdmitInstallmentDatalist();
    },
    computed: {
        getadmitInstallmentsDataSet() {
            return this.$store.getters.getAdmitCardInstallmentData;
        },
        editAdmitInstallmentsDataSet() {
            return this.$store.getters.getEditAdmitCardInstallmentData;
        },
    },
    methods: {
        fetchAdmitInstallmentDatalist() {
            let peram = {};
            peram.exam_initiate_id = this.getExamInitiateId;
            peram.exam_code = this.getLoggedInExam.code;
            peram.exam_year = this.getLoggedInYear.year;
            console.log("This is Load Data", peram);
            this.loading = true;
            this.$store.dispatch("fetchAdmitInstallmentData", peram).then(() => {
                this.loading = false;
            });
        },

        createNewInstallment() {
            this.dialog = true;
            console.log('Tanvir this is new installment data', this.searchParam);
            this.$store.dispatch("postAdmitInstallmentData", this.searchParam).then(() => {
                this.loading = false;
                this.fetchAdmitInstallmentDatalist();
            });
        },

        editInstallmentDataSet(id) {
            console.log("Tanvir! this is edit data", id);
            this.$store.dispatch("editInstallmentData", id).then(() => {
                const data = this.editAdmitInstallmentsDataSet;
                this.searchParam.id = data.id;
                this.searchParam.title = data.title;
                this.searchParam.installment_no = data.installment_no;
                this.searchParam.start_date = data.start_date.split(' ')[0];
                this.searchParam.end_date = data.end_date.split(' ')[0];
                this.searchParam.status = data.status;
                this.loading = false;
                this.dialog = true;
            });
        },

        updateInstallment() {
            this.dialog = true;
            console.log('Tanvir this is new installment data', this.searchParam);
            this.$store.dispatch("updateAdmitInstallmentData", this.searchParam).then(() => {
                this.loading = false;
                this.fetchAdmitInstallmentDatalist();
            });
        },
    }
}
</script>
<style></style>