<template>
  <v-container fluid>
    <v-expansion-panel>
      <v-expansion-panel-content class="mycustomExpansionPanel">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
          </div>
        </template>
        <v-layout row wrap align-center>
          <v-flex>
            <v-card class="px-3">
              <v-layout row wrap align-center class="py-3">
                <v-text-field
                  v-model="searchParam.exam_roll"
                  type="number"
                  class="ma-2"
                  menu-props="auto"
                  label="Roll Number"
                  prepend-icon="edit"
                ></v-text-field>
                <v-text-field
                  v-model="searchParam.reg_no"
                  type="number"
                  class="ma-2"
                  menu-props="auto"
                  label="Registration Number"
                  prepend-icon="edit"
                ></v-text-field>
              </v-layout>
              <v-layout row wrap align-center style="margin-top: -26px">
                <v-flex xs12 md4>
                  <v-menu
                    class="ma-2"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="mt-4"
                        v-model="searchParam.Fromdate"
                        label="From Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        clearable
                         outline
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchParam.Fromdate"
                      @input="(menu1 = false), setDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md4 style="margin-left:20px;">
                  <v-menu
                    class="ma-2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="mt-4"
                        v-model="searchParam.Todate"
                        label="To Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        clearable
                         outline
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchParam.Todate"
                      @input="(menu2 = false), setDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm12 md12 style="margin-top: 10px; margin-bottom:20px;">
                  <v-layout justify-center row fill-height align-center>
                    <v-btn
                      color="primary"
                      large
                      @click="getCorrectedDataforAdmitGen()"
                      >Search</v-btn
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-card style="margin-top: 60px">
      <v-card flat style="margin-bottom: 24px">
        <v-layout row>
          <v-card-text class="title" style="margin-top: 10px"
            >Admit Card Correction / Duplicate List</v-card-text
          >
          <v-divider class="my-2" vertical></v-divider>
          <v-flex xs4 sm4 d-flex style="margin-top: 6px; margin-left: 24px">
            <v-select
              v-model="searchParam.goptions"
              :items="goptions"
              label="Select Status"
              prepend-icon="map"
              @input="getCorrectedDataforAdmitGen(searchParam.goptions)"
            ></v-select>
          </v-flex>
          <v-flex
            class="customeDateSearch text-xs-right"
            xs2
            style="margin-top: 16px; margin-left: 24px"
          >
            <v-btn
              color="primary"
              small
              style="margin-bottom: 20px"
              @click="generateAdmitCard(userIds)"
            >
              Generate Admit Card
            </v-btn>
          </v-flex>
          <v-flex
            class="customeDateSearch text-xs-right"
            xs2
            style="margin-top: 16px"
          >
            <v-btn
              color="primary"
              small
              style="margin-bottom: 20px"
              @click="getCorrectedDataforAdmitGen(searchParam.goptions, 1)"
            >
              <v-icon>history</v-icon>&nbsp;Reload
            </v-btn>
          </v-flex>
          <v-spacer />
        </v-layout>
      </v-card>
      <v-flex
        class="customeDateSearch text-xs-left"
        xs2
        style="margin-bottom: -41px; margin-left: 16px"
      >
        <input
          type="checkbox"
          @click="selectAll()"
          v-model="allSelected"
          style="font-size: 18px; padding: 10px; width: 15px; height: 15px"
        />
      </v-flex>
      <!-- total-items="pagination.totalItems"
        :pagination.sync="pagination"
        :rows-per-page-items="pagination.rowsPerPageItems" -->
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="getCorrectedAdmitDataSet"
        class="elevation-1"
      >
        <template v-slot:items="props">
          <td style="width: 8%">
            <input
              class="largerCheckbox"
              type="checkbox"
              v-model="userIds"
              @click="select()"
              :value="props.item"
              :disabled="props.item.generate_status == 'G'"
            />
          </td>
          <td>{{ props.index + 1 }}</td>
          <td>{{ props.item.student_name }}</td>
          <td class="text-xs-center">{{ props.item.reg_no }}</td>
          <td class="text-xs-center">{{ props.item.exam_roll }}</td>
          <td class="text-xs-center">{{ props.item.session_year }}</td>
          <td class="text-xs-center">{{ props.item.job_type }}</td>
          <td class="text-xs-center">{{ props.item.transaction_id }}</td>
          <td class="text-xs-center">
            {{ props.item.payment_date ? props.item.payment_date : "" }}
          </td>
          <td class="text-xs-center" v-if="props.item.generate_status == 'G'">
            <v-btn color="#2E7D32" dark small> g </v-btn>
          </td>
          <td class="text-xs-center" v-if="props.item.generate_status == 'NG'">
            <v-btn color="#C62828" dark small> not-g </v-btn>
          </td>
          <td class="text-xs-center" v-if="props.item.generate_status == 'P'">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </td>
          <td class="text-xs-center">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark fab small v-on="on">
                  <v-icon color="light" style="font-size: 22px">history</v-icon>
                </v-btn>
              </template>
              <span>History</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark fab small v-on="on">
                  <v-icon color="light" style="font-size: 22px"
                    >visibility</v-icon
                  >
                </v-btn>
              </template>
              <span>View</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="#EEEEEE"
                  dark
                  fab
                  small
                  v-on="on"
                  @click="
                    getAdmit(
                      props.item.admit_card_url,
                      props.item.generate_status
                    )
                  "
                  style="border: 1px solid #d32f2f"
                >
                  <img
                    src="@/assets/nu_logos/pdf1.png"
                    style="color: #fff; width: 60% !important"
                  />
                </v-btn>
              </template>
              <span v-if="props.item.generate_status == 'G'"
                >Download Admit Card</span
              >
              <span v-if="props.item.generate_status == 'NG'"
                >Admit Not Generated</span
              >
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
      <!-- <span>Selected Ids: {{ userIds }}</span> -->
    </v-card>
  </v-container>
</template>

<script>
import { baseURL } from "@/store/api";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import commonGet from "@/mixin/common_getters_mixin";
export default {
  mixins: [commonGet, commonExamYear],
  data() {
    return {
      menu1: false,
      menu2: false,
      loading: false,
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 25, { text: "All", value: -1 }],
      },
      searchParam: {
        reg_no: "",
        full: true,
        admitcard: true,
        final: true,
        Fromdate: "",
        Todate: "",
        goptions: "All",
      },
      admitParam: {},
      goptions: ["All", "Generated", "Not-Generated", "Processing"],
      headers: [
        {
          text: "All",
          align: "right",
          sortable: false,
          value: "",
        },
        {
          text: "SL",
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "student_name",
        },
        {
          text: "Registration",
          value: "reg_no",
          align: "center",
        },
        {
          text: "Roll No",
          value: "exam_roll",
          align: "center",
        },
        {
          text: "Session",
          value: "session_year",
          align: "center",
        },
        {
          text: "Job Type",
          value: "job_type",
          align: "center",
        },
        {
          text: "Transaction ID",
          value: "transaction_id",
          align: "center",
        },
        {
          text: "Date",
          value: "payment_date",
          align: "center",
        },
        {
          text: "G Status",
          value: "generate_status",
          align: "center",
        },
        {
          text: "Actions",
          value: "",
          align: "center",
        },
      ],
      selected: [],
      allSelected: false,
      userIds: [],
    };
  },
  created() {
    this.getCorrectedDataforAdmitGen();
  },
  computed: {
    getCorrectedAdmitDataSet() {
      return this.$store.getters.getAdmitCardCurrectionData;
    },
  },
  methods: {
    setDate() {
      if (this.searchParam.Fromdate && !this.searchParam.Todate) {
        this.searchParam.Todate = this.searchParam.Fromdate;
        console.log("This is Fromdate tanvir", this.searchParam.Todate);
      } else if (this.searchParam.Todate && !this.searchParam.Fromdate) {
        this.searchParam.Fromdate = this.searchParam.Todate;
        console.log("This is Todate tanvir", this.searchParam.Fromdate);
      }
    },
    getCorrectedDataforAdmitGen(goptions, type) {
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      peram.job_date = this.todaysDate;
      if (goptions == "Generated") {
        peram.generate_status = "G";
      } else if (goptions == "Not-Generated") {
        peram.generate_status = "NG";
      } else if (goptions == "Processing") {
        peram.generate_status = "P";
      } else if (goptions == "Processing" && type == 1) {
        peram.generate_status = "P";
      } else {
        peram.generate_status = "";
      }
      peram.reg_no = this.searchParam.reg_no;
      peram.exam_roll = this.searchParam.exam_roll;
      peram.from_date = this.searchParam.Fromdate;
      peram.to_date = this.searchParam.Todate;
      console.log("This is Load Data", peram);
      this.loading = true;
      this.$store.dispatch("fecthAdmitCorrectedData", peram).then(() => {
        this.loading = false;
      });
    },
    getAdmit(admit, status) {
      console.log(admit, status);
      if (status == "G") {
        window.open(admit);
      }
    },
    selectAll() {
      this.userIds = [];
      if (this.allSelected == false) {
        for (var dessert in this.getCorrectedAdmitDataSet) {
          if (this.getCorrectedAdmitDataSet[dessert].generate_status == "NG") {
            this.userIds.push(this.getCorrectedAdmitDataSet[dessert]);
            this.admitParam = this.getCorrectedAdmitDataSet[dessert];
          }
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    generateAdmitCard(items) {
      console.log("Tanvir! This is selected admitData", items);
      if (items.length <= 0) {
        this.$root.$confirm("Please select Examinee First");
      } else {
        this.$root
          .$confirm("Are you sure that you want to Generate?")
          .then((confirm) => {
            if (confirm) {
              let admitGenPeram = {};
              admitGenPeram.full = 'true';
              admitGenPeram.items = items;
              console.log("Tanvir! This is admit gen data", admitGenPeram);
              this.$store
                .dispatch("generateAdmitCorrectedData", admitGenPeram)
                .then(() => {
                  this.allSelected = "";
                  this.userIds = [];
                  let peram = {};
                  peram.exam_initiate_id = this.getExamInitiateId;
                  peram.exam_code = this.getLoggedInExam.code;
                  peram.exam_year = this.getLoggedInYear.year;
                  peram.job_date = this.todaysDate;
                  peram.generate_status = "P";
                  console.log("This is Load Data", peram);
                  this.$store.dispatch("fecthAdmitCorrectedData", peram);
                });
            }
          });
      }
    },
  },
};
</script>

<style>
.largerCheckbox {
  width: 15px;
  height: 15px;
}
</style>


