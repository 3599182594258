<template>
  <v-container fluid>
    <!-- result details hons -->
    <v-dialog v-model="paperDialog" persistent max-width="1600">
      <v-toolbar>
        <v-toolbar-title>
          <!-- <div class="title">Course Details</div> -->
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn round outline flat @click="paperDialog = false">
          <span class="mx-2 subheading">Close</span>( <v-icon>close</v-icon>)
        </v-btn>
      </v-toolbar>
      <v-card height="800">
        <div v-html="stdResultDetails" id="htmlContainer"></div>
      </v-card>
    </v-dialog>

    <v-card>
      <v-toolbar color="primary" class="white--text" dense>
        <v-toolbar-title>Result List</v-toolbar-title>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex md12>
            <v-card flat v-for="(data, index) in getSchema" :key="data.id">
              <!-- {{ data }} -->
              <v-card-title primary-title class="justify-center">
                <div>
                  <div class="headline mb-0">
                    {{ data.core_exam ? data.core_exam.exam_name : "" }}
                    Examination - {{ data.exm_year }}
                  </div>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3">
                <span>
                  <v-chip
                    outline
                    color="blue-grey darken-4"
                    class="font-weight-bold"
                  >
                    Published on : {{ formatted_date(data.res_date) }}
                  </v-chip>
                </span>
                <v-spacer></v-spacer>
                <v-btn round color="info" @click="showDetails(data.id)"
                  >Result Details</v-btn
                >
                <v-btn round color="primary" v-if="data.result == 'P'" @click="downloadStdResult(data)"
                  >Download Result</v-btn
                >
              </v-card-actions>
              <hr />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    paperDialog: false,
    stdResultDetails: ""
  }),

  computed: {
    getSchema() {
      return this.$store.getters.fetchStdResultList;
    }
  },

  created() {
    this.$store.dispatch("fetchStdResultList");
  },
  methods: {
    showDetails(id) {
      this.stdResultDetails = "";
      this.paperDialog = true;
      this.$store.dispatch("fetchStdResultDetailList", id).then(data => {
        this.stdResultDetails = data;
      });
    },
    downloadStdResult(data) {
      let file_name = data.exm_code + "_" + data.exm_year + "_" + data.exm_roll;
      this.$store.dispatch("downloadStdResultDetails", {
        params: {
          result_id: data.id,
          file_name: file_name
        },
        responseType: "blob"
      });
    },
    formatted_date(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>

<style scoped>
.v-card__title--primary {
  padding-top: 0;
}

.v-card__title {
  padding-bottom: 16px;
}
</style>
