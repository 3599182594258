<template>
  <v-container fluid>
    <v-flex xs12>
      <v-card>
        <v-card-title class="cyan">
          <span class="white--text text-md-center"> Search </span>
        </v-card-title>

        <v-card-text>
          <form ref="form">
            <v-container fluid grid-list-md>
              <v-card color="white">
                <v-layout row ml-2>
                  <v-text-field
                    v-model="getSchema.reg_no"
                    class="mt-0"
                    flat
                    hide-details
                    label="REG No."
                    prepend-inner-icon="search"
                    single-line
                    solo
                  ></v-text-field>
                  <v-card-actions>
                    <v-btn @click="search" color="info" dark>Search</v-btn>
                  </v-card-actions>
                </v-layout>
              </v-card>
            </v-container>
          </form>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex
      xs12
      mt-3
      v-if="
        getSchema.reg_no &&
        getSchema.form_fill_up.papers &&
        getSchema.form_fill_up.required_credit
      "
    >
      <v-card>
        <v-card-title class="cyan">
          <span class="white--text text-md-center">
            Form Fill-up Correction
          </span>
        </v-card-title>

        <v-card-text v-if="getSchema.form_fill_up.papers">
          <form>
            <v-container grid-list-md text-xs-center>
              <v-layout row>
                <v-flex>
                  <table class="v-datatable v-table theme--light">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Current Information</th>
                        <th>New Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Student Name :</td>
                        <td class="text-xs-left">
                          {{ getSchema.form_fill_up.current_name }}
                        </td>
                        <td>
                          <v-text-field
                            class="text-left mt-0 pt-0"
                            type="text"
                            v-model="getSchema.form_fill_up.name"
                            name="name"
                            placeholder="Student Name"
                            prepend-icon="edit"
                            data-vv-name="name"
                            :error-messages="errors.collect('name')"
                            v-validate="'alpha_spaces'"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td>Father's Name :</td>
                        <td class="text-xs-left">
                          {{ getSchema.form_fill_up.current_father_name }}
                        </td>
                        <td>
                          <v-text-field
                            class="text-left mt-0 pt-0"
                            type="text"
                            v-model="getSchema.form_fill_up.father_name"
                            name="father_name"
                            placeholder="Father's Name"
                            prepend-icon="edit"
                            data-vv-name="father_name"
                            :error-messages="errors.collect('father_name')"
                            v-validate="'alpha_spaces'"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td>Mother's Name :</td>
                        <td class="text-xs-left">
                          {{ getSchema.form_fill_up.current_mother_name }}
                        </td>
                        <td>
                          <v-text-field
                            class="text-left mt-0 pt-0"
                            type="text"
                            v-model="getSchema.form_fill_up.mother_name"
                            name="mother_name"
                            placeholder="Mother's Name"
                            prepend-icon="edit"
                            data-vv-name="mother_name"
                            :error-messages="errors.collect('mother_name')"
                            v-validate="'alpha_spaces'"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
              </v-layout>
              <v-data-table
                :headers="headers"
                :items="getSchema.form_fill_up.papers"
                class="elevation-1"
                style="white-space: nowrap"
                :hide-actions="true"
              >
                <template v-slot:items="props">
                  <td class="text-xs-left">
                    <v-checkbox
                      v-model="props.item.confirm_paper"
                      color="green"
                      hide-details
                      v-on:change="calculate_selected_credit"
                      false-value="0"
                      true-value="1"
                      :disabled="
                        Number(props.item.expired_exam) == 1 ? true : false
                      "
                    ></v-checkbox>
                  </td>
                  <td
                    v-if="props.item.exam_date"
                    :class="[
                      Number(props.item.expired_exam)
                        ? 'text-xs-left orange--text'
                        : 'text-xs-left',
                    ]"
                  >
                    {{ props.item.exam_date | dateFormat }}
                  </td>
                  <td v-else>--</td>
                  <td
                    v-if="
                      getSchema.form_fill_up.ff_criteria == 1 &&
                      props.item.total_incourse_marks > 0 &&
                      props.item.confirm_paper == 1 &&
                      !Number(props.item.expired_exam)
                    "
                    class="text-xs-right"
                    background-color="white"
                  >
                    <v-text-field
                      :class="
                        'text-left mt-0 pt-0' +
                        {
                          'is-danger': errors.has(
                            ['incourse_marks', props.item.id].join('_')
                          ),
                        }
                      "
                      placeholder="Incourse Marks"
                      :ref="['incourse_marks', props.item.id].join('_')"
                      type="number"
                      v-model="props.item.incourse_marks"
                      name="incourse_marks"
                      :data-vv-name="
                        ['incourse_marks', props.item.id].join('_')
                      "
                      :error-messages="
                        errors.collect(
                          ['incourse_marks', props.item.id].join('_')
                        )
                      "
                      v-validate="
                        'between:' +
                        props.item.minimum_incourse_marks +
                        ',' +
                        props.item.total_incourse_marks
                      "
                      prepend-icon="edit"
                    ></v-text-field>
                  </td>
                  <td
                    v-else-if="props.item.incourse_marks > 0"
                    class="text-xs-center"
                  >
                    {{ props.item.incourse_marks }}
                  </td>
                  <td v-else class="text-xs-center"></td>
                  <td class="text-xs-left">{{ props.item.paper_code }}</td>
                  <td class="text-xs-left">{{ props.item.paper_name }}</td>
                  <td class="text-xs-left">
                    {{ props.item.paper_type_title }}
                  </td>
                  <td class="text-xs-center">{{ props.item.credit }}</td>
                </template>
              </v-data-table>
              
                  <v-textarea
                    class="mt-2"
                    v-model="getSchema.form_fill_up.remarks"
                    background-color="grey lighten-2"
                    color="cyan"
                    label="Remarks"
                    prepend-inner-icon="mdi-comment"
                    filled
                    auto-grow
                    box
                    rows="2"
                    row-height="20"
                    name="remarks"
                    data-vv-name="remarks"
                    :error-messages="errors.collect('remarks')"
                    v-validate="'required'"
                  ></v-textarea>
            </v-container>
            <slot
              v-if="
                getSchema.selected_credit <
                getSchema.form_fill_up.required_credit
              "
            >
              <v-alert :value="true" type="warning">
                At least {{ getSchema.form_fill_up.required_credit }} credits
                must be selected
              </v-alert>
            </slot>
            <v-chip class="ma-2" color="green" text-color="white">
              Selected Credit
              <v-avatar
                right
                class="green darken-4"
                style="margin-left: 8px; margin-right: -12px"
              >
                {{ getSchema.selected_credit }}
              </v-avatar>
            </v-chip>
            <slot
              v-if="
                getSchema.selected_credit >=
                getSchema.form_fill_up.required_credit
              "
            >
              <v-btn class="right" color="success" @click="submit"
                >submit</v-btn
              >
              <!-- <v-btn color="error" @click="clear">clear</v-btn> -->
            </slot>
          </form>
        </v-card-text>
      </v-card>
    </v-flex>
    <!-- <pre>{{ errors }}</pre> -->
    <!-- <pre>{{ getExamInitiateId }}</pre> -->
    <!-- <pre>{{ getLoggedInYear }}</pre> -->
    <!-- <pre>{{ getLoggedInExam }}</pre> -->
    <!-- <pre>{{ getSchema }}</pre> -->
  </v-container>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
const isBetween = {
  getMessage(field, [min, max]) {
    // will be added to default locale messages.
    // Returns a message.
    return "The incourse mark must be between " + min + " and " + max;
  },
  validate(value, { min, max }) {
    // Returns a Boolean or a Promise that resolves to a boolean.
    return Number(min) <= value && Number(max) >= value;
  },
};
const paramNames = ["min", "max"];
export default {
  $_veeValidate: {
    validator: "new",
  },
  created() {
    this.getSchema.exam_initiate_id = this.getExamInitiateId;
    this.getSchema.exam_year = this.getLoggedInYear.year;
    this.getSchema.exam_code = this.getLoggedInExam.code;
  },
  data() {
    return {
      headers: [
        { text: "Select", value: "confirm_paper", sortable: false },
        { text: "Exam Date", value: "exam_date", sortable: false },
        { text: "Incourse Mark", value: "incourse_marks", sortable: false },
        { text: "Course Code", value: "paper_code", sortable: false },
        { text: "Course Name", value: "paper_name", sortable: false },
        { text: "Course Type", value: "paper_type_title", sortable: false },
        { text: "Course Credit", value: "credit", sortable: false },
      ],
      validationRules: {
        required(value) {
          return (value || "") >= !!value || `Minimum  mark ${min} `;
        },
        min(value, min) {
          return (value || "") >= min || `Minimum  mark ${min} `;
        },
        max(value, max) {
          return (value || "") <= max || `Maximum mark ${max} `;
        },
        application_no: [(v) => !!v || "Application No is required"],
        incourse_marks: [
          (v) => !!v || "Incourse Marks is required",
          (v) => v <= 20 || "Maximum 20",
          (v) => v >= 7 || "Minimum 7",
        ],
        term_paper_marks: [(v) => !!v || "Trem Paper Marks is required"],
      },
    };
  },
  watch: {
    "getSchema.reg_no": function (val, oldVal) {
      this.$store.commit("UNSET_FF_PAPER_EXCHANGE_PAPER_CODES");
    },
    "getSchema.form_fill_up": function (val, oldVal) {
      this.calculate_selected_credit();
    },
  },
  computed: {
    getLoggedInExam() {
      return this.$store.getters.getLoggedInExam;
    },
    getLoggedInYear() {
      return this.$store.getters.getLoggedInYear;
    },
    getExamInitiateId() {
      return this.$store.getters.getExamInitiateId;
    },
    getSchema() {
      return this.$store.getters.getPaperExchangeSchema;
    },
  },
  methods: {
    calculate_selected_credit() {
      if (this.getSchema.form_fill_up.papers) {
        this.getSchema.selected_credit = 0;
        this.getSchema.form_fill_up.papers.forEach((paper) => {
          if (paper.confirm_paper == 1) {
            this.getSchema.selected_credit += Number(paper.credit);
          }
        });
        console.log(this.getSchema.selected_credit);
      }
    },
    search() {
      this.$store.dispatch("getFFPaperExchangePaperCodes", this.getSchema);
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$root
            .$confirm("Are you sure that you want to Submit?")
            .then((confirm) => {
              if (confirm) {
                this.$store
                  .dispatch("submitFFPaperExchangePaperCodes", this.getSchema)
                  .then(() => {
                    // console.log("success");
                  })
                  .catch((error) => {})
                  .then((always) => {
                    this.search();
                  });
              }
            });
        }
      });
    },
    clear() {},
  },
  mounted() {
    this.$validator.extend("between", isBetween, {
      paramNames, //  pass it in the extend options.
    });
    // this.$validator.localize("en", this.dictionary);
  },
};
</script>

<style>
.incourse_marks imnput {
  text-align: right !important;
}
</style>
