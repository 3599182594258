<template>
  <v-container fluid>
    <v-layout column>
      <v-flex xs12>
        <v-card class="px-5" color="#FAFAFA" style="margin-bottom:50px;">
          <v-layout row wrap align-center class="py-3">
            <v-autocomplete
              class="ma-2"
              v-model="searchParam.paper_code"
              :items="getPracticalPaperItem"
              :item-text="(item) => item.paper_code + ' - ' + item.paper_name"
              item-value="paper_code"
              label="Select Course"
              persistent-hint
              prepend-icon="library_books"
              :loading="paperLoading"
            >
              <template v-slot:selection="data">
                <v-chip
                  color="#EBEBEB"
                  close
                  @input="searchParam.paper_code = ''"
                  :selected="data.selected"
                  class="chip--select-multi"
                  >{{
                    data.item.paper_code + " - " + data.item.paper_name
                  }}</v-chip
                >
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item"></v-list-tile-content>
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <v-list-tile-title
                      v-html="
                        data.item.paper_code + ' - ' + data.item.paper_name
                      "
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn color="primary" fab round small @click="bothSearch">
              <v-icon center dark>search</v-icon>
            </v-btn>
          </v-layout>
        </v-card>

        <v-card color="white">
          <v-layout row>
            <v-card flat>
              <v-badge left overlap>
                <template v-slot:badge>
                  <v-btn
                    round
                    color="primary"
                    fab
                    small
                    style="margin-top: -1.2em"
                    class="elevation-0"
                    >{{
                      pagination.totalItems ? pagination.totalItems : 0
                    }}</v-btn
                  >
                </template>
                <v-card-text class="title"
                  >Practical Candidate List</v-card-text
                >
              </v-badge>
            </v-card>
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
            <v-btn
              :disabled="!searchParam.center_code || !dateIsBetween"
              @click="downloadAttendenceSheet()"
              >Attendence List</v-btn
            >
            <v-btn
              :disabled="!searchParam.center_code || !dateIsBetween"
              @click="downloadPracticalCandidateList()"
              >Download Candidate List</v-btn
            >
          </v-layout>
        </v-card>

        <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="getPracticalCandiList"
        :expand="expand"
        :loading="loading"
        :pagination.sync="pagination"
        :total-items="pagination.totalItems"
        :rows-per-page-items="pagination.rowsPerPageItems"
      >
        <template v-slot:items="props">
          <!-- <tr @click="props.expanded = !props.expanded"> -->
          <td class="text-xs">{{ sl_count + props.index }}</td>
          <td class="text-xs">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ props.item.subject_name }}</span>
              </template>
              <span class="title">Code: {{ props.item.subject_code }}</span>
            </v-tooltip>
          </td>
          <td class="text-xs">
            {{ props.item.paper_code }} - {{ props.item.paper_name }}
          </td>
          <td class="text-xs">{{ props.item.exam_roll }}</td>
          <td class="text-xs">{{ props.item.reg_no }}</td>
          <td class="text-xs">{{ props.item.session }}</td>
          <td class="text-xs">{{ props.item.student_name }}</td>
          <!-- <td class="text-xs">
            <v-chip :class="showGender(props.item.gender)">
              {{
              showGender(props.item.gender)
              }}
            </v-chip>
          </td>-->
          <!-- ...  -->
          <!-- <td class="text-xs">{{ sl_count + props.index }}</td> -->

          <td class="text-xs">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on"
                  >{{ props.item.degree_group_code }} -
                  {{ props.item.degree_group_name }}</span
                >
              </template>
              <span class="title"
                >Code: {{ props.item.degree_group_code }}</span
              >
            </v-tooltip>
          </td>
          <!-- <td class="text-xs">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ props.item.exam_name }}</span>
              </template>
              <span class="title">Code: {{ props.item.exam_code }}</span>
            </v-tooltip>
          </td>-->
          <td class="text-xs">{{ props.item.year }}</td>
          <td class="text-xs">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ props.item.college_name }}</span>
              </template>
              <span class="title">Code: {{ props.item.college_code }}</span>
            </v-tooltip>
          </td>
          <td class="text-xs">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ props.item.degree_name }}</span>
              </template>
              <span class="title">Code: {{ props.item.degree_code }}</span>
            </v-tooltip>
          </td>
          <td class="text-xs">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ props.item.degree_group_name }}</span>
              </template>
              <span class="title"
                >Code: {{ props.item.degree_group_code }}</span
              >
            </v-tooltip>
          </td>

          <!--   <td class="text-xs">
            <v-btn outline color="info" @click="showPaperDetails(props.item.details)">
              <b class="mr-2">{{ props.item.details.length }}</b>
              <span v-if="props.item.details.length - 1">Papers</span>
              <span v-else class="mr-2">Paper</span>
            </v-btn>
          </td>-->
        </template>
      </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      searchParam: {},
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [5, 10, 25, { text: "All", value: -1 }],
      },
      getPracticalCandiList: [],
      expand: false,
      loading: false,
      headers: [
        { text: "SL", value: "sl" },
        { text: "Subject", value: "subject_code" },
        { text: "Course", value: "paper_code" },

        { text: "Exam Roll", value: "exam_roll" },
        { text: "Registration No", value: "reg_no" },
        { text: "Session Year", value: "session_year" },
        { text: "Name", value: "student_name" },

        // { text: "Paper", value: "paper_code" },
        { text: "Degree Group", value: "degree_group_code" },
        // { text: "Exam", value: "exam_name" },
        { text: "Year", value: "year" },
        { text: "College", value: "college_name" },
        { text: "Degree ", value: "degree_code" },
        { text: "Degree Group", value: "degree_group_code" },

        // { text: "Paper Details", value: "mandatory_papers" }
      ],
    };
  },
};
</script>
<style lang=""></style>
