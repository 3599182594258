import Axios from "axios";
import {getDailyAttendanceWantingList} from "../../api";
import {GET_DAILY_ATTENDANCE_WANTING_LIST} from "../../mutation-types";

const state = {
wantingList:[],
};

const getters = {
    getAttendanceWantingList() {
        return state.wantingList;
    },
};

const mutations = {
    [GET_DAILY_ATTENDANCE_WANTING_LIST](state, response) {
        return state.wantingList = response;
    },
};

const actions = {
    fetchAttendanceWantingList({
        commit
    }, searchParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary"
            });
            // loading  Opening
            Axios.get(getDailyAttendanceWantingList, {
                    params: searchParam,
                })
                .then(function (response) {
                    commit("UNSET_LOADING"); // loading Closing
                    var result = response.data;
                    console.log("Tanvir, this is wanting data->", result);
                    commit("GET_DAILY_ATTENDANCE_WANTING_LIST", result);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit("UNSET_LOADING"); // loading Closing
                    console.log(error);
                    reject();
                });
        });
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};