<template>
  <v-container fluid>
    <v-card color="white">
      <Info></Info>
    </v-card>
  </v-container>
</template>
<script>
import avatar from "@/components/collegePanel/avatar.vue";
import Info from "@/components/collegePanel/info.vue";

export default {
  components: {
    avatar,
    Info
  },
  computed: {
    studentToken() {
      return this.$store.getters.getAuthStudentToken;
    }
    // authStudent() {
    //   return this.$store.getters.getAuthStudent;
    // }
  },
  created() {
    // this.setAcademicInfo();
  },
  methods: {
    setAcademicInfo() {
      this.$store.dispatch("setAcademicInfo");
    }
  }
};
</script>
<style lang="scss">
.student-profile {
  tr {
    th {
      text-align: left;
    }
  }
}
</style>
