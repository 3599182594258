<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Issued Items Return</v-btn>
      </template>
      <!-- dialog header -->
      <v-card>
        <v-toolbar dark color="indigo">
          <v-toolbar-title>Issued Items Receive From</v-toolbar-title>
          <v-spacer />
          <v-btn
            fab
            class="ma-1 pa-0"
            small
            color="red darken-3 white--text"
            @click="(dialog = false), clearobj(index)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- dialog body -->
        <v-snackbar
          style="margin-top: 40px"
          v-model="snackbar"
          color="red accent-2"
          :bottom="y === 'bottom'"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="timeout"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
          v-if="getExamIssuedItemReturnMess.status == 'error'"
        >
          {{ getExamIssuedItemReturnMess.message }}
          <v-btn color="light" flat @click="snackbar = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-snackbar>

        <v-snackbar
          style="margin-top: 40px"
          v-model="snackbar"
          color="green darken-3"
          :bottom="y === 'bottom'"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="timeout"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
          v-if="getExamIssuedItemReturnMess.status == 'success'"
        >
          {{ getExamIssuedItemReturnMess.message }}
          <v-btn color="light" flat @click="snackbar = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-snackbar>
        <div
        v-for="(itemDetails,index) in getCenter" :key="index">
            <div v-if="getCenter !== ''">
          <!-- Item Receiver from -->
          <v-container fluid grid-list-lg>
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <div class="dark--text">
                  <div id="input_area">
                    <template>
                      <v-form>
                        <v-layout>
                          <v-flex xs12 md12>
                            <!-- :value="itemDetails.exam_name" -->
                            <v-text-field
                              v-model="itemDetails.exam_name"
                              label="Exam"
                              disabled
                            ></v-text-field>
                            <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.received_no == ''"
                            >{{rcval}}</span
                          > -->
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field
                              v-model="itemDetails.year"
                              label="Exam Year"
                              required
                              disabled
                            ></v-text-field>
                            <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.received_by === ''"
                            >{{receiverval}}</span
                          > -->
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="itemDetails.exam_initiate_id"
                              label="Exam Initiate Id"
                              disabled
                            >
                            </v-text-field>
                            <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.received_no == ''"
                            >{{rcval}}</span
                          > -->
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="itemListObject.return_date"    
                              label="Return Date"
                              type="date"
                              required
                            >
                            </v-text-field>
                            <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.received_date === ''"
                            >{{dateval}}</span
                          > -->
                          </v-flex>
                        </v-layout>

                        <v-layout>
                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="itemListObject.slip_no"
                              label="Slip No"
                              type="number"
                            ></v-text-field>
                            <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.received_no == ''"
                            >{{rcval}}</span
                          > -->
                          </v-flex>
                          <v-flex xs12 md12>
                            <v-select
                              :items="itemDetails.center"
                              label="Center Name*"
                              item-text="center_name"
                              :item-value="(item) => item"
                              v-model="itemListObject.center_name"
                              @change="setCenterDetails($event)"
                              required
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="itemListObject.center_code"
                              label="Center Code"
                              disabled
                            >
                            </v-text-field>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="itemListObject.college_code"
                              label="College Code"
                              disabled
                            >
                            </v-text-field>
                          </v-flex>
                        </v-layout>

                        <v-layout>
                          <v-flex xs12 md6>
                            <v-text-field
                              v-model="itemListObject.returned_by"
                              label="Returned By"
                              required
                            >
                            </v-text-field>
                            <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.remarks === ''"
                            >{{remarksval}}</span
                          > -->
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field
                              v-model="itemListObject.received_by"
                              label="Received By"
                              required
                            >
                            </v-text-field>
                            <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.remarks === ''"
                            >{{remarksval}}</span
                          > -->
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-text-field
                              v-model="itemListObject.remarks"
                              label="Remarks"
                              required
                            >
                            </v-text-field>
                            <!-- <span
                            id="validet_mes"
                            v-if="itemListObject.remarks === ''"
                            >{{remarksval}}</span
                          > -->
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </template>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <!-- Item issue from -->
          <div id="input_area">
            <v-layout row wrap>
              <v-flex xs12 sm12>
                <v-toolbar>
                  <v-toolbar-title>
                    <div style="font-size: 26px">Returned Items</div>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card
                  color="grey lighten-5"
                  class="scroll dark--text"
                  height="320px"
                >
                  <template>
                    <v-form v-model="valid">
                      <v-container fluid>
                        <v-layout
                          row
                          v-for="(items, index) in itemListObject.item_details" :key="index"
                        >
                          <v-flex>
                            <h3 id="sl_number">{{ index + 1 }}</h3>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-select
                              :items="getExamStoreItemListData"
                              label="Item Name*"
                              item-text="item_name"
                              item-value="id"
                              v-model="items.item_id"
                              required
                            >
                            </v-select>
                          </v-flex>

                          <v-flex xs12 md4>
                            <v-select
                              :items="getExamStoreUnitList"
                              label="Unit In*"
                              item-text="unit_name"
                              item-value="id"
                              v-model="items.unit_id"
                              required
                            >
                            </v-select>
                          </v-flex>

                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="items.return_quantity"
                              label="Return Quantity"
                              type="number"
                              required
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="items.damage_quantity"
                              label="Damage Quantity"
                              type="number"
                              required
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="items.remarks"
                              label="Remarks"
                              required
                            ></v-text-field>
                            <!-- <span id="validet_mes" v-if="items.remarks === ''"
                            >Remarks is requred</span
                          > -->
                          </v-flex>

                          <v-flex xs12 md3 id="delete_btn">
                            <v-btn
                              fab
                              class="ma-1 pa-0"
                              small
                              color="red darken-3 white--text"
                              v-if="index == 0"
                              disabled
                              @click="removeRow(index)"
                            >
                              <v-icon>delete</v-icon>
                            </v-btn>
                            <v-btn
                              fab
                              class="ma-1 pa-0"
                              small
                              color="red darken-3 white--text"
                              v-if="index !== 0"
                              @click="removeRow(index)"
                            >
                              <v-icon>delete</v-icon>
                            </v-btn>

                            <v-btn
                              fab
                              class="ma-1 pa-0"
                              small
                              color="indigo darken-3 white--text"
                              @click="addRow()"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </template>
                </v-card>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    id="button_rcv"
                    color="indigo white--text"
                    text
                    @click="(dialog = true), saveReceive(itemListObject)"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </div>
        </div>
        </div>

        <div v-if="getCenter == ''">
          <v-card v-model="valid">
            <!-- dialog body -->
            <!-- Item Receiver from -->
            <v-container fluid grid-list-lg>
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <div class="dark--text">
                    <div id="input_area">
                      <template id="allart_col">
                        <h1 id="allart">Center is Not Allocated yet!</h1>
                      </template>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import common_login_getters from "@/mixin/common_login_getters";
export default {
    mixins: [
    commonGet,
    common_login_getters,
  ],
  data() {
    return {
      // rcval: "Receive no required",
      // mrrval: "MRR no requred",
      // dateval: "Date no requred",
      // receiverval: "Recever name is requred",
      // remarksval: "Remarks is requred",
      snackbar: false,
      y: "top",
      x: "right",
      mode: "",
      timeout: 6000,
      dialog: false,
      valid: false,
      firstname: "",
      lastname: "",
      year: "",
      itemListObject: {
        center_name: "",
        slip_no: "",
        return_date: "",
        exam_initiate_id: "",
        exam_code: "",
        exam_year: "",
        college_code: "",
        center_code: "",
        received_by: "",
        returned_by: "",
        remarks: "",
        item_details: [{}],
      },
      // RcRules: [(v) => !!v || "Receive No is is required"],
      // MrrRules: [(v) => !!v || "MRR no is required"],
      // DateRules: [(v) => !!v || "Receive Date no is required"],
      // RcByRules: [(v) => !!v || "Receive person name is required"],
      // RemarkRules: [(v) => !!v || "Remarks is required"],
      // itemRules: [(v) => !!v || "Select a item is required"],
      // unitRules: [(v) => !!v || "Select unit is required"],
      // quantityRules: [(v) => !!v || "Item Quantity is required"],
    };
  },
  created() {
    // this.addRow();
    this.fatchExamStoreItemList();
    this.fetchExamStoreUnitList();
  },
  computed: {
    getExamStoreItemListData() {
      return this.$store.getters.getExamStoreItemListData
        ? this.$store.getters.getExamStoreItemListData
        : [];
    },
    getExamStoreUnitList() {
      return this.$store.getters.getExamStoreItemUnitList
        ? this.$store.getters.getExamStoreItemUnitList
        : [];
    },
    getExamIssuedItemReturnMess() {
      return this.$store.getters.getExamIssuedItemReturnMess
        ? this.$store.getters.getExamIssuedItemReturnMess
        : [];
    },

    getCenter() {
      return this.$store.getters.getCenterAllocationItem
        ? this.$store.getters.getCenterAllocationItem
        : [];
    },
  },
  methods: {
    setCenterDetails(center_data) {
      console.log("This is center object", center_data);
      this.itemListObject.college_code = center_data.college_code;
      this.itemListObject.center_code = center_data.center_code;
    },
    saveReceive(itemListObject) {
      let peram = {};
      peram.center_code = itemListObject.center_code;
      peram.college_code = itemListObject.college_code;
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_initiate_id = this.getExamInitiateId;;
      peram.exam_year = this.getLoggedInYear.year;
      peram.item_details = itemListObject.item_details;
      peram.received_by = itemListObject.received_by;
      peram.remarks = itemListObject.remarks;
      peram.return_date = itemListObject.return_date;
      peram.returned_by = itemListObject.returned_by;
      peram.slip_no = itemListObject.slip_no;
      console.log("Tanvir, exam returned items", peram);
      this.$store.dispatch("saveIssuedItemReturn", itemListObject).then(() => {
        this.snackbar = true;
      });
    },

    addRow() {
      this.itemListObject.item_details.push({
        item_id: "",
        unit_id: "",
        return_quantity: "",
        damage_quantity: "",
        remarks: "",
      });
    },

    removeRow(index) {
      if (this.itemListObject.item_details.length > 1) {
        this.itemListObject.item_details.splice(index, 1);
      }
    },
    // validet() {
    //   this.RcRules;
    //   this.MrrRules;
    //   this.DateRules;
    //   this.RcByRules;
    //   this.RemarkRules;
    //   this.itemRules;
    //   this.unitRules;
    //   this.quantityRules;
    // },
    clearobj() {
      this.itemListObject = {
        center_name: "",
        slip_no: "",
        return_date: "",
        exam_initiate_id: "",
        exam_code: "",
        exam_year: "",
        college_code: "",
        center_code: "",
        received_by: "",
        returned_by: "",
        remarks: "",
        item_details: [],
      };
      this.itemListObject.item_details.push({
        item_id: "",
        unit_id: "",
        return_quantity: "",
        damage_quantity: "",
        remarks: "",
      });
    },

    //get Exam Store Item List
    fatchExamStoreItemList() {
      this.$store.dispatch("fetchExamStoreItemList");
    },

    //get Exam Store Item unit list
    fetchExamStoreUnitList() {
      this.$store.dispatch("fetchEditExamStoreItemsUnit");
    },
  },
};
</script>

<style>
#input_area {
  padding-left: 50px;
  padding-right: 50px;
}

.scroll {
  overflow-y: scroll;
}

#sl_number {
  margin-top: 20px;
}

#delete_btn {
  margin-top: 5px;
  text-align: center;
}

#validet_mes {
  color: brown;
}

#button_rcv {
  margin-top: 20px;
}

/* #examinee_count{

} */
#allart_col {
  text-align: center;
}

#allart {
  margin-top: 15%;
  margin-left: 35%;
  margin-bottom: 42.26%;
  color: brown;
}
</style>
