
<template>
  <Info></Info>
</template>

<script>
  import Info from "@/components/OrganizationPanel/Info.vue";
  export default {
    components : { Info }
  }
</script>
