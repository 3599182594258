<template>
  <div id="CollegeUserInfo">
    <v-layout row wrap v-if="profile">
      <v-flex xs9 sm9 md9 layout>
        <div class="mx-4">
          <span class="headline">{{
            `${profile.name} (College Code : ${profile.college_code})`
          }}</span>
          <div v-if="currentPrivilege">
            <span v-if="currentPrivilege.exam_master">
              <strong>Exam : </strong>
              {{ currentPrivilege.exam_master.exam_display_name }}
            </span>
            <span v-if="currentPrivilege.degree">
              <strong>, Degree :</strong>
              {{ currentPrivilege.degree.degree_display_name }}
            </span>
            <span v-if="currentPrivilege.degree_group">
              <strong>, Degree Group :</strong>
              {{ currentPrivilege.degree_group.degree_group_display_name }}
            </span>
            <span v-if="currentPrivilege.subject">
              <strong>, Subject :</strong>
              {{ currentPrivilege.subject.subject_name }}
            </span>
          </div>
          <!-- <div v-if="currentPrivilege"> -->
          <!-- <a  class="v-btn theme--dark primary" target="_blank" :href="getBaseUrl+`/api/approximate_fee_as_probable_data?exam_initiate_id=`+currentPrivilege.exam_initiate_id+'&college_code='+profile.college_code+'&degree_code='+currentPrivilege.degree_code+'&degree_group_code='+currentPrivilege.degree_group_code+'&subject_code='+currentPrivilege.subject_code+'&student_confirm_status=1&confirm_paper=1'">Export Fee Details As CSV</a> -->
          <!-- <a class="porto-button" target="_blank" :href="`http://103.4.145.242:8006/api/approximate_fee_as_probable_data?exam_initiate_id=`+currentPrivilege.exam_initiate_id+'&college_code='+profile.college_code+'&degree_code='+currentPrivilege.degree_code+'&degree_group_code='+currentPrivilege.degree_group_code+'&subject_code='+currentPrivilege.subject_code+'&student_confirm_status=1&confirm_paper=1'">Export Fee Details As CSV</a> -->
            <!-- <v-btn @click="downloadCsv" color="primary" dark>
            Export Fee Details As CSV
            <v-icon right dark>cloud_download</v-icon>
          </v-btn> -->
          <!-- </div> -->
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["currentPrivilege"],
  computed: {
    getBaseUrl() {
      return this.$store.getters.getBaseUrl;
    },
    user() {
      return this.$store.getters.getAuthCollegeUser;
    },
    profile() {
      return this.$store.getters.getCollegeUserProfile;
    },
    avatarSize() {
      return `${this.slider}px`;
    }
  },
  data: () => ({
    slider: 156,
    tile: true,
    test: "test"
  }),
  created() {
    this.$store.dispatch("getCollegeUserProfile");
  },
  methods: {
    downloadCsv() {
      let current_privilege = JSON.parse(
        localStorage.getItem("current-privilege")
      );
      this.$store.dispatch("clgUserFeeDetailsCsv", {
        params: {
          exam_initiate_id: current_privilege.exam_initiate_id,
          college_code: this.profile.college_code,
          degree_code: current_privilege.degree_code,
          degree_group_code: current_privilege.degree_group_code,
          subject_code: current_privilege.subject_code,
          student_confirm_status:1,
          confirm_paper:1
        },
        responseType: "blob"
      });
    }
  }
};
</script>
