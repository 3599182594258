<template>
  <div>
    <v-toolbar flat dark>
      <v-toolbar-title>Form Fill Up Fee (University)</v-toolbar-title>
    </v-toolbar>
    <table
      class="v-datatable v-table theme--light"
      v-if="getStudentFormFillUpFees.hasOwnProperty('fees')"
    >
      <tbody>
        <slot v-for="(item, index) in getStudentFormFillUpFees.fees">
          <tr :key="index" v-if="item.iteration">
            <th
              role="columnheader"
              scope="col"
              aria-label="Dessert (100g serving): Not sorted."
              aria-sort="none"
              class="column text-xs-left"
            >
              {{ item.fee_title }}
            </th>
            <td class="text-xs-right" v-if="item.tag == 'specialFormFillupFine'">
              <v-text-field
                v-model="item.amount"
                name="Fine"
                prepend-icon="edit"    
                type="number"   
                style="text-align:right !important">
              </v-text-field>
            </td>
            <td class="text-xs-right" v-else>
              {{
                `${item.amount}x${item.iteration}=${convertFloat(
                  item.iteration * item.amount
                )}`
              }}
            </td>
          </tr>         
        </slot>
        <tr v-if="getStudentFormFillUpFees.total > 0">
          <th
            role="columnheader"
            scope="col"
            aria-label="Dessert (100g serving): Not sorted."
            aria-sort="none"
            class="column text-xs-left"
          >
            Total
          </th>
          <td class="text-xs-right" id="totalFee">
            {{ convertFloat(getStudentFormFillUpFees.total) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "FormFillUpFee",
  created() {
    // this.getFeeHeads();
    // this.calculateFee();
  },
  computed: {
    profile() {
      let profile = JSON.parse(localStorage.getItem("student"));
      return profile;
    },

    getStudentFormFillUpFees() {   
     let fee = this.$store.getters.getStudentFormFillUpFees;
     if(fee.hasOwnProperty('fees')){
      //  this.specialFine = parseFloat(fee.fees.filter(feeHead => feeHead.tag == 'specialFormFillupFine')[0].amount);
       fee.total = fee.fees.reduce(function (prevValue, curValue) {
          return  parseFloat(curValue.iteration) > 0 ? prevValue + parseFloat(curValue.iteration * curValue.amount) : (prevValue + 0); 
        }, 0);
     }
     this.$parent.$parent.$parent.fees = fee;
     return fee;
    },
  },
  data() {
    return {
      //   form_fill_up_fee: {}
    };
  },
  methods: {
    convertFloat(val) {
      let value = Number(val);
      return parseFloat(value).toFixed(2);
    }
  }
};
</script>
