<template lang="">
  <v-container fluid>
    <v-card color="white">
      <v-layout row>
        <v-flex xs9 style="margin-left: 10px">
          <v-icon left style="font-size: 32px; margin-bottom: -53px"
            >person_pin</v-icon
          >
          <v-text-field
            type="number"
            v-model="searchParam.reg_no"
            style="margin-left: 40px"
            label="Enter Student's Regestration Number Here"
          ></v-text-field>
        </v-flex>
        <v-flex xs3 text-xs-center style="margin-top: 20px">
          <v-btn color="info" large @click="getStudentSummaryData()">
            <v-icon left dark>search</v-icon>Search
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>

    <v-card color="white" v-if="studentSummaryData != ''">
      <v-layout row>
        <v-flex xs12>
          <v-badge left overlap color="transparent">
            <v-card-text class="title">Student Summary </v-card-text>
          </v-badge>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
          <table style="width: 98%; margin-left: 14px">
            <tbody style="font-size: 18px">
              <tr>
                <td style="padding: 8px; padding-left: 14px">Student Name:</td>
                <td style="padding: 8px; padding-left: 14px">
                  {{
                    studentSummaryData.results
                      ? studentSummaryData.results.student_info
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td style="padding: 8px; padding-left: 14px">
                  Last Year/Session Result:
                </td>
                <td style="padding: 8px; padding-left: 14px">
                  {{
                    studentSummaryData.results
                      ? studentSummaryData.results.last_year_session_result
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td style="padding: 8px; padding-left: 14px">
                  Previous Exam Status: Reported Status
                </td>
                <td style="padding: 8px; padding-left: 14px">
                  {{
                    studentSummaryData.results
                      ? studentSummaryData.results.previous_exam_info
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td style="padding: 8px; padding-left: 14px">
                  Reported: Probable Status
                </td>
                <td style="padding: 8px; padding-left: 14px">
                  {{
                    studentSummaryData.results
                      ? studentSummaryData.results.reported_info
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td style="padding: 8px; padding-left: 14px">
                  Probable Status:
                </td>
                <td style="padding: 8px; padding-left: 14px">
                  <b>
                    {{
                      studentSummaryData.results
                        ? studentSummaryData.results.probable_list_info
                        : ""
                    }}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </v-flex>
      </v-layout>

      <v-layout row style=" margin-bottom:-10px;">
        <v-flex xs12 style="display: flex">
          <v-badge left overlap color="transparent">
            <v-card-text class="title" style="margin-left:8px; margin-top:10px;">
              <b>Result Summery:</b> (
              {{
                studentSummaryData.results
                  ? studentSummaryData.results.exam_initiate_info.exam_name
                  : ""
              }})
            </v-card-text>
          </v-badge>
          <v-divider class="my-2" vertical></v-divider>
          <v-spacer />

          <v-layout row>
            <v-flex
              xs12
              style="padding: 8px; padding-right: 16px; font-size: 19px; text-align:right; margin-top:16px;"
            >
              <span>
                <b>Number of Atends:</b> ({{
                  studentSummaryData.results
                    ? studentSummaryData.results.number_of_attends
                    : ""
                }})
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12 style="padding: 8px; padding-left: 30px; font-size: 17px">
          <span> </span>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-data-table
                v-if="studentSummaryData && studentSummaryData.results && studentSummaryData.results.results_summery"
                class="elevation-1"
                :headers="headers"
                :items="addBlankRows(studentSummaryData.results.results_summery)"
                hide-actions
              >
                <template v-slot:items="props">
                  <tr v-if="props.item.isBlank">
                    <td colspan="6" style="background-color:#fff;  border-left:none; border-right:none;"></td>
                  </tr>
                  <tr v-else :style="{ backgroundColor: props.item.exam_year === latestFYear && props.item.letter_grade === 'F' ? '#B71C1C' : '' }">
                    <td :style="{color: props.item.exam_year === latestFYear && props.item.letter_grade === 'F' ? '#fff' : ''}">
                      {{ props.item.exam_year }}
                    </td>
                    <td :style="{color: props.item.exam_year === latestFYear && props.item.letter_grade === 'F' ? '#fff' : ''}">
                      {{ props.item.status }}
                    </td>
                    <td :style="{color: props.item.exam_year === latestFYear && props.item.letter_grade === 'F' ? '#fff' : ''}">
                      {{ props.item.paper_code }}  {{ props.item.paper_name }}
                    </td>
                    <td :style="{color: props.item.exam_year === latestFYear && props.item.letter_grade === 'F' ? '#fff' : ''}">
                      {{ props.item.letter_grade }}
                    </td>
                    <td :style="{color: props.item.exam_year === latestFYear && props.item.letter_grade === 'F' ? '#fff' : ''}">
                      {{ props.item.credit }}
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <div
                v-else
                style="
                  padding: 8px;
                  padding-left: 30px;
                  font-size: 17px;
                  text-align: center;
                "
              >
                <v-alert :value="true" color="error" icon="warning">
                  sorry! no data available.
                </v-alert>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>

    <v-card v-else>
      <div
        style="
          padding: 8px;
          padding-left: 10px;
          font-size: 17px;
          text-align: center;
        "
      >
        <v-alert :value="true" color="error" icon="warning">
          search with registration number first !
        </v-alert>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";
export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
      searchParam: {},
      headers: [
        { text: "Exam Year", value: "exam_year", sortable: false },
        { text: "Result Type", value: "status", sortable: false },
        { text: "Paper Code & Name", value: "paper_code", sortable: false },
        { text: "Letter Grade", value: "exam_year", sortable: false },
        { text: "Credit", value: "exam_year", sortable: false },
      ],
    };
  },

  created() {},

  computed: {
    studentSummaryData() {
      return this.$store.getters.studentSummaryDetailsData;
    },

    latestFYear() {
      const items = this.studentSummaryData.results.results_summery;
      let latestYear = null;
      items.forEach(item => {
        if (item.letter_grade === 'F' && (!latestYear || item.exam_year > latestYear)) {
          latestYear = item.exam_year;
        }
      });
      return latestYear;
    }
  },

  methods: {
    getStudentSummaryData() {
      let peram = {};
      peram.reg_no = this.searchParam.reg_no;
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      peram.view_type = "json";
      this.loading = true;
      console.log("Tanvir! This student summary search param", peram);
      this.$store.dispatch("fetchStudentSummaryData", peram).then((data) => {
        console.log("Tanvir! returned data (STUDENT_SUMMARY)", data);
      });
    },

    addBlankRows(items) {
      let processedItems = [];
      items.forEach((item, index) => {
        processedItems.push(item);
        if (index < items.length - 1 && item.exam_year !== items[index + 1].exam_year) {
          processedItems.push({ isBlank: true });
        }
      });
      return processedItems;
    }
  },
};
</script>
<style lang=""></style>
