import {
    fetchCollegeDeptIndividualSpecialAdmitCardUrl
} from "../../api";
import { saveMsg, updateMsg, deleteMsg, failedMsg } from "../../erpUtil";
import Axios from "axios";
import {
} from "../../mutation-types";
const state = {};
const getters = {};
const mutations = {};
const actions = {
      fetchCollegeDeptIndividualSpecialAdmitCard({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary"
          });
          // loading  Opening
          Axios.get(fetchCollegeDeptIndividualSpecialAdmitCardUrl, {
            params: searchParam
          })
            .then(function(response) {
              commit("UNSET_LOADING"); // loading Closing
              var result = response.data;
              commit("SET_ADMIT_CARD_DATA_BY_INDIVIDUAL", result);
              resolve(response.data);
            })
            .catch(function(error) {
              commit("UNSET_LOADING"); // loading Closing
              console.log(error);
              reject();
            });
        });
      },
};
export default {
    state,
    getters,
    actions,
    mutations,
  };