<template lang="">
  <v-container fluid>
    <v-layout row wrap align-center>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-layout row>
            <v-card-text class="title">Scrutinizer List</v-card-text>
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
            <template>
              <v-layout
                row
                justify-center
                style="margin-right: 10px; margin-top: 5px"
              >
                <v-dialog v-model="dialog" width="900px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on"
                      >New Scrutinizer</v-btn
                    >
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-toolbar-title>
                        <v-icon>account_circle</v-icon> Create Scrutinizer
                        User</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                          icon
                          dark
                          color="red darken-3 white--text"
                          @click="eraseData()"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                      <v-layout
                        wrap
                        style="margin-top: -10px; padding-left: 10px"
                      >
                        <v-flex xs12 sm4 md6 style="padding-left: 0px">
                          <v-text-field
                            label="TMIS ID"
                            v-model="searchParam.tmis_id"
                            prepend-icon="touch_app"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap style="background-color: #dedede">
                        <p
                          style="
                            font-size: 20px;
                            padding-top: 10px;
                            padding-left: 10px;
                          "
                        >
                          User Info
                        </p>
                      </v-layout>
                      <v-layout
                        wrap
                        style="padding-left: 10px; padding-right: 10px"
                      >
                        <v-flex xs12 sm6 md8>
                          <v-text-field
                            label="Name"
                            v-model="searchParam.name"
                            prepend-icon="account_circle"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md4 style="padding-left: 0px">
                          <v-select
                            v-model="searchParam.designation_id"
                            :items="dataOfdesignationList"
                            item-text="designation"
                            item-value="designation_id"
                            class="mt-3 ml-2"
                            name="designation_id"
                            label="Designation"
                            prepend-icon="list"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md6 style="margin-left: -10px">
                          <v-select
                            v-model="searchParam.subject_code"
                            :items="getDegSubject"
                            item-text="subject_name"
                            item-value="subject_code"
                            class="mt-3 ml-2"
                            name="subject_code"
                            label="Subject"
                            prepend-icon="edit"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md6 style="margin-left: 10px">
                          <v-autocomplete
                            class="ma-2"
                            v-model="searchParam.college_code"
                            :item-text="
                              (item) =>
                                '(' +
                                item.college_code +
                                ') ' +
                                item.college_name
                            "
                            item-value="college_code"
                            :items="getCollegeSchema"
                            menu-props="auto"
                            label="Select College"
                            prepend-icon="subject"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                close
                                @input="searchParam.college_code = ''"
                                :selected="data.selected"
                                class="chip--select-multi"
                                >{{
                                  "(" +
                                  data.item.college_code +
                                  ") " +
                                  data.item.college_name
                                }}</v-chip
                              >
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-tile-content
                                  v-text="data.item"
                                ></v-list-tile-content>
                              </template>
                              <template v-else>
                                <v-list-tile-content>
                                  <v-list-tile-title
                                    v-html="
                                      '(' +
                                      data.item.college_code +
                                      ') ' +
                                      data.item.college_name
                                    "
                                  ></v-list-tile-title>
                                </v-list-tile-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                          <v-select
                            v-model="searchParam.examiner_list"
                            :items="MarkCountListByHeadExaminer"
                            :item-text="
                              (item) =>
                                '(' + item.tims_id + ') ' + item.examiner_name
                            "
                            item-value="tims_id"
                            name="tims_id"
                            label="Select Examiners"
                            prepend-icon="supervised_user_circle"
                            multiple
                          ></v-select>
                        </v-flex>
                      </v-layout>

                      <v-layout wrap style="background-color: #dedede">
                        <p
                          style="
                            font-size: 20px;
                            padding-top: 10px;
                            padding-left: 10px;
                          "
                        >
                          Account Info
                        </p>
                      </v-layout>
                      <v-layout wrap style="padding: 10px">
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="Bank Name"
                            v-model="searchParam.bank_name"
                            prepend-icon="account_balance"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="Branch Name"
                            v-model="searchParam.branch_name"
                            prepend-icon="account_balance"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4 md4 style="padding-left: 10px">
                          <v-text-field
                            label="Account No"
                            v-model="searchParam.account_no"
                            prepend-icon="account_balance"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap style="background-color: #dedede">
                        <p
                          style="
                            font-size: 20px;
                            padding-top: 10px;
                            padding-left: 10px;
                          "
                        >
                          Profile Info
                        </p>
                      </v-layout>
                      <v-layout wrap style="padding: 10px">
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            label="User Type"
                            v-model="user_type"
                            prepend-icon="person"
                            disabled
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            label="Email"
                            required
                            v-model="searchParam.email"
                            prepend-icon="mail"
                            outline
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            label="password"
                            v-model="searchParam.password"
                            prepend-icon="password"
                            required
                            outline
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            label="Confirm password"
                            v-model="searchParam.c_password"
                            prepend-icon="password"
                            required
                            outline
                            style="padding-left: 10px"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-layout wrap>
                      <v-btn
                        color="primary"
                        @click="submitScrut()"
                        style="
                          margin-left: 46%;
                          margin-bottom: 30px;
                          margin-top: -20px;
                        "
                        >Create</v-btn
                      >
                    </v-layout>
                  </v-card>
                </v-dialog>
              </v-layout>
            </template>
          </v-layout>
        </v-card>
        <template>
          <v-data-table
            :headers="headers"
            :items="getDataOfscrutinizerList"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>{{ props.index + 1 }}</td>
              <td class="text-xs-left">{{ props.item.tmis_id }}</td>
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">
                {{ props.item.designation.desig_name }}
              </td>
              <td class="text-xs-left">{{ props.item.email }}</td>
              <td class="text-xs-left">{{ props.item.h_ex_tmis_id }}</td>
              <td class="text-xs-left">
                <v-layout row justify-center>
                  <v-dialog v-model="ex_dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">View</v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Examiners </span>
                      </v-card-title>
                      <v-card-text>
                        <table>
                          <thead>
                            <tr>
                              <th id="RCitem">SL</th>
                              <th id="RCitem">TMIS</th>
                              <th id="RCitem">Name</th>
                              <th id="RCitem">Email</th>
                              <th id="RCitem">Mobile</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(rcitem, index) in props.item.examiners"
                              :key="index"
                            >
                              <td id="RCitem">
                                {{ index + 1 }}
                              </td>
                              <td>
                                {{ rcitem.tmis_id }}
                              </td>
                              <td>
                                {{ rcitem.teacher_info.fullname }}
                              </td>
                              <td>
                                {{ rcitem.teacher_info.email }}
                              </td>
                              <td>
                                {{ rcitem.teacher_info.mobile }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          flat
                          @click="ex_dialog = false"
                          >OK</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </td>
              <!-- <td class="text-xs-left">
                <v-layout row justify-center>
                  <v-dialog v-model="ex_dialog_edit" persistent max-width="800px">
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editScrut(props.item.id)" color="primary" dark v-on="on">Edit</v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Edit Examiner</span>
                        <v-spacer></v-spacer>
                        <v-btn
                          fab
                          class="ma-1 pa-0"
                          small
                          color="red darken-3 white--text"
                          @click="ex_dialog_edit = false;"
                          ><v-icon>close</v-icon></v-btn
                        >
                      </v-card-title>
                      <v-card-text v-for="(scrutitem, index) in getDataOfscrutinizerListEdit" :key="index">
                        <v-layout
                        wrap
                        style="margin-top: -10px; padding-left: 10px"
                      >
                        <v-flex xs12 sm4 md6 style="padding-left: 0px">
                          <v-text-field
                            label="TMIS ID"
                            v-model="scrutitem.tmis_id"
                            prepend-icon="touch_app"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap style="background-color: #dedede">
                        <p
                          style="
                            font-size: 20px;
                            padding-top: 10px;
                            padding-left: 10px;
                          "
                        >
                          User Info
                        </p>
                      </v-layout>
                      <v-layout
                        wrap
                        style="padding-left: 10px; padding-right: 10px"
                      >
                        <v-flex xs12 sm6 md8>
                          <v-text-field
                            label="Name"
                            v-model="scrutitem.name"
                            prepend-icon="account_circle"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md4 style="padding-left: 0px">
                          <v-select
                            v-model="scrutitem.designation_id"
                            :items="dataOfdesignationList"
                            item-text="designation"
                            item-value="designation_id"
                            class="mt-3 ml-2"
                            name="designation_id"
                            label="Designation"
                            prepend-icon="list"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md6 style="margin-left: -10px">
                          <v-select
                            v-model="scrutitem.subject_code"
                            :items="getDegSubject"
                            item-text="subject_name"
                            item-value="subject_code"
                            class="mt-3 ml-2"
                            name="subject_code"
                            label="Subject"
                            prepend-icon="edit"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md6 style="margin-left: 10px">
                          <v-autocomplete
                            class="ma-2"
                            v-model="scrutitem.college_code"
                            :item-text="
                              (item) =>
                                '(' +
                                item.college_code +
                                ') ' +
                                item.college_name
                            "
                            item-value="college_code"
                            :items="getCollegeSchema"
                            menu-props="auto"
                            label="Select College"
                            prepend-icon="subject"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                close
                                @input="scrutitem.college_code = ''"
                                :selected="data.selected"
                                class="chip--select-multi"
                                >{{
                                  "(" +
                                  data.item.college_code +
                                  ") " +
                                  data.item.college_name
                                }}</v-chip
                              >
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-tile-content
                                  v-text="data.item"
                                ></v-list-tile-content>
                              </template>
                              <template v-else>
                                <v-list-tile-content>
                                  <v-list-tile-title
                                    v-html="
                                      '(' +
                                      data.item.college_code +
                                      ') ' +
                                      data.item.college_name
                                    "
                                  ></v-list-tile-title>
                                </v-list-tile-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                          <v-select
                            v-model="scrutitem.examiners"
                            :items="MarkCountListByHeadExaminer"
                            :item-text="
                              (item) =>
                                '(' + item.tims_id + ') ' + item.examiner_name
                            "
                            item-value="tims_id"
                            name="tims_id"
                            label="Select Examiners"
                            prepend-icon="supervised_user_circle"
                            multiple
                          ></v-select>
                        </v-flex>
                      </v-layout>

                      <v-layout wrap style="background-color: #dedede">
                        <p
                          style="
                            font-size: 20px;
                            padding-top: 10px;
                            padding-left: 10px;
                          "
                        >
                          Account Info
                        </p>
                      </v-layout>
                      <v-layout wrap style="padding: 10px">
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="Bank Name"
                            v-model="scrutitem.bank_name"
                            prepend-icon="account_balance"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="Branch Name"
                            v-model="scrutitem.branch_name"
                            prepend-icon="account_balance"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4 md4 style="padding-left: 10px">
                          <v-text-field
                            label="Account No"
                            v-model="scrutitem.account_no"
                            prepend-icon="account_balance"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap style="background-color: #dedede">
                        <p
                          style="
                            font-size: 20px;
                            padding-top: 10px;
                            padding-left: 10px;
                          "
                        >
                          Profile Info
                        </p>
                      </v-layout>
                      <v-layout wrap style="padding: 10px">
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            label="User Type"
                            v-model="user_type"
                            prepend-icon="person"
                            disabled
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            label="Email"
                            required
                            v-model="scrutitem.email"
                            prepend-icon="mail"
                            outline
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            label="password"
                            v-model="scrutitem.password"
                            prepend-icon="password"
                            required
                            outline
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            label="Confirm password"
                            v-model="scrutitem.c_password"
                            prepend-icon="password"
                            required
                            outline
                            style="padding-left: 10px"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-layout wrap>
                      <v-btn
                        color="primary"
                        @click="updateScrut(scrutitem.tmis_id, 
                          scrutitem.name, 
                          scrutitem.designation_id,
                          scrutitem.subject_code,
                          scrutitem.college_code,
                          scrutitem.examiners,
                          scrutitem.bank_name,
                          scrutitem.branch_name,
                          scrutitem.account_no,
                          scrutitem.email,
                          scrutitem.password,
                          scrutitem.c_password)"
                        style="
                          margin-left: 46%;
                          margin-bottom: 30px;
                          margin-top: -20px;
                        "
                        >Update</v-btn
                      >
                    </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </td> -->
            </template>
          </v-data-table>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";

export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
      loading: false,
      dialog: false,
      ex_dialog: false,
      ex_dialog_edit: false,
      searchParam: {},
      scrutitem: {},
      user_type: "scrutinizer",
      headers: [
        {
          text: "SL",
          align: "left",
          sortable: false,
          value: "",
        },
        { text: "Tmis Id", value: "carbs" },
        { text: "Name", value: "calories" },
        { text: "Designation", value: "fat" },
        { text: "email", value: "protein" },
        { text: "Head Examiner Tmis ", value: "iron" },
        { text: "View Examiners", value: "hex" },
        //{ text: "Edit", value: "" },
      ],
      desserts: [
        {
          sl: 1,
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
          hex: "dffsd",
        },
      ],
      userData: null,
      timsId: null,
    };
  },

  created() {
    this.getDataFromApiForSelect();
    this.$store.dispatch("fetchAllSubjectData");
    this.$store.dispatch("fetchDesignationData");
    this.searchParam.exam_initiate_id = this.getExamInitiateId;
    this.searchParam.h_ex_tmis_id = this.getAuthUser.examiner.tims_id;
    this.getScrutListData();
    this.searchParam.user_id = this.getAuthUser.examiner.user_id;
    this.searchParam.exam_year = this.getLoggedInYear.year;
    this.getexListData();
    this.getDesignationList();
  },
  computed: {
    getDataOfscrutinizerList() {
      return this.$store.getters.dataOfscrutinizerList;
    },
    getDataOfscrutinizerListEdit() {
      return this.$store.getters.dataOfscrutinizerListEdit;
    },
    getCollegeSchema() {
      return this.$store.getters.getAllCollege;
    },
    getDegSubject() {
      return this.$store.getters.getAllSubject;
    },
    MarkCountListByHeadExaminer() {
      return this.$store.getters.getMarkCountListByExaminer;
    },
    dataOfdesignationList() {
      return this.$store.getters.dataOfdesignationList;
    },
  },
  mounted() {},
  methods: {
    getDataFromApiForSelect() {
      this.$store.dispatch("fetchAllCollegeData");
    },

    getexListData() {
      let peram = {};
      peram.exam_code = this.getLoggedInExam.code;
      peram.year = this.getLoggedInYear.year;
      peram.exam_initiate_id = this.searchParam.exam_initiate_id;
      peram.exam_type = this.searchParam.exam_type;
      peram.user_id = this.searchParam.user_id;

      this.$store.dispatch("fetchMarkCountByExaminer", peram);
    },

    getScrutListData() {
      this.$store.dispatch("fetchScrutinizerData", this.searchParam);
    },

    submitScrut() {
      console.log("Tanvir!! This is scruk data", this.searchParam);
      this.$store
        .dispatch("postScrutinizerData", this.searchParam)
        .then((resp) => {
          this.loading = false;
          this.dialog = true;
          this.searchParam.tmis_id = "";
          this.searchParam.name = "";
          this.searchParam.designation_id = "";
          this.searchParam.subject_code = "";
          this.searchParam.college_code = "";
          this.searchParam.examiner_list = "";
          this.searchParam.bank_name = "";
          this.searchParam.branch_name = "";
          this.searchParam.account_no = "";
          this.searchParam.email = "";
          this.searchParam.password = "";
          this.searchParam.c_password = "";
          this.getScrutListData();
        });
    },

    eraseData() {
      this.searchParam.tmis = "";
      this.searchParam.name = "";
      this.searchParam.designation = "";
      this.searchParam.subject_code = "";
      this.searchParam.college_code = "";
      this.searchParam.examiner_list = "";
      this.searchParam.bank_name = "";
      this.searchParam.account_no = "";
      this.searchParam.email = "";
      this.searchParam.password = "";
      this.searchParam.c_password = "";
      this.dialog = false;
    },

    editScrut(id) {
      let peram = {};
      peram.id = id;
      peram.exam_initiate_id = this.searchParam.exam_initiate_id;
      this.$store.dispatch("fetchEditScrutData", peram);
    },

    //updateScrut(name,designation_id,subject_code,college_code,examiners,bank_name,branch_name,account_no,email,password,c_password) {
                //let peram = {};
                //peram.name = name;
                //peram.designation_id = designation_id;
                //peram.subject_code = subject_code;
                //peram.college_code = college_code;
                //peram.examiners = examiners;
                //peram.bank_name = bank_name;
                //peram.branch_name = branch_name;
                //peram.account_no = account_no;
                //peram.email = email;
                //peram.password = password;
                //peram.c_password = c_password;
      //console.log("Tanvir Update Srut called!!!!", peram);
      //this.ex_dialog_edit = true;
      //this.$store.dispatch("updateScrutData", this.scrutitem);
    //},
  },
};
</script>
<style lang=""></style>
