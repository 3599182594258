import Axios from "axios";

import {
  SET_DASHBOARD_DATA,
  GET_EXAM_ROUTIN_AT_ADMIN_DASHBOARD
} from "../../mutation-types";

import {
  adminDashboardUrl,
  AdminExamRoutinData
} from "../../api";

const state = {
  adminDashboardData: {},
  totalStatus: {
    total_center: "",
    total_invigilator: "",
    total_user: "",
    total_regional_center: "",
    exams_status: [],
  },
};
const getters = {
  getDashboardData(state) {
    return state.totalStatus;
  },

  getAdminDashboardRoutin(state) {
    return state.adminDashboardData;
  }
};
// mutations
const mutations = {
  [SET_DASHBOARD_DATA](state, response) {
    state.totalStatus = response;
  },

  [GET_EXAM_ROUTIN_AT_ADMIN_DASHBOARD](state, response) {
    state.adminDashboardData = response;
  },
};
// Actions
const actions = {
  getRoutineAtAdminDashboard({
    commit
  }, date) {

    return Axios.get(AdminExamRoutinData, {params: date})
      .then(function (response) {
        var routine = response.data;
        commit("GET_EXAM_ROUTIN_AT_ADMIN_DASHBOARD", routine);
        return routine;
      })
  },
  getDashData({
    commit
  }) {
    return new Promise((resolve, reject) => {
      Axios.get(adminDashboardUrl)
        .then(function (response) {
          console.log("res", response);
          var result = response.data.data;
          commit("SET_DASHBOARD_DATA", result);
          resolve();
        })
        .catch(function (error) {
          console.log(error);
          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};