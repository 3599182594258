<template lang="">
  <v-container fluid>
    <v-card color="white" style="margin-top: 40px">
      <v-layout row>
        <v-card flat>
          <v-badge left overlap>
            <template v-slot:badge>
              <v-btn
                round
                color="primary"
                fab
                small
                style="margin-top: -1.3em"
                class="elevation-0"
                v-if="getDataOfinsertSlotList.length > 0"
                >{{ getDataOfinsertSlotList.length }}</v-btn
              >
            </template>
            <v-card-text class="title">Insert Slot List</v-card-text>
          </v-badge>
        </v-card>

        <v-divider class="my-2" vertical></v-divider>
        <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search by table name"
          class="mt-0"
          hide-details
          solo
          flat
          single-line
        ></v-text-field>
        <v-spacer />

        <v-layout row justify-end>
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn color="#3F51B5" dark v-on="on">
                <v-icon size="30" color="#fff">add</v-icon>
                <p style="margin-top: 20px; color: #fff">Insert</p>
              </v-btn>
            </template>
            <v-card>
              <v-card-title style="background-color:#3F51B5; color:#fff;">
                <span class="headline">Insert FF Data</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-form>
                    <v-layout wrap>
                      <v-flex xs12 sm6 md12>
                        <v-text-field
                          label="Table Name"
                          v-model="searchParam.table_name"
                          v-validate="'required'"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md12>
                        <v-text-field
                          label="Installment"
                          v-model="searchParam.inst"
                          type="number"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm12>
                        <v-select
                          :items="['formfillup', 'probable']"
                          v-model="searchParam.data_type"
                          label="Data Type"
                          required
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click="dialog = false"
                  >Close</v-btn
                >
                <v-btn color="blue darken-1" flat @click="saveffinsert()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <v-btn small fab @click="getDataOfinsertSlotSet()">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-layout>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="getDataOfinsertSlotList"
      class="elevation-1"
      :total-items="pagination.total"
      :pagination.sync="pagination"
      :loading="loading"
      :rows-per-page-items="[50, 100, 500]"
    >
      <template v-slot:items="props">
        <!-- <td class="text-xs-center">{{ props.index + 1 }}</td> -->
        <td class="text-xs-center">
          {{ props.item.installment }}
        </td>
        <td class="text-xs-center">
          {{ props.item.table_name }}
        </td>
        <td class="text-xs-center">
          <span>
            {{ props.item.total_students }}
          </span>
        </td>
        <td class="text-xs-center">
          <span>
            {{ props.item.total_inserted }}
          </span>
        </td>
        <td class="text-xs-left">{{ props.item.added_by }}</td>
        <td class="text-xs-center">
          <span>
            {{ props.item.added_on }}
          </span>
        </td>
        <td class="text-xs-center">
          <span>
            {{ props.item.remarks }}
          </span>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";
export default {
  mixins: [commonGet, commonExamYear, common_login_getters],

  data() {
    return {
      dialog: false,
      searchParam: {},
      absentListDialog: false,
      loading: false,
      pagination: {
        ascending: true,
        rowsPerPage: 50,
      },
      headers: [
        { text: "Installment", value: "installment", align: "left" },
        { text: "Table Name", value: "table_name", align: "left" },
        { text: "Total Students", value: "total_students", align: "left" },
        { text: "Total Inserted", value: "total_inserted", align: "left" },
        { text: "Added By", value: "added_by", align: "left" },
        { text: "Added On", value: "added_on", align: "left" },
        { text: "Remarks", value: "remarks", align: "left" },
      ],
      validationErrors: {},
      formDialogStatus: false,
      search: "",
    };
  },

  created() {
    this.getDataOfinsertSlotSet();
  },

  computed: {
    getDataOfinsertSlotList() {
      return this.$store.getters.dataOfinsertSlotList;
    },
  },

  watch: {
    pagination() {
      this.getDataOfinsertSlotSet();
    },
    search: function(newQuery) {
      this.getDataOfinsertSlotSet(newQuery); // Call the debounced function
    },
  },

  methods: {
    setLimit() {
      const { sortBy, page, rowsPerPage } = this.pagination;
      this.searchParam.page = page;
      this.searchParam.limit = rowsPerPage;
      this.searchParam.sort = sortBy;
      this.searchParam.search = this.search;
    },

    getDataOfinsertSlotSet() {
      this.setLimit();
      this.loading = true;
      this.$store
        .dispatch("fetchinsertSlotData", this.searchParam)
        .then((data) => {
          // console.log("data.meta.total", data);
          this.loading = false;
          this.pagination.totalItems = data.meta.total;
        });
    },

    saveffinsert() {
      if (
        !this.searchParam.table_name ||
        !this.searchParam.inst ||
        !this.searchParam.data_type
      ) {
        this.$store.commit("SET_SNACKBAR", {
          msg: "All fields are required!",
          color: "red",
        });
        return;
      }

      const payload = {
        table_name: this.searchParam.table_name,
        inst: this.searchParam.inst,
        data_type: this.searchParam.data_type,
      };

      this.$store
        .dispatch("storeffSlotData", payload)
        .then(() => {
          this.$store.commit("SET_SNACKBAR", {
            msg: "Data inserted successfully!",
            color: "green",
          });
          this.dialog = false; // Close the dialog
          this.searchParam = {}; // Reset the form fields
        })
        .catch((errors) => {
          if (errors.general) {
            this.$store.commit("SET_SNACKBAR", {
              msg: errors.general,
              color: "red",
            });
          } else {
            // Handle validation errors
            this.$store.commit("SET_SNACKBAR", {
              msg: "Please fix the errors in the form.",
              color: "red",
            });
          }
        });
    },

    dialogOpen() {
      this.formDialogStatus = true;
    },
  },
};
</script>
<style></style>
