<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Marks Entry</v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Viva Marks</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="red" icon dark @click="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
  
        <div style="padding-top:30px; padding-left:50px; padding-right:50px;">
          <span class>
            <div class="display-1 mb-4">
              <v-icon size="50" color="primary">dashboard</v-icon>
              <span class="ml-2">Viva Marks Entry..</span>
            </div>
          </span>
          <v-card class="px-5" color="#FAFAFA" style="margin-bottom:50px;">
            <v-layout row wrap align-center class="py-3">
              <v-autocomplete
                class="ma-2"
                v-model="searchParam.paper_code"
                :items="getVivaPaperItem"
                :item-text="(item) => item.paper_code + ' - ' + item.paper_name"
                item-value="paper_code"
                label="Select Course"
                persistent-hint
                prepend-icon="library_books"
                :loading="paperLoading"
              >
                <template v-slot:selection="data">
                  <v-chip
                    color="#EBEBEB"
                    close
                    @input="searchParam.paper_code = ''"
                    :selected="data.selected"
                    class="chip--select-multi"
                    >{{
                      data.item.paper_code + " - " + data.item.paper_name
                    }}</v-chip
                  >
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-tile-content v-text="data.item"></v-list-tile-content>
                  </template>
                  <template v-else>
                    <v-list-tile-content>
                      <v-list-tile-title
                        v-html="
                          data.item.paper_code + ' - ' + data.item.paper_name
                        "
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-btn color="primary" small @click="bothSearch">
                <v-icon center dark>list</v-icon> &nbspLoad Student
              </v-btn>
              <v-btn color="primary" small @click="bothSearch">
                <v-icon center dark>download</v-icon> &nbspStudent List
              </v-btn>
              <v-btn color="primary" small @click="bothSearch">
                <v-icon center dark>file_copy</v-icon> &nbspBlank Mark Input List
              </v-btn>
            </v-layout>
          </v-card>
  
          <v-card color="white">
            <v-layout row>
              <v-card flat>
                <v-badge left overlap>
                  <template v-slot:badge>
                    <v-btn
                      round
                      color="primary"
                      fab
                      small
                      style="margin-top: -1em; font-size: 1.3em"
                      class="elevation-0"
                      >{{
                        pagination.totalDataItems ? pagination.totalDataItems : 0
                      }}</v-btn
                    >
                  </template>
                  <v-card-text class="title">Viva Student List</v-card-text>
                </v-badge>
              </v-card>
              <v-divider class="my-2" vertical></v-divider>
            </v-layout>
          </v-card>
  
          <v-data-table
            :headers="headers"
            :items="getVivaWantListByStd"
            :loading="loading"
            class="elevation-4 mytable"
            :pagination.sync="pagination"
            :rows-per-page-items="pagination.rowsPerPageItems"
            :total-items="pagination.totalDataItems"
          >
            <template v-slot:items="props">
              <td class="text-xs">{{ sl_count + props.index }}</td>
              <td class="text-xs-center">{{ props.item.exam_roll }}</td>
              <td class="text-xs-center">{{ props.item.reg_no }}</td>
              <td class="text-xs-center">{{ props.item.session_year }}</td>
              <td class="text-xs-center" v-if="ifNew">
                {{ props.item.examinee_name }}
              </td>
              <td class="text-xs-center" v-else>
                {{ props.item.examinee_name }}
              </td>
              <td class="text-xs-left my--style" width="250">
                <v-text-field
                  v-model="props.item.mark"
                  label="Input Mark"
                  single-line
                  autofocus
                  hide-details
                  class="mb-3"
                  :ref="'text' + props.index"
                  @click="up(props.item)"
                  @keyup.enter="callRef(props.index + 1)"
                  @keyup.up="callRef(props.index - 1)"
                  @keyup.down="callRef(props.index + 1)"
                  :error="getErrorStatus(props.item)"
                  :prepend-icon="getIconForMarkEntry(props.item)"
                ></v-text-field>
              </td>
              <td class="text-xs-center">{{ props.item.full_marks }}</td>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        searchParam: {},
        dialog: false,
        pagination: {
          page: 1,
          rowsPerPage: 50,
          rowsPerPageItems: [50],
        },
        getVivaWantListByStd: [],
        headers: [
          {
            text: "Sl",
            align: "left",
            value: "examinee_name",
            class: "my-style-2",
            sortable: false,
          },
          {
            text: "Exam Roll",
            align: "center",
            value: "examinee_name",
            class: "my-style-2",
            sortable: false,
          },
          {
            text: "Registration No.",
            align: "center",
            value: "reg_no",
            class: "my-style-2",
            sortable: false,
          },
          {
            text: "Session",
            align: "center",
            value: "reg_no",
            class: "my-style-2",
            sortable: false,
          },
          {
            text: "Examinee Name",
            align: "center",
            value: "examinee_name",
            class: "my-style-2",
            sortable: false,
          },
          {
            text: "Viva/Field work Mark",
            align: "center",
            value: "id",
            sortable: false,
          },
          {
            text: "Full Marks",
            align: "center",
            value: "id",
            sortable: false,
          },
        ],
      };
    },
  };
  </script>
  <style lang=""></style>
  