import Axios from "axios";

import { ffSlotList, saveffSlotList } from "@/store/api";
import { INSERT_FF_SLOT_LIST_DATA } from "../../mutation-types";
const state = {
    insertSlotData: [],
};

const getters = {
    dataOfinsertSlotList() {
        return state.insertSlotData;
    },
};
const mutations = {

    [INSERT_FF_SLOT_LIST_DATA](state, result) {
        state.insertSlotData = result;
    },

};
const actions = {
    fetchinsertSlotData({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary",
            });
            Axios.get(ffSlotList, {
                params: searchParam,
            })
                .then(function (response) {
                    commit("UNSET_LOADING");
                    var result = response.data.data;
                    console.log("Tanvir, this is slot list->", result);
                    commit("INSERT_FF_SLOT_LIST_DATA", result);
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    commit("UNSET_LOADING");
                    console.log(error);
                    reject();
                });
        });
    },


    storeffSlotData({ commit }, schema) {
        let data = schema;
        return new Promise((resolve, reject) => {
            Axios.post(saveffSlotList, data)
                .then(function (response) {
                    console.log("the response comes", response);
                    commit("SET_LOADING", { color: "primary" });
                    if (response.data.code === 200) {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "green"
                        });
                        commit("UNSET_LOADING"); // loading Closing
                        resolve();
                    } else {
                        commit("UNSET_LOADING"); // loading Closing
                        reject(response.data.errors || {});
                    }
                })
                .catch(function (error) {
                    console.log("the error comes", error);
                    commit("UNSET_LOADING"); // Ensure loading is closed
                    if (error.response && error.response.status === 422) {
                        // Pass validation errors to the component
                        reject(error.response.data.errors);
                    } else {
                        // Handle other errors (optional)
                        reject({ general: "An unexpected error occurred." });
                    }
                });
        });
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
