<template>
  <v-container fluid>
    <v-card color="white">
      <v-layout row>
        <v-layout row wrap align-center>
          <v-flex class="mx-5">
            <v-layout
              row
              wrap
              align-center
              style="margin-top: 20px; margin-bottom: 20px"
            >
              <span
                v-if="ifCenterUser == true"
                class="ma-2 title"
                style="
                  width: 49%;
                  border: 1px solid #bdbdbd;
                  padding: 16px;
                  color: #7b7b7b;
                "
              >
                {{ centerName.centerCode }} - {{ centerName.centerName }}
              </span>

              <v-flex style="padding-top: 24px" v-if="ifCenterUser == false">
                <v-autocomplete
                  :disabled="ifCenterUser"
                  class="ma-2"
                  v-model="center_code"
                  :item-text="
                    (item) => item.center_code + ' - ' + item.center_name
                  "
                  item-value="center_code"
                  :items="getTheoryCenterList"
                  menu-props="auto"
                  label="Select Center"
                  prepend-icon="list"
                  outline
                >
                  <template v-slot:selection="data">
                    <v-chip
                      close
                      @input="clearCenter()"
                      :selected="data.selected"
                      class="chip--select-multi"
                    >
                      {{
                        data.item.center_code + " - " + data.item.center_name
                      }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-tile-content>
                        <v-list-tile-title
                          v-html="
                            data.item.center_code +
                            ' - ' +
                            data.item.center_name
                          "
                        ></v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-flex>

              <v-flex xs6 sm4 style="padding-top: 24px">
                <v-text-field
                  label="Room Number"
                  v-model="roomNo"
                  single-line
                  outline
                ></v-text-field>
              </v-flex>

              <v-menu full-width max-width="290" :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    color="cyan"
                    class="ma-2 title"
                    v-model="date"
                    label="Select Attendance Date"
                    readonly
                    v-on="on"
                    outline
                    hide-details
                    prepend-icon="calendar_today"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date"></v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>

      <div class="text-xs-center" style="padding-bottom:40px;">
        <v-btn style="padding:24px;" round color="primary" @click="getSammaryofTodayExam(0)" dark
          ><v-icon left dark>search</v-icon>Search</v-btn
        >
      </div>
    </v-card>

    <!-- List area -->
    <v-card color="white" style="margin-top: 40px">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Room Wise Present List</v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-btn color="#3F51B5" @click="getSammaryofTodayExamPDF(1)">
          <v-icon size="30" color="#fff">download</v-icon>
          <p style="margin-top: 16px; color: #fff">Download Sammary</p>
        </v-btn>
        <v-btn color="#3F51B5" @click="getSammaryofTodayExam(0)">
          <v-icon size="30" color="#fff">replay</v-icon>
          <p style="margin-top: 16px; color: #fff">Reload</p>
        </v-btn>
      </v-layout>
    </v-card>
    <!-- {{getPersentListRoomWise}} -->

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="getPersentListRoomWise"
      class="elevation-1"
      hide-actions
    >
      <template v-slot:items="props">
        <td class="text-xs-center">{{ props.index + 1 }}</td>
        <td class="text-xs-center">
          {{ props.item.room_no }}
        </td>
        <td class="text-xs-center">
          {{ props.item.exam_roll }}
        </td>
        <td class="text-xs-left" style="padding-left:10px;">{{ props.item.student_name }}</td>
        <td class="text-xs-center">
          <span>
            {{ props.item.paper_code }}
          </span>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import common_getters_mixin from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import formNotification from "@/components/utils/formNotification";
import admitCardCountByCenter from "@/components/admitCard/admitCardCountByCenter.vue";
import todaySummary from "@/components/todaySummary/todaySummary.vue";
import routinePdf from "@/components/admin/routinePdf.vue";
import draggable from "vuedraggable";
import common_exam_lg from "../../mixin/common_login_getters.vue";
export default {
  mixins: [common_getters_mixin, commonExamYear, common_exam_lg],
  components: {
    draggable,
    admitCardCountByCenter,
    todaySummary,
    formNotification,
    routinePdf,
  },
  data() {
    return {
      loading: false,
      roomNo: "",
      date: "",
      centerName: {},
      headers: [
        {
          text: "SL",
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: "Room No",
          align: "center",
          value: "room_no",
        },
        { text: "Exam Roll", align: "center", value: "exam_roll" },
        { text: "Student Name", align: "center", value: "student_name" },
        { text: "Paper Code", align: "center", value: "paper_code" },
      ],
    };
  },

  created() {
    this.theworyyCenterData();
    this.setCenterNameAndCode();
  },

  computed: {
    getTheoryCenterList() {
      return this.$store.getters.getTheoryCenterList;
    },

    getPersentListRoomWise() {
      return this.$store.getters.GetRoomWiseAttandanceListPresent
        ? this.$store.getters.GetRoomWiseAttandanceListPresent
        : [];
    },
  },

  watch: {},

  methods: {
    theworyyCenterData() {
      let param = {};
      param.exam_initiate_id = this.getExamInitiateId;
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      this.$store.dispatch("fetchTheoryWiseCenterData", param);
    },
    setCenterNameAndCode() {
      if (this.ifCenterUser === true) {
        this.centerName.centerName = this.getAuthUser.center.center_name;
        this.centerName.centerCode = this.getAuthUser.center.center_code;
      } else {
        console.log("Tanvir,This is an  admin user!");
      }
    },

    getSammaryofTodayExam(download) {
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      if (this.ifCenterUser == false) {
        peram.center_code = this.center_code;
      } else {
        peram.center_code = this.getAuthUser.center.center_code;
      }
      peram.attendance_date = this.date;
      peram.room_no = this.roomNo;
      peram.download = download;
      console.log("Tanvir, Its colled peram data!!!!", peram);
      if (peram.room_no && peram.center_code && peram.attendance_date) {
        this.loading = true;
        this.$store
          .dispatch("fatchRoomWiseAttandanceListPresent", peram)
          .then(() => {
            this.loading = false;
          });
      }
    },

    getSammaryofTodayExamPDF(download) {
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      if (this.ifCenterUser == false) {
        peram.center_code = this.center_code;
      } else {
        peram.center_code = this.getAuthUser.center.center_code;
      }
      peram.attendance_date = this.date;
      peram.room_no = this.roomNo;
      peram.download = download;
      console.log("Tanvir, Its colled peram data PDF!!!!", peram);
      if (peram.room_no && peram.center_code && peram.attendance_date) {
        this.$store
          .dispatch("fatchRoomWiseAttandanceListPresent", peram)
          .then((resp) => {
            window.open(resp);
          });
      }
    },
  },
};
</script>

<style>
</style>