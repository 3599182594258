<template>
  <div id="PaymentSlip">
    <v-container fluid v-if="getCollegePaymentSlipDetails.hasOwnProperty('all_subject_fee')">
      <v-card color="white">
        <v-layout row>
          <v-card flat>
            <v-card-text class="title">Fee Summary Details</v-card-text>
          </v-card>
          <v-spacer />
        </v-layout>
      </v-card>
      <table class="v-datatable v-table theme--light">
          <thead>
            <tr>
              <th
                role="columnheader"
                scope="col"
                width="10"
                aria-label="SL.: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                SL.
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="SL.: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
              Subject Code &Name
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Number of Candidate(s): Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
              No. ofStudent 
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Total: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
              Total
              </th>
            </tr>
          </thead>
          <tbody>
            <slot v-if="getCollegePaymentSlipDetails.hasOwnProperty('all_subject_fee')">
              <tr v-for="(item, index) in getCollegePaymentSlipDetails.all_subject_fee" :key="index">         
                <td>{{ index + 1 }}</td>               
                <td class="text-xs-left">{{ item.subject_code }} - {{ item.subject_name }}</td>
                <td class="text-xs-left">{{ item.total_student }}</td>
                <td class="text-xs-left">{{ item.fee }}</td>
              </tr>
            </slot>
          </tbody>
        </table>
    </v-container>
  </div>
</template>
<script>
import commonGet from "@/mixin/college_common_getters_mixin";
export default {
  mixins: [commonGet],
  props: ['referenceId'],
  components: {},
  computed: {
    getSchema() {
      return this.$store.getters.collegePaymentSlipSchema;
    },
    getCollegePaymentSlipDetails(){
      return this.$store.getters.collegePaymentSlipDetails;
    }
  },
  data() {
    return {
    };
  },
  created() {
    this.getCollegePaymentSlipSchema();
  },
  methods: {
    getCollegePaymentSlipSchema(TrxId) {
      this.$store.dispatch("collegePaymentSlipDetails", {
        exam_initiate_id: this.$route.params.exam_initiate_id,
        trxid: this.$route.params.referenceId,
      })
    }
  },
  mounted() {
    if (!this.getSchema.exam_initiate_id && this.getExamMasterList.length > 0) {
      this.getSchema.exam_initiate_id = this.getExamMasterList["0"].id;
    }
  }
};
</script>
