<template>
  <v-container fluid>
    <v-expansion-panel class="mb-3" inset v-model="custP">
      <v-expansion-panel-content class="CSP_BoarderStyle">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
          </div>
        </template>
        <v-layout column wrap>
          <v-flex class="mx-3">
            <v-layout>
              <!-- {{getLoggedInExam}} -->
              <v-flex xs12>
                <v-row>
                  <template>
                    <v-form v-model="valid">
                      <v-container>
                        <v-layout>
                          <v-flex xs12 md6 style="display: flex;">
                             <v-icon style="margin-right: 10px">list</v-icon>
                           <v-select
                              :items="centers"
                              label="Center Name*"
                              item-text="center_name"
                              :item-value="(item) => item.center_code"
                              v-model="searchParam.center_code"
                            >
                            <template v-slot:selection="data">
                              <v-chip
                                close
                                :selected="data.selected"
                                class="chip--select-multi"
                                >{{ data.item.center_code + " - " + data.item.center_name }}</v-chip
                              >
                            </template>
                            </v-select>
                          </v-flex>
                          <v-flex xs12 md6 style="display: flex;">
                             <v-icon style="margin-right: 10px">list</v-icon>
                            <v-select
                                :items="issueTypeList"
                                label="Issue Type"
                                item-text="value"
                                item-value="id"
                                v-model="searchParam.issue_type"
                              ></v-select>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                           <v-flex xs12 md4 style="display: flex">
                            <v-icon style="margin-right: 10px">info</v-icon>
                            <v-text-field
                              v-model="searchParam.challan_no"
                              label="Challan No"
                              required
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md4 style="display: flex">
                            <v-icon style="margin-right: 10px">info</v-icon>
                            <v-text-field
                              v-model="searchParam.issue_no"
                              label="Issue No"
                              required
                            ></v-text-field>
                          </v-flex>
                           <v-flex xs12 md4 style="display: flex">
                            <v-icon style="margin-right: 10px">info</v-icon>
                            <v-text-field
                              v-model="searchParam.get_pass_no"
                              label="Get Pass No"
                              required
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </template>
                </v-row>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-flex xs12 sm12 md12 class="my-3">
              <v-layout justify-center row fill-height align-center>
                 <v-btn class="elevation-0" outline round large color="red darken-3 white--text" @click="reset()">
                  <v-icon left dark>close</v-icon>RESET
                </v-btn>
                <v-btn outline round large @click="searchData()">
                  <v-icon left dark>search</v-icon>Search
                </v-btn>
              </v-layout>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-container fluid>
    <v-card color="white">
      <v-layout row>
        <v-card flat>
          <v-badge left overlap color="transparent">
            <template v-slot:badge>
              <v-btn
                fab
                color="primary"
                small
                style="margin-top: -0.8em"
                class="elevation-0"
                >{{ getExamStoreItemIssueList.length }}</v-btn
              >
            </template>
            <v-card-text class="title">Issued to Center List</v-card-text>
          </v-badge>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-card-actions>
          <template>
            <v-row justify="center">
              <itemIssueFrom />
            </v-row>
          </template>
        </v-card-actions>
      </v-layout>
    </v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="getExamStoreItemIssueList"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:items="props">
        <tr>
        </tr>
        <tr>
          <td>{{ props.index + 1 }}</td>
          <td>{{ props.item.center_code }}</td>
          <td>{{ props.item.center.center_name }}</td>
          <td>{{ props.item.college_code }}</td>
          <td>{{ props.item.received_by }}</td>
          <td>{{ props.item.remarks }}</td>
          <td v-if="props.item.issue_type == 1">External</td>
          <td v-if="props.item.issue_type == 0">Internal</td>
          <td>{{ props.item.issue_no}}</td>
          <td>{{ props.item.issue_date}}</td>
          <td>{{ props.item.challan_no}}</td>
          <td>{{ props.item.get_pass_no}}</td>
          <td align="center">
            <template>
                  <!-- <v-row justify="center"> -->
                  <v-dialog v-model="dialog" persistent max-width="580px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-1 pa-0"
                        color="indigo white--text"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="getreceiveditem(props.item.item_details)"
                      >
                        <v-icon dark>visibility</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar>
                        <v-badge left overlap>
                        <v-card-text class="title">Issued  Item</v-card-text>
                        </v-badge>
                        <v-spacer />
                        <v-card-actions>
                          <v-btn
                            fab
                            class="ma-1 pa-0"
                            small
                            color="indigo white--text"
                            @click="dialog = false"
                          >
                            X
                          </v-btn>
                        </v-card-actions>
                      </v-toolbar>
                      <v-card-text>
                        <!-- <v-row> -->
                        <template align="center">
                           <v-card
                            color="grey lighten-5"
                            class="scroll dark--text"
                            height="500px"
                          >
                            <table id="rcpitemList">
                              <thead>
                                <tr>
                                  <th id="RCpHitem">SL</th>
                                  <th id="RCpHitem">Item Code</th>
                                  <th id="RCpHitem">Item Name</th>
                                  <th id="RCpHitem">Issued Quantity</th>
                                  <th id="RCpHitem">Unit In</th>
                                  <th id="RCpHitem">Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(rcitem, index) in updateItemListtada"
                                  :key="index"
                                >
                                  <td id="RCitem">
                                    {{ index + 1 }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem &&
                                      rcitem.item &&
                                      rcitem.item.item_code
                                        ? rcitem.item.item_code
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem &&
                                      rcitem.item &&
                                      rcitem.item.item_name
                                        ? rcitem.item.item_name
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem && rcitem.issued_quantity
                                        ? rcitem.issued_quantity
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem && rcitem.unit.unit_name
                                        ? rcitem.unit.unit_name
                                        : "Not found"
                                    }}
                                  </td>
                                  <td id="RCitem">
                                    {{
                                      rcitem && rcitem.remarks
                                        ? rcitem.remarks
                                        : "Not found"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                           </v-card>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </template>
          </td>
        </tr>
      </template>
    </v-data-table>
    </v-container>
    <template> </template>
  </v-container>
</template>

<script>
import itemIssueFrom from "./itemIssueFrom.vue";

import commonGet from "../../mixin/common_getters_mixin";
import common_login_getters from "@/mixin/common_login_getters";
import createCenterAllocation from "@/components/center/createCenterAllocationForm.vue";

export default {
  mixins: [
    commonGet,
    common_login_getters,
  ],
  components: {
    createCenterAllocation,
    itemIssueFrom,
  },
  data() {
    return {
      custP:'',
      searchParam: {},
      loading: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      updateItemListtada: [],
      headers:[
        {
          text: "SL",
          value: "",
        },
        {
          text: "Center Code",
          value: "",
        },
        {
          text: "Center Name",
          value: "",
        },
        {
          text: "College Code",
          value: "",
        },
        {
          text: "Received By",
          value: "",
        },
        {
          text: "Remarks",
          value: "",
        },
        {
          text: "Issue Type",
          value: "",
        },
        {
          text: "Issue No",
          value: "",
        },
        {
          text: "Issue Date",
          value: "",
        },
        {
          text: "Challan No",
          value: "",
        },
        {
          text: "Get Pass No",
          value: "",
        },
        {
          text: "View Issued Items",
          value: "",
        },
      ],
      centers:[],
      issueTypeList: [
        {
          id: 1,
          value: "External ",
        },
        {
          id: 0,
          value: "Internal",
        },
      ],
    };
  },

  created(){
    this.searchParam.exam_initiate_id = this.getExamInitiateId;
    this.searchParam.exam_code = this.getLoggedInExam.code;
    this.searchParam.year = this.getLoggedInYear.year;
    //all  list
    console.log("pramrams->", this.$route.params);
    if (this.getRouterParam === "practical") {
      this.searchParam.type = 2;
    } else if (this.getRouterParam === "viva") {
      this.searchParam.type = 3;
    } else {
      this.searchParam.type = 1;
    }
    this.fetchexamissueditemlistdata();
    this.searchCenterAllocationList();
  },

  computed:{
    getExamStoreItemIssueList(){
      return this.$store.getters.getExamStoreItemIssueList ?
      this.$store.getters.getExamStoreItemIssueList:[];
    },
     getCenter() {
      return this.$store.getters.getCenterAllocationItem
        ? this.$store.getters.getCenterAllocationItem
        : [];
    },
    //  getitemData() {
    //   return this.updateItemListtada;
    // },

    
  },

  watch:{

  },

  methods:{
    //get issued list and issued item  list
    fetchexamissueditemlistdata(){
      this.loading = true;
      this.$store.dispatch("fetchExamStoreItemIssueList").then(()=>{
        this.loading = false;
      })
    },

    async getreceiveditem(tada) {
      this.updateItemListtada = await tada;
      console.log(tada);
      return updateItemListtada
      
    },
    searchCenterAllocationList() {
      console.log("this.searchParam", this.searchParam)
      this.$store
        .dispatch("searchCenterAllocationData",this.searchParam)
        .then(()=>{
          for(let i = 0; i < this.getCenter.length; i++){
            console.log("Tanvir! this is center resp", this.getCenter[i].centers);
            this.centers = this.getCenter[i].centers;
          }
          return  this.centers;
        });
    },
    searchData(){
      console.log("Tanvir! This is search data", this.searchParam);
      let param = {};
      param.center_code=this.searchParam.center_code;
      param.issue_type=this.searchParam.issue_type;
      param.challan_no=this.searchParam.challan_no;
      param.issue_no=this.searchParam.issue_no;
      param.get_pass_no=this.searchParam.get_pass_no;
      this.loading = true;
      this.$store.dispatch("fetchExamStoreItemIssueList",param).then(()=>{
        this.loading = false;
      })
    },

    reset(){
      this.searchParam.center_code = '';
      this.searchParam.issue_type = '';
      this.searchParam.challan_no = '';
      this.searchParam.issue_no = '';
      this.searchParam.get_pass_no = '';
    },
    // clearobj(){
    //   this.searchParam.center_code  = '';
    // },

  },
};

</script>

<style>
#RCpHitem {
  text-align: center;
  /* margin-left: 2%; */
}
#RCpHitem{
  font-size: 18px;
  padding: 5px;
  /* padding-left:10px; */
  /* padding-bottom:5px; */
}
#RCitem {
  text-align: center;
  padding: 5px;
  font-size: 16px;
}
</style>