<template>
  <v-container fluid>
    <v-layout column>
      <v-flex xs12>
        <v-card class="px-5" color="#FAFAFA" style="margin-bottom:50px;">
          <v-layout row wrap align-center class="py-3">
            <v-autocomplete
              class="ma-2"
              v-model="searchParam.paper_code"
              :items="getPracticalPaperItem"
              :item-text="(item) => item.paper_code + ' - ' + item.paper_name"
              item-value="paper_code"
              label="Select Course"
              persistent-hint
              prepend-icon="library_books"
              :loading="paperLoading"
            >
              <template v-slot:selection="data">
                <v-chip
                  color="#EBEBEB"
                  close
                  @input="searchParam.paper_code = ''"
                  :selected="data.selected"
                  class="chip--select-multi"
                  >{{
                    data.item.paper_code + " - " + data.item.paper_name
                  }}</v-chip
                >
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item"></v-list-tile-content>
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <v-list-tile-title
                      v-html="
                        data.item.paper_code + ' - ' + data.item.paper_name
                      "
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn color="primary" fab round small @click="bothSearch">
              <v-icon center dark>search</v-icon>
            </v-btn>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-card color="white">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Practical Mark List ..</v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-btn color="primary" @click="downloadPracticalMarklist()"
          >Download Practical Mark List</v-btn
        >
        <marksEnrty></marksEnrty>
        <!-- <v-btn color="primary" @click="goPVMarksEntry()">Marks Entry</v-btn> -->
      </v-layout>
    </v-card>
    <!-- v-if="this.searchParam.subject_code || searchParam.code" -->
    <v-data-table
      :headers="headers"
      :items="getDataList"
      class="elevation-2"
      :loading="loading"
      :total-items="pagination.totalItems"
      :pagination.sync="pagination"
      :rows-per-page-items="pagination.rowsPerPageItems"
    >
      <template v-slot:items="props">
        <td class="text-xs-center">
          {{ dateFormatted(props.item.created_at) }}
        </td>
        <!-- <td class="text-xs-center">{{ props.item.course }}</td> -->
        <td class="text-xs-center">
          {{ props.item.subject_code ? props.item.subject_code : "" }} -
          {{ props.item.subject_name }}
        </td>
        <td class="text-xs-center">
          {{ props.item.paper_code }} - {{ props.item.paper_name }}
        </td>

        <td class="text-xs-center">
          {{ props.item.exam_code }} {{ props.item.exam_name }}
        </td>
        <td class="text-xs-center">{{ props.item.exam_year }}</td>
        <td class="text-xs-center">{{ props.item.student_count }}</td>
        <!-- <td class="text-xs-center">
              <v-btn
                color="primary"
                outline
                round
                @click="showDetailItem(props.item)"
              >Click to See All The Student Details</v-btn>
            </td>-->
        <td class="text-xs-center">
          <v-btn
            color="primary"
            @click="editItem(props.item)"
            :disabled="!dateIsBetween"
            >Edit</v-btn
          >
          <v-btn color="warning" @click="reviewItem(props.item)">Review</v-btn>
        </td>
      </template>
    </v-data-table>

    <v-card color="white" style="margin-top:50px;">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Edited Data Log</v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
      </v-layout>
    </v-card>

    <!-- v-if="this.searchParam.subject_code || searchParam.code" -->
    <v-data-table
      :headers="editlogheaders"
      :items="getEditedDataList"
      class="elevation-2"
      :loading="loading2"
      :total-items="pagination2.totalItems"
      :pagination.sync="pagination2"
      :rows-per-page-items="pagination2.rowsPerPageItems"
    >
      <template v-slot:items="props">
        <!-- <td class="text-xs-center">{{ props.item.course }}</td> -->
        <td class="text-xs-center">{{ props.item.exam_roll }}</td>
        <td class="text-xs-center">{{ props.item.reg_no }}</td>
        <td class="text-xs-center">{{ props.item.session }}</td>
        <td class="text-xs-center">{{ props.item.candidate_type }}</td>
        <td class="text-xs-center">{{ props.item.user_name }}</td>
        <td class="text-xs-center">{{ props.item.old_mark }}</td>
        <td class="text-xs-center">{{ props.item.mark }}</td>
        <!-- <td class="text-xs-center">{{ props.item.created_at }}</td> -->
        <td class="text-xs-center">
          {{ dateFormate(props.item.updated_at) }}
        </td>
        <!-- <td class="text-xs-center">
              <v-btn
                color="primary"
                outline
                round
                @click="showDetailItem(props.item)"
              >Click to See All The Student Details</v-btn>
          </td>-->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import marksEnrty from "./practicalMarksEntry.vue";
export default {
    components:{marksEnrty},
  data() {
    return {
      open: false, // For v-expansion-panel's v-model
      searchParam: {
        exam_code: null,
        year: null,
        center_code: null,
        subject_code: null,
        paper_code: null,
      },
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 25, { text: "All", value: -1 }],
      },
      pagination2: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 25, { text: "All", value: -1 }],
      },
      getExamList: [], // Replace with API data or mocked data
      getYearList: [], // Replace with API data or mocked data
      getPracticalCenterList: [], // Replace with API data or mocked data
      getAllSub: ["Bangla", "English"], // Replace with API data or mocked data
      getPracticalSubject: [], // Replace with API data or mocked data
      getPracticalPaperItem: [], // Replace with API data or mocked data
      paperLoading: false, // Loading indicator for paper items
      getDataList: [],
      getEditedDataList: [],
      sideTitleOfSearchPanel: "Search Exams", // Example side title
      headers: [
        { text: "Date", value: "create", align: "center", sortable: false },
        // { text: "Course", value: "course_id", align: "center" },
        {
          text: "Subject",
          value: "subject_code",
          align: "center",
          sortable: false,
        },
        {
          text: "Course",
          value: "paper_code",
          align: "center",
          sortable: false,
        },

        {
          text: "Exam",
          value: "exam_code",
          align: "center",
          sortable: false,
        },
        { text: "Year", value: "year", align: "center", sortable: false },
        {
          text: "Examinee Count",
          value: "student_count",
          align: "center",
          sortable: false,
        },
        // { text: "Student Details", value: "mark", align: "center" },
        { text: "Action", value: "edit", align: "center", sortable: false },
      ],
      editlogheaders: [
        { text: "Roll", value: "exam_roll", align: "center", sortable: false },
        {
          text: "Registration No",
          value: "reg_no",
          align: "center",
          sortable: false,
        },
        { text: "Session", value: "session", align: "center", sortable: false },
        {
          text: "Canditate Type",
          value: "candidate_type",
          align: "center",
          sortable: false,
        },
        {
          text: "User Name",
          value: "user_name",
          align: "center",
          sortable: false,
        },
        {
          text: "Previous Mark",
          value: "old_mark",
          align: "center",
          sortable: false,
        },
        { text: "New Mark", value: "mark", align: "center", sortable: false },
        // { text: "Created Date", value: "created_at", align: "center" },
        {
          text: "Edited date",
          value: "updated_at",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    bothSearch() {
      //   this.searchPracticalExamList();
      //   this.searchPracticalEditLog();
    },
  },
};
</script>
<style lang=""></style>
