<template>
  <v-container fluid>
    <div>
      <v-container fluid>
        <v-card color="white">
          <v-layout row>
            <v-card flat>
              <v-badge left overlap>
                <v-card-text class="title">Paper Wise Present List</v-card-text>
              </v-badge>
            </v-card>
            <v-divider class="my-2" vertical></v-divider>

            <v-spacer />
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="exportAttendancexls"
              >Download As Excel</v-btn
            >
          </v-layout>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="getSchema"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:items="props">
            <td class="text-xs-left">
              {{ props.item.subject_code }}-
              {{ props.item.subject_name }}
            </td>
            <td class="text-xs-left">
              {{ props.item.attendance_date }}
            </td>
            <td class="text-xs-left font-weight-bold">
              <v-chip>
                {{ `${props.item.paper_code}-` + " " + props.item.paper_name }}
              </v-chip>
            </td>
            <td class="text-xs-left">
              {{ props.item.total_examinees }}
            </td>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </v-container>
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        { text: "Subject Code & Name", align: "left", value: "subject_code" },
        { text: "Attendance Date", align: "left", value: "attendance_date" },
        { text: "Paper Code & Name", align: "left", value: "paper_code" },
        { text: "Total Examinee", align: "left", value: "total_examinees" }
      ]
    };
  },

  computed: {
    getSchema() {
      return this.$store.getters.getPaperWisePresentList;
    },
    getLoggedInExam() {
      return this.$store.getters.getLoggedInExam;
    },
    getLoggedInYear() {
      return this.$store.getters.getLoggedInYear;
    },
    getSubject() {
      return this.$store.getters.getAllSubject;
    }
  },

  created() {
    this.$store.dispatch("getPaperWisePresentList", {
      exam_code: this.getLoggedInExam.code,
      exam_year: this.getLoggedInYear.year,
      respons_type: "show_data"
    });
  },

  methods: {
    exportAttendancexls() {
      this.$store.dispatch("downloadPaperWisePresentList", {
        exam_code: this.getLoggedInExam.code,
        exam_year: this.getLoggedInYear.year,
        respons_type: "export_as_excel"
      });
    }
  }
};
</script>

<style scoped></style>
