<template>
  <v-container fluid>
    <v-layout row wrap align-center>
      <v-flex xs12 md12>
        <template>
          <v-snackbar v-model="errorSnackbar" color="error" top right>
            <div class="snackbar-content">
              {{ errorMessage }}
              <v-btn icon @click="errorSnackbar = false">
                <span class="close-icon" @click="errorSnackbar = false"
                  >&times;</span
                >
              </v-btn>
            </div>
          </v-snackbar>
          <v-card color="white">
            <v-layout row style="margin-top: 40px">
              <v-card flat>
                <v-badge left overlap color="transparent">
                  <template v-slot:badge>
                    <v-btn
                      fab
                      color="primary"
                      small
                      style="margin-top: -0.8em"
                      class="elevation-0"
                      >{{ getatachmentsListData.length }}</v-btn
                    >
                  </template>
                  <v-card-text class="title" style="margin-top: 10px"
                    >Attachments List</v-card-text
                  >
                </v-badge>
              </v-card>
              <v-divider class="my-2" vertical></v-divider>
              <v-spacer />
              <v-flex
                xs12
                sm3
                d-flex
                style="
                  padding-top: 10px;
                  padding-right: 20px;
                  margin-bottom: -10px;
                "
                v-if="getAuthUser.user_type == 1 || getAuthUser.user_type == 9"
              >
                <v-select
                  :items="items"
                  v-model="selectedUser"
                  label="Select User"
                  prepend-icon="list"
                  @change="getAtachmentsList()"
                ></v-select>
              </v-flex>
              <v-flex
                xs6
                sm6
                md3
                style="
                  padding-top: 10px;
                  padding-right: 40px;
                  margin-right: -180px;
                  margin-bottom: -10px;
                "
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  v-if="
                    getAuthUser.user_type == 1 || getAuthUser.user_type == 9
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      label="Select file submit date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="(menu2 = false); getAtachmentsList()"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm2
                d-flex>
              <template>
                <v-layout row justify-center>
                  <v-dialog
                    v-model="SendfILEdialog"
                    persistent
                    max-width="740px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        dark
                        v-on="on"
                        style="
                          margin-top: 14px;
                        "
                        v-if="
                          getAuthUser.user_type == 2 ||
                          getAuthUser.user_type == 5
                        "
                        >Send Files</v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title
                        style="background-color: #3f51b5; color: #fff"
                      >
                        <span class="headline">Send Attachments</span>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                            fab
                            class="ma-1 pa-0"
                            small
                            color="red darken-3 white--text"
                            @click="clearobj()"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-toolbar-items>
                      </v-card-title>
                      <v-card-text>
                        <v-container grid-list-md style="text-align: center">
                          <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                disabled
                                label="FROM"
                                v-model="to_sender"
                                outline
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                disabled
                                label="TO"
                                v-model="from_con"
                                outline
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 style="margin-top: -16px">
                              <v-text-field
                                label="Heading"
                                type="text"
                                v-model="heading"
                                required
                                outline
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 style="margin-top: -16px">
                              <v-textarea
                                outline
                                name="input-7-4"
                                label="Description"
                                v-model="description"
                              ></v-textarea>
                            </v-flex>
                            <v-flex xs12 sm12 md12 style="margin-top: -16px">
                              <div class="selected-files-container">
                                <div
                                  v-for="(fileName, index) in selectedFileNames"
                                  :key="index"
                                  class="selected-file"
                                >
                                  <div
                                    color="white black--text"
                                    class="file-btn"
                                    @click="removeFile(index)"
                                  >
                                    <span class="file-name">{{
                                      fileName
                                    }}</span>
                                    <v-btn
                                      icon
                                      style="
                                        width: 20px;
                                        height: 20px;
                                        margin-right: -6px;
                                        font-size: 20px;
                                        color: #8e8e8e;
                                      "
                                      >&times;</v-btn
                                    >
                                  </div>
                                </div>
                              </div>
                            </v-flex>
                            <v-flex xs6 sm4 md4>
                              <v-text-field
                                single-line
                                label="upload file"
                                prepend-icon="attach_file"
                                name="filename"
                                @click.native="onFocus"
                                ref="fileTextField"
                              ></v-text-field>
                              <input
                                type="file"
                                ref="inputUpload4"
                                style="display: none"
                                id="file-upload"
                                @change="onFileChange($event)"
                                @click="$refs.inputUpload4.value = null"
                                multiple
                              />
                            </v-flex>
                            <v-flex xs6 sm10 md12>
                              <v-btn
                                color="primary"
                                dark
                                @click="sendAttachments()"
                                >Submit</v-btn
                              >
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </template>
              </v-flex>
            </v-layout>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="getatachmentsListData"
            class="elevation-1"
          >
            <template slot="headerCell" slot-scope="props">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                  </span>
                </template>
                <span>
                  {{ props.header.text }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.index + 1 }}</td>
              <td class="text-xs-center">
                {{ props.item.exam_code }} - {{ props.item.exam_year }}
              </td>
              <td class="text-xs-left">
                {{ props.item.center_code }} - {{ props.item.from }}
              </td>
              <td class="text-xs-left">{{ props.item.subject }}</td>
              <td class="text-xs-center">
                <v-layout row justify-center>
                  <v-dialog
                    v-model="attachmentsProps"
                    persistent
                    max-width="700px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        dark
                        v-on="on"
                        @click="showAttachmentsDATA(props.item.attachments)"
                        > <v-icon>attachment</v-icon>&nbsp;&nbsp;View</v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title
                        style="background-color: #3f51b5; color: #fff"
                      >
                        <span class="headline"
                          >Attachments <span style="font-size:18px;">( {{ props.item.center_code }} -
                          {{ props.item.from }} )</span></span
                        >
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                            fab
                            class="ma-1 pa-0"
                            small
                            color="red darken-3 white--text"
                            @click="attachmentsProps = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-toolbar-items>
                      </v-card-title>
                      <v-card-text>
                        <v-flex xs12 sm12 md12>
                          <div>
                            <table> 
                                <thead style="background-color:#2497A7;">
                                    <th style="padding:6px; width:10%; color:#fff;">SL</th>
                                    <th style="padding:6px; width:80%; color:#fff;">File Name</th>
                                    <th style="padding:6px; width:20%; color:#fff;">Action</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listItemData" :key="index">
                                        <td class="text-xs-center"  style="padding:6px;">{{ index + 1 }}</td>
                                        <td class="text-xs-left" style="padding:6px; padding-left:20px;">{{ item.name }}</td>
                                        <td class="text-xs-center" style="padding:6px;">
                                            <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                color="#EEEEEE"
                                                dark
                                                fab
                                                small
                                                v-on="on"
                                                @click="viewPDF(item.path)"
                                                style="border: 1px solid #d32f2f"
                                                >
                                                <img
                                                    src="@/assets/nu_logos/pdf1.png"
                                                    style="color: #fff; width: 60% !important"
                                                />
                                                </v-btn>
                                            </template>
                                            <span
                                                >view pdf</span
                                            >
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                        </v-flex>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </td>
            </template>
          </v-data-table>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_getters_mixin from "../../mixin/common_getters_mixin";
import common_exam_lg from "../../mixin/common_login_getters.vue";
export default {
  mixins: [common_getters_mixin, commonExamYear, common_exam_lg],
  data() {
    return {
      selectedUser:'ALL',
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      items: ["ALL","Center", "Examiner", "Head-Examiner"],
      SendfILEdialog: false,
      to_sender: "",
      from_con: "Controller of Examination, NU",
      heading: "",
      description: "",
      file_name: "",
      attachments: [],
      file_names: [],
      showCancel: false,
      attachmentsProps: false,
      headers: [
        {
          text: "SL",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Exam code / year",
          value: "exam_code",
          align: "center",
        },
        {
          text: "From",
          value: "center_code",
          align: "center",
        },
        // {
        //     text: 'To',
        //     value: ''
        // },
        {
          text: "Subject",
          value: "protein",
          align: "center",
        },
        {
          text: "Action",
          value: "",
          align: "center",
        },
      ],
      Attachmentsheaders: [
        {
          text: "SL",
          align: "center",
          sortable: false,
          value: "",
        },
        {
          text: "File Name",
          value: "",
          align: "center",
        },
        {
          text: "Action",
          value: "",
          align: "center",
        },
      ],
      errorSnackbar: false,
      errorMessage: "",
      listItemData: [],
    };
  },

  created() {
    this.serderSetter();
    this.getAtachmentsList();
  },

  computed: {
    getatachmentsListData() {
      return this.$store.getters.getatachments;
    },
    selectedFileNames() {
      const maxWidth = 120;
      const ellipsis = "...";
      const names = this.file_names.map((name) => {
        if (name.length > maxWidth) {
          return name.substring(0, maxWidth - ellipsis.length) + ellipsis;
        }
        return name;
      });
      return names;
    },
  },

  watch: {},

  methods: {
    getAtachmentsList() {
      let peram = {};
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      peram.exam_initiate_id = this.getExamInitiateId;
      if (this.getAuthUser.user_type == 2) {
        peram.center_code = this.getAuthUser.center.center_code;
      } else if (
        this.getAuthUser.user_type == 5 &&
        this.getAuthUser.examiner.details.tims_id
      ) {
        peram.tmis_id = this.getAuthUser.examiner.details.tims_id;
      } else if (
        this.getAuthUser.user_type == 5 &&
        !this.getAuthUser.examiner.details.tims_id
      ) {
        peram.tmis_id = this.getAuthUser.examiner.details.user_id;
      } else {
        console.log("Tanvir! This DICT or Controller user");
      }
      peram.from_date = this.date;
      if(this.selectedUser == 'Center'){
        peram.user_type = "C";
      }else if(this.selectedUser == 'Examiner'){
        peram.user_type = "E";
      }else if(this.selectedUser == 'Head-Examiner'){
        peram.user_type = "H";
      }else{
        peram.user_type = "";
      }
      this.$store.dispatch("fetchAtachments", peram).then(() => {
        console.log("Tanvir! This DICT or Controller user");
      });
    },
    showAttachmentsDATA(List) {
      this.listItemData = List;
    },
    viewPDF(path) {
      window.open(path);
    },
    serderSetter() {
      if (this.getAuthUser.user_type == 2) {
        this.to_sender = this.getAuthUser.center.center_name;
      } else if (this.getAuthUser.user_type == 5) {
        this.to_sender = this.getAuthUser.name;
      }
    },
    onFocus() {
      document.getElementById("file-upload").click();
    },
    onFileChange(e) {
      var files = e.target.files;
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file) {
            if (file.type !== "application/pdf") {
              this.showErrorSnackbar("Only PDF files are accepted.");
              return;
            }
            if (file.size > 2000 * 1024) {
              this.showErrorSnackbar(
                "PDF file size must not be more than 500KB."
              );
              return;
            }
            this.attachments.push(file);
            this.file_names.push(file.name + " ");
          }
        }
        this.showCancel = true;
        console.log("...m up", this.attachments);
      } else {
        this.cancel();
      }
    },
    removeFile(index) {
      this.file_names.splice(index, 1);
      this.attachments.splice(index, 1);
    },
    showErrorSnackbar(message) {
      this.errorMessage = message;
      this.errorSnackbar = true;
    },
    cancel() {
      this.showCancel = false;
      this.attachments = [];
      this.file_names = [];
    },
    clearobj() {
      this.SendfILEdialog = false;
      this.heading = "";
      this.description = "";
      this.showCancel = false;
      this.attachments = [];
      this.file_names = [];
    },

    sendAttachments() {
      this.SendfILEdialog = true;
      let peram = {};
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.center_code = this.getAuthUser.center.center_code;
      peram.files = this.attachments;
      peram.subject = this.heading;
      peram.description = this.description;
      if (this.getAuthUser.user_type == 2) {
        peram.user_type = "C";
      } else if (this.getAuthUser.user_type == 5) {
        peram.user_type = this.getAuthUser.examiner.details.examiner_type;
      } else {
        peram.user_type = "";
      }
      console.log("Tanvir this is peram data", peram);
      this.$store.dispatch("attachmentsfile", peram).then(() => {
        this.clearobj();
        this.getAtachmentsList();
      });
    },
  },
};
</script>

<style>
.selected-files-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 642px;
  border-bottom: 1px solid #c1c1c1;
  background-color: white;
  padding: 8px;
}

.selected-file {
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
}

.file-btn {
  padding: 0 10px;
  border-radius: 25px;
  height: 28px;
  display: flex;
  align-items: center;
  border: 1px solid #c9c7c7;
  background-color: white;
  box-shadow: none;
  color: black;
}

.file-name {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.snackbar-content {
  display: flex;
  align-items: center;
}

.close-icon {
  font-size: 23px;
  color: black;
  cursor: pointer;
}
</style>
