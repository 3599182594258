import Axios from "axios";
import {
    GET_ADMIT_CARD_DATA_FOR_INSTALLMENT,
    EDIT_ADMIT_CARD_DATA_FOR_INSTALLMENT
} from "../../mutation-types";
import {
    admitcardInstallment
} from "../../api";


const state = {
  admitCardinstallment: [],
  editAdmitCardinstallment: [],
};
const getters = {
  getAdmitCardInstallmentData() {
    return state.admitCardinstallment;
  },
  getEditAdmitCardInstallmentData() {
    return state.editAdmitCardinstallment;
  },
};
const mutations = {
  [GET_ADMIT_CARD_DATA_FOR_INSTALLMENT](state, result) {
    state.admitCardinstallment = result;
  },
  [EDIT_ADMIT_CARD_DATA_FOR_INSTALLMENT](state, result) {
    state.editAdmitCardinstallment = result;
  },
};
const actions = {
  fetchAdmitInstallmentData({
    commit
  }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary"
      });
      Axios.get(admitcardInstallment, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data;
          console.log("Tanvir, this is admit Installment data->", result);
          commit("GET_ADMIT_CARD_DATA_FOR_INSTALLMENT", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  postAdmitInstallmentData({ commit }, peram) {
    console.log("This is from api", peram);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary"
      });
      Axios.post(admitcardInstallment, peram)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, Installment chrated successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Process failed. Please try again !!!",
            color: "red"
          });
          reject(error)
        });
    });
  },

  async editInstallmentData({ commit }, id){
    commit("SET_LOADING", {
      color: "Primary"
    });
    const response = await Axios.get(admitcardInstallment + '/' + id);
    commit("UNSET_LOADING");
    var editlist = response.data.data;
    commit("EDIT_ADMIT_CARD_DATA_FOR_INSTALLMENT", editlist);
    return response.data.message;
  },

  async updateAdmitInstallmentData({commit},item){
    commit("SET_LOADING", {
      color: "Primary"
    });
    const response = await Axios.put(admitcardInstallment + '/' + item.id, item);
    commit("UNSET_LOADING");
    

  },

};


export default {
  state,
  getters,
  actions,
  mutations,
};