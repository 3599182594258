import Axios from "axios";
import { FORMFILLUPSAMMARY, StudentSummery } from "../../api";
import { GET_FORM_FILLUP_SUMMARY_REPORT } from "../../mutation-types";
import { resolve } from "path";

const state = {
  summaryReport: {}
};

const getters = {
  getFormFillupSummaryReport(state) {
    return state.summaryReport;
  }
};

const mutations = {
  [GET_FORM_FILLUP_SUMMARY_REPORT](state, response) {
    state.summaryReport = response;
  }
};

const actions = {
  formFillupSummaryReport({ commit }, searchParam) {
    commit("GET_FORM_FILLUP_SUMMARY_REPORT", {});
    commit("SET_LOADING", { color: "primary" });
    Axios.get(FORMFILLUPSAMMARY, {
      params: searchParam
    })
      .then(response => {
        commit("UNSET_LOADING");
        var resp = response;
        commit("GET_FORM_FILLUP_SUMMARY_REPORT", resp);
      })
      .catch(error => {
        commit("UNSET_LOADING");
      });
  },
  searchStudentSummary({ commit }, searchParam) {
    commit("SET_LOADING", { color: "primary" });
    return Axios.get(StudentSummery, {
      params: searchParam
    })
      .then(response => {
        commit("UNSET_LOADING");
        const result = response.data;
        return result;
      })
      .catch(() => {
        commit("UNSET_LOADING");
        return null;
      });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
