import Axios from "axios";
import {getStudentSummaryDetails} from "@/store/api";
import {GET_STUDENT_SUMMERY_DATA} from "../../mutation-types";

const state = {
    studentSummary:[],
};
const getters = {
    studentSummaryDetailsData(){
        return state.studentSummary;
    }
};
const mutations = {
    [GET_STUDENT_SUMMERY_DATA](state, result){
        state.studentSummary = result;
    },
};
const actions = {
    fetchStudentSummaryData({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
          commit("SET_LOADING", {
            color: "primary",
          });
          // loading  Opening Scrutinizer List
          Axios.get(getStudentSummaryDetails, {
            params: searchParam,
          })
            .then(function (response) {
              commit("UNSET_LOADING"); // loading Closing
              var result = response.data;
              console.log("Tanvir, this is student summary (js)->", result);
              commit("GET_STUDENT_SUMMERY_DATA", result);
              resolve(response.data);
            })
            .catch(function (error) {
              commit("UNSET_LOADING"); // loading Closing
              console.log(error);
              reject();
            });
        });
      },
};

export default {
    state,
    getters,
    actions,
    mutations
  };
  
