<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->
<template>
  <v-container fluid>
    <resultDetails
      :dialogStatus.sync="detailsDialog"
      :detailData="detailData"
    ></resultDetails>
    
    <v-card>
      <v-toolbar color="primary" class="white--text" dense>
        <v-toolbar-title>Result List</v-toolbar-title>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex md12>
            <v-card flat v-for="(data, index) in getSchema" :key="index">
              <v-card-title primary-title class="justify-center">
                <div>
                  <div class="headline mb-0">
                    {{ data.core_exam ? data.core_exam.exam_name : "" }}
                    Examination - {{ data.exam_year }}
                  </div>
                  <div class="text-md-center">
                    <v-chip
                      v-if="data.result_type"
                      class="font-weight-bold title"
                    >
                      {{ data.type_of_result.result_type_name }}
                    </v-chip>
                  </div>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3">
                <span>
                  <v-chip
                    outline
                    color="blue-grey darken-4"
                    class="font-weight-bold"
                  >
                    Published on : {{ formatted_date(data.created_at) }}
                  </v-chip>
                </span>
                <v-spacer></v-spacer>
                <v-btn round color="info" @click="showDetails(data)"
                  >Result Details</v-btn
                >

                <v-btn
                  round
                  color="primary"
                  v-if="
                    data.result_type == 1 ||
                      data.result_type == 5 ||
                      data.result_type == 2
                  "
                  @click="downloadStudentResult(data)"
                  >Download Result</v-btn
                >
              </v-card-actions>
              <hr />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import resultDetails from "@/components/common/resultDetails.vue";

export default {
  components: {
    resultDetails
  },
  data: () => ({
    detailsDialog: false,
    detailData: {}
  }),

  computed: {
    getSchema() {
      return this.$store.getters.fetchStudentResultList;
    }
  },

  created() {
    this.$store.dispatch("fetchStudentResultList");
  },
  methods: {
    showDetails(data) {
      this.detailData.exam_code = data.exam_code;
      this.detailData.exam_year = data.exam_year;
      this.detailData.exam_display_name =
        data.core_exam.exam_name + " Examination " + "-" + data.exam_year;
      this.detailData.student_name = data.student.name;
      this.detailData.student_father_name = data.student.father_name;
      this.detailData.student_mother_name = data.student.mother_name;
      this.detailData.college_display_name =
        "[" +
        data.form_fillup_info.college.college_code +
        "] " +
        data.form_fillup_info.college.college_name +
        ", " +
        data.form_fillup_info.college.districts.district_name;

      this.detailData.exam_roll = data.exam_roll;
      this.detailData.reg_no = data.reg_no;
      this.detailData.student_degree_code = data.student.degree_code;
      this.detailData.ac_session = data.student.ac_session;
      this.detailData.student_type = data.student.reg_type.student_type;
      this.detailData.result_type = data.result_type;
      this.detailData.result_type_name = data.result_type_name;
      this.detailData.course_grade_title = data.course_grade_title;

      const result_details = [];
      data.combine_result_details.forEach(paper => {
        result_details.push({
          paper_code: paper.paper_code,
          paper_name: paper.paper.paper_name,
          credit: paper.credit,
          letter_grade: paper.letter_grade
        });
      });

      this.detailData.result_details = result_details;
      this.detailsDialog = true;
    },
    downloadStudentResult(data) {
      let file_name =
        data.exam_code + "_" + data.exam_year + "_" + data.exam_roll;
      this.$store.dispatch("downloadStudentResultDetails", {
        params: {
          result_id: data.id,
          file_name: file_name
        },
        responseType: "blob"
      });
    },
    formatted_date(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>

<style scoped>
.v-card__title--primary {
  padding-top: 0;
}

.v-card__title {
  padding-bottom: 16px;
}
.a {
  border: 1px solid black;
  border-collapse: collapse;
}
.c {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

.b {
  padding: 5px;
  text-align: left;
}

.mytableClass {
  float: left;
  width: 25%;
}
.text-center {
  text-align: center;
}
.nowrap {
  white-space: nowrap;
}
</style>
