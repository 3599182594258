/* eslint-disable prettier/prettier */
import Axios from 'axios';

import {
    SET_FORM_FILLUP_DATA,
    UNSET_FORM_FILLUP_DATA,
    SET_FORM_FILLUP_COLLEGEWISE_DATA,
    UNSET_FORM_FILLUP_COLLEGEWISE_DATA,
    SET_FORM_FILLUP_CURRENT_ITEM_DETAILS,
    SET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS,
    UNSET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS,
    SET_COLLEGEWISE_STD_COUNT_DATA,
    UNSET_COLLEGEWISE_STD_COUNT_DATA,
    SET_FF_PAPER_EXCHANGE_PAPER_CODES,
    UNSET_FF_PAPER_EXCHANGE_PAPER_CODES,
    SET_REPORTWISE,
    UNSET_REPORTWISE,
    SET_PAYMENT_INSTALLMENT_FORM_SCHEMA,
    UNSET_PAYMENT_INSTALLMENT_FORM_SCHEMA,
    SET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA,
    UNSET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA,
    SET_PAYMENT_INSTALLMENT,
    UNSET_PAYMENT_INSTALLMENT,
    SET_FORM_FILLUP_REPORT_HTML,
    UNSET_FORM_FILLUP_REPORT_HTML
} from '../../mutation-types';

import {
    FormFillUpFetchUrl,
    FormFillUpCollegeWiseFetchUrl,
    FormFillUpAllUrl,
    FormFillUpColWiseMainUrl,
    FormFillUpColWiseDetailsUrlUrl,
    CollegeListDetailsUrl,
    fetchFormFillUpReportDataUrl,
    getFFPaperExchangePaperCodeURL,
    submitFFPaperExchangePaperCodeURL,
    getReporwiseUrl,
    storePaymentInstallmentAdminUrl,
    getAllPaymentInstallmentAdminUrl,
    updatePaymentInstallmentAdminUrl,
    processPaymentInstallmentAdminUrl
} from '../../api';
import { updateMsg, failedMsg } from '../../erpUtil'
import moment from "moment";
//
const state = {
    getPaymentInstallmentSchema: {
        title:'',
        no_of_installment: 1,
        ff_start_date: moment().subtract(11, 'days').format("YYYY-MM-DD"),
        ff_end_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        status: "1"
    },
    getPaperExchangeSchema: {
        reg_no: null,
        selected_credit: 0,
        form_fill_up: []
    },
    getFormFillupReportSchema: {
        apply_from_date: null,
        apply_to_date: null,
        confirm_from_date: null,
        confirm_to_date: null,
        payment_from_date: null,
        payment_to_date: null,
        paper_type: [],
        data_source: 'probable_data',
        report_name: 'college_wise_form_fillup_count_list',
        document_type: '.csv'
    },
    getReportWise: [],
    datalist: [],
    // main
    dataListColWise: [],
    // details
    dataListColWiseDetails: [],
    // current
    detailCurrentItem: {},
    //for center allocation
    collegeList: [],
    paymentInstallment: [],
    paymentInstallmentDetails:[],
    html:``
};

const getters = {
    getPaymentInstallmentSchema(state) {
        return state.getPaymentInstallmentSchema;
    },
    getPaperExchangeSchema(state) {
        return state.getPaperExchangeSchema;
    },
    getFormFillupReportSchema(state) {
        return state.getFormFillupReportSchema;
    },
    getReportWise(state) {
        return state.getReportWise
    },
    getFormFillupItem(state) {
        return state.datalist;
    },
    getFormFillupCollgeWiseItem(state) {
        return state.dataListColWise;
    },
    getFormFillupCollgeWiseDetailsItem(state) {
        return state.dataListColWiseDetails;
    },
    getCurrentDetailItem(state) {
        return state.detailCurrentItem;
    },
    getCollegeWiseStdCount(state) {
        return state.collegeList;
    },
    getAllPaymentInstallment(state) {
        return state.paymentInstallment;
    },
    getFormFillupReportHTML(state){
        return state.html
    },
    getPaymentInstallmentDetails(state) {
        return state.paymentInstallmentDetails;
    },
};
const mutations = {
    [SET_FORM_FILLUP_DATA](state, result) {
        state.datalist = result;
    },
    [UNSET_FORM_FILLUP_DATA](state) {
        state.datalist = [];
    },
    [SET_FORM_FILLUP_REPORT_HTML](state, result) {
        state.html = result;
    },
    [UNSET_FORM_FILLUP_REPORT_HTML](state) {
        state.html = ``;
    },
    // MAIN
    [SET_FORM_FILLUP_COLLEGEWISE_DATA](state, result) {
        state.dataListColWise = result;
    },
    [UNSET_FORM_FILLUP_COLLEGEWISE_DATA](state) {
        state.dataListColWise = [];
    },
    // DETAILS
    [SET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS](state, result) {
        state.dataListColWiseDetails = result;
    },
    [UNSET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS](state) {
        state.dataListColWiseDetails = [{}];
    },
    // CURRENT
    [SET_FORM_FILLUP_CURRENT_ITEM_DETAILS](state, item) {
        state.detailCurrentItem = item;
    },
    [SET_COLLEGEWISE_STD_COUNT_DATA](state, collegList) {
        state.collegeList = collegList;
    },
    [UNSET_COLLEGEWISE_STD_COUNT_DATA](state) {
        state.collegeList = [];
    },
    [SET_FF_PAPER_EXCHANGE_PAPER_CODES](state, data) {
        state.getPaperExchangeSchema.form_fill_up = data;
    },
    [UNSET_FF_PAPER_EXCHANGE_PAPER_CODES](state) {
        state.getPaperExchangeSchema.form_fill_up = [];
    },
    [SET_REPORTWISE](state, data) {
        state.getReportWise = data;
    },
    [UNSET_REPORTWISE](state) {
        state.getReportWise = [];
    },

    [SET_PAYMENT_INSTALLMENT_FORM_SCHEMA](state, data) {
        state.getPaymentInstallmentSchema = data;
    },
    [UNSET_PAYMENT_INSTALLMENT_FORM_SCHEMA](state ,data) {

        state.getPaymentInstallmentSchema = Object.assign(data, {
            no_of_installment: 1,
            ff_start_date: moment().subtract(11, 'days').format("YYYY-MM-DD"),
            ff_end_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
            status: "1"
        });
    },
    [SET_PAYMENT_INSTALLMENT](state, data) {
        state.paymentInstallment = data;
    },
    [UNSET_PAYMENT_INSTALLMENT](state) {
        state.paymentInstallment = [];
    },
    [SET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA](state, data) {
        state.paymentInstallmentDetails = data;
    },
    [UNSET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA](state) {
        state.paymentInstallmentDetails = [];
    },

};
const actions = {
    searchFormFillup({ commit }, searchParam) {
        console.log(JSON.stringify(searchParam));
        return new Promise((resolve, reject) => {
            commit('SET_LOADING', {
                color: 'primary',
            });
            Axios.get(FormFillUpAllUrl, {
                params: searchParam,
            })
                .then(function (response) {
                    commit('UNSET_LOADING'); // loading Closing
                    var result = response.data.data;
                    commit('SET_FORM_FILLUP_DATA', result);
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log('searchFormFillup: in catch ??');
                    commit('UNSET_LOADING'); // loading Closing
                    console.log(error);
                    reject();
                });
        });
    },
    searchFormFillupCollegeWise({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit('SET_LOADING', {
                color: 'primary',
            });
            // loading  Opening
            Axios.get(FormFillUpColWiseMainUrl, {
                params: searchParam,
            })
                .then(function (response) {
                    commit('UNSET_LOADING'); // loading Closing
                    var result = response.data.data;
                    console.log('˜˜˜˜', response.data.data);
                    commit('SET_FORM_FILLUP_COLLEGEWISE_DATA', result);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit('UNSET_LOADING'); // loading Closing
                    console.log(error);
                    reject();
                });
        });
    },
    searchFormFillupCollegeWiseDetails({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            // commit("SET_LOADING", {
            //   color: "primary"
            // });
            // loading  Opening
            console.log(
                'FormFillUpColWiseDetailsUrlUrl::',
                FormFillUpColWiseDetailsUrlUrl
            );
            Axios.get(FormFillUpColWiseDetailsUrlUrl, {
                params: searchParam,
            })
                .then(function (response) {
                    // commit("UNSET_LOADING"); // loading Closing
                    var result = response.data.data;
                    console.log('˜˜details-response˜˜', response);
                    commit('SET_FORM_FILLUP_COLLEGEWISE_DATA_DETAILS', result);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit('UNSET_LOADING'); // loading Closing
                    console.log(error);
                    reject();
                });
        });
    },
    fetchFormFillupCollegeWiseSubjectWiseCount({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit('SET_LOADING', {
                color: 'primary',
            });
            // loading  Opening
            Axios.get(CollegeListDetailsUrl, {
                params: searchParam,
            })
                .then(function (response) {
                    commit('UNSET_LOADING'); // loading Closing
                    var result = response.data.data;
                    console.log('˜˜˜˜', response.data.data);
                    commit('SET_COLLEGEWISE_STD_COUNT_DATA', result);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit('UNSET_LOADING'); // loading Closing
                    console.log(error);
                    reject();
                });
        });
    },
    fetchFormFillupReportData({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit('SET_LOADING', {
                color: 'primary',
            });
            // loading  Opening
            var requestData = {
                params: searchParam,
            }
            if (searchParam.document_type != 'show_data') {
                requestData.responseType = "blob"

            }
            Axios.get(fetchFormFillUpReportDataUrl, requestData)
                .then(function (response) {
                    if(searchParam.document_type != 'show_data'){
                        commit('UNSET_FORM_FILLUP_REPORT_HTML',``);
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", searchParam.report_name + searchParam.document_type); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                    else{
                        // if(response.data.data.html)
                        commit('SET_FORM_FILLUP_REPORT_HTML', response.data.data.html);
                        
                    }  
                    resolve(response);                  
                })
                .catch(function (error) {
                    commit('UNSET_LOADING'); // loading Closing
                    console.log(error);
                    reject();
                }).then(always => {
                    commit("UNSET_LOADING");
                });
        });
    },
    getFFPaperExchangePaperCodes({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            // console.log('searchParam',searchParam)
            commit('SET_LOADING', { color: 'primary', });
            commit('UNSET_FF_PAPER_EXCHANGE_PAPER_CODES');
            // loading  Opening
            Axios.get(getFFPaperExchangePaperCodeURL, {
                params: searchParam,
            })
                .then(function (response) {
                    commit('UNSET_LOADING'); // loading Closing
                    var result = response.data.data;
                    // console.log('˜˜˜˜', response.data.data);
                    commit('SET_FF_PAPER_EXCHANGE_PAPER_CODES', result);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit('UNSET_FF_PAPER_EXCHANGE_PAPER_CODES');
                    if (error.response.status = 404) {
                        commit("SET_SNACKBAR", { msg: error.response.statusText, color: "red" });
                    } else {
                        commit("SET_SNACKBAR", { msg: failedMsg, color: "red" });
                    }
                    reject();
                }).then(always => {
                    commit('UNSET_LOADING'); // loading Closing
                });
        });
    },
    submitFFPaperExchangePaperCodes({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            // console.log('searchParam',searchParam)
            commit('SET_LOADING', {
                color: 'primary',
            });
            // commit('UNSET_FF_PAPER_EXCHANGE_PAPER_CODES');
            // loading  Opening
            Axios.post(submitFFPaperExchangePaperCodeURL, searchParam)
                .then(function (response) {
                    commit("SET_SNACKBAR", { msg: updateMsg, color: "green" });
                    commit('UNSET_FF_PAPER_EXCHANGE_PAPER_CODES');
                    commit('UNSET_LOADING'); // loading Closing
                    console.log('ok >>', response.data);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit("SET_SNACKBAR", { msg: failedMsg, color: "red" });
                    console.log('error>>', error.response);
                    reject();
                }).then(always => {
                    commit('UNSET_LOADING'); // loading Closing
                });
        });
    },
    fetchReportWise({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit('SET_LOADING', {
                color: 'primary',
            });
            Axios.get(getReporwiseUrl, { params: searchParam })
                .then(function (response) {
                    commit('UNSET_LOADING');
                    var result = response.data.data;
                    commit('SET_REPORTWISE', result);
                    resolve(result)
                })
                .catch(function (error) {
                    commit('UNSET_REPORTWISE', []);
                    commit('UNSET_LOADING');
                    console.log(error);
                    reject();
                })
        })
    },
    storePaymentInstallment({ commit }, schema) {
        let data = schema;
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary"
            }); // loading Opening
            Axios.post(storePaymentInstallmentAdminUrl, data)
                .then(function (response) {
                    commit("UNSET_LOADING"); // loading Closing
                    if (response.data.code == 200) {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "green"
                        });
                        resolve();
                    } else {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "red"
                        });
                        reject();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    commit("UNSET_LOADING"); // loading Closing
                    if (error.response.data.code == 409) {
                        commit("SET_SNACKBAR", {
                            msg: error.response.data.message,
                            color: "red"
                        });
                    }else{
                        commit("SET_SNACKBAR", {
                            msg: failedMsg,
                            color: "red"
                        });
                    }
                    
                    reject();
                });
        });
    },
    getAllPaymentInstallment({ commit },searchParam) {
        return new Promise(function (resolve, reject) {
            commit("SET_LOADING", { color: "primary" });
            Axios.get(getAllPaymentInstallmentAdminUrl,{params:searchParam})
                .then(function (response) {
                    commit("UNSET_LOADING");
                    let result = response.data.data;
                    commit("SET_PAYMENT_INSTALLMENT", result);
                    resolve();
                })
                .catch(function (error) {
                    commit("UNSET_LOADING");
                    console.log(error);
                    reject();
                });
        });
    },
    updatePaymentInstallment({ commit }, schema) {
        // commit("SET_ACTION_PROCESS");
        let data = schema;
        const url = `${updatePaymentInstallmentAdminUrl}/${data.id}`;
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary"
            }); // loading Opening
            Axios.put(url, data)
                .then(function (response) {
                    commit("UNSET_LOADING"); // loading Closing
                    if (response.data.status === "success") {
                        commit("SET_SNACKBAR", {
                            msg: updateMsg,
                            color: "green"
                        });
                        resolve();
                    } else {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "red"
                        });
                        reject();
                    }
                })
                .catch(function (error) {
                    commit("UNSET_LOADING"); // loading Closing
                    commit("SET_SNACKBAR", {
                        msg: failedMsg,
                        color: "red"
                    });
                    console.log(error);
                    reject();
                });
        });
    },
    processPaymentInstallment({ commit }, schema) {
        // commit("SET_ACTION_PROCESS");
        let data = schema;
        const url = `${processPaymentInstallmentAdminUrl}/${data.id}`;
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary"
            }); // loading Opening
            Axios.post(url, data)
                .then(function (response) {
                    commit("UNSET_LOADING"); // loading Closing
                    if (response.data.status === "success") {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "green"
                        });
                        resolve();
                    } else {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "red"
                        });
                        reject();
                    }
                })
                .catch(function (error) {
                    commit("UNSET_LOADING"); // loading Closing
                    commit("SET_SNACKBAR", {
                        msg: failedMsg,
                        color: "red"
                    });
                    console.log(error);
                    reject();
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};