<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    transition="dialog-bottom-transition"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card color="primary" flat>
        <v-card-title>
          <span class="headline white--text">Insert Reg Data</span>

          <v-spacer></v-spacer>
          <v-btn
            fab
            class="ma-0 pa-0"
            small
            color="white black--text "
            @click="close"
          >
            <v-icon medium>close</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-card-text>
        <v-form>
          <v-layout row wrap>
            <v-flex md12>
              <v-select
                name="degree_code"
                label="Degree"
                v-model="slotData.degree_code"
                :items="getDegrees"
                item-text="degree_display_name"
                item-value="degree_code"
                v-validate="'required'"
                @change="degreeGroupData"
                prepend-icon="edit"
                :error="!!validationErrors.degree_code"
                :error-messages="validationErrors.degree_code"
              ></v-select>
            </v-flex>

            <v-flex md12>
              <v-select
                name="degree_code"
                label="Degree Group"
                v-model="slotData.degree_group_code"
                :items="getDegreeGroup"
                :item-text="
                  item => item.degree_group_code + '-' + item.degree_group_name
                "
                item-value="degree_group_code"
                @change="getSubjectData"
                prepend-icon="edit"
                :error="!!validationErrors.degree_group_code"
                :error-messages="validationErrors.degree_group_code"
              ></v-select>
            </v-flex>
            <v-flex md12>
              <v-select
                name="subject_code"
                label="Subject"
                v-model="slotData.subject_code"
                :items="getSubject"
                :item-text="item => item.subject_code + '-' + item.subject_name"
                item-value="subject_code"
                prepend-icon="edit"
                :error="!!validationErrors.subject_code"
                :error-messages="validationErrors.subject_code"
              ></v-select>
            </v-flex>
            <v-flex md12>
              <v-text-field
                name="installment"
                label="Installment"
                type="number"
                min="1"
                v-model="slotData.installment"
                data-vv-name="installment"
                prepend-icon="edit"
                :error="!!validationErrors.installment"
                :error-messages="validationErrors.installment"
              ></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-text-field
                class="mb-2"
                name="table_name"
                label="Table Name"
                v-model="slotData.table_name"
                prepend-icon="edit"
                :error="!!validationErrors.table_name"
                :error-messages="validationErrors.table_name"
              ></v-text-field>
              <div v-if="validationErrors.table_validate" class="text-danger">
                {{ validationErrors.table_validate[0] }}
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn color="primary  white--text" @click="save()">Submit </v-btn>
          <v-btn color="error  white--text" @click="clear">Reset</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  created() {},
  props: ["dialogStatus"],
  data: () => ({
    dialog: false,
    editedStatus: false,
    slotData: {},
    validationErrors: {} // Store field-specific errors
  }),
  computed: {
    getDegrees() {
      return this.$store.getters.getAllDegree;
    },
    getDegreeGroup() {
      return this.$store.getters.getAllDegreeGroup;
    },
    getSubject() {
      return this.$store.getters.getAllSubject;
    },
    getLoggedInExam() {
      return this.$store.getters.getLoggedInExam;
    }
  },
  methods: {
    degreeGroupData(degree_code) {
      if (degree_code) {
        this.$store.dispatch("fetchAllDegreeGroupData", {
          params: {
            degree_code: degree_code,
            degree_group_code: this.getLoggedInExam.degree_group_code
          }
        });
        this.$store.dispatch("fetchAllExamData", degree_code);
      }
    },
    getSubjectData(degree_group_code) {
      this.$store.dispatch("fetchAllSubjectData", {
        params: { degree_group_code: degree_group_code }
      });
    },
    save() {
      let self = this;
      let item = this.slotData;
      this.validationErrors = {}; // Clear existing errors
      this.$store
        .dispatch("storeRegDataInsertSlot", item)
        .then(function() {
          try {
            self.close();
            self.$parent.$parent.getDataFromApi();
          } catch (ex) {
            console.log(ex);
          }
        })
        .catch(function(error) {
          if (error) {
            self.validationErrors = error; // Set validation errors
          }
        });
    },

    clear() {
      this.slotData = {};
    },
    close() {
      this.$emit("update:dialogStatus", false);
      this.clear();
    }
  },

  watch: {
    dialogStatus(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (!val) this.close();
    }
  },

  mounted() {
    // this.$store.dispatch("fetchAllDegreeData",{degree_code:this.getLoggedInExam.exam_code});
  }
};
</script>

<style scoped>
.v-messages__message {
  color: red !important;
}
.custom-placeholer-color input,
.custom-label-color input {
  color: red !important;
}
.is-invalid {
  border-color: red;
}
.text-danger {
  color: red;
  font-size: 0.875em;
}
</style>
