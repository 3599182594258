<template>
  <v-expansion-panel focusable>
    <v-expansion-panel-content>
      <template v-slot:actions>
        <v-icon large color="primary">$vuetify.icons.expand</v-icon>
      </template>
      <template v-slot:header>
        <div>
          <v-icon color="primary">search</v-icon>
          <span class="pl-2 subheading">Custom Search Panel</span>
        </div>
      </template>
      <v-layout row wrap align-center>
        <v-flex>
          <v-card class="px-3">
            <v-layout row wrap align-center class="py-3">
              <v-text-field
                v-model="searchParam.reg_no"
                class="mt-3 ml-2"
                name="rg_no"
                label="Registration Number"
                prepend-icon="edit"
                v-validate="'required'"
                data-vv-name="registration_number"                           
                :error-messages="errors.collect('registration_number')"
              ></v-text-field>
              <v-flex xs12 sm12 md12 style="margin-top:40px;">
                <v-layout justify-center row fill-height align-center>
                  <v-btn color="primary" large @click="submit"
                    >Search</v-btn
                  >
                  <v-btn color="error" large @click="resetInputData"
                    >Reset</v-btn
                  >
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import moment from "moment";

export default {
  $_veevalidate:{
    validator: 'new'
  },
  data: () => ({
    searchParam: {},
  }),

  computed: {
  },

  created() {
  },

  methods: {
    submit(){
      this.$validator.validateAll().then(isValid => {
        if(isValid){
          this.searchStudentInfo();
        }
      })
    },
    searchStudentInfo() {
      this.loading = true;
      let self = this;
      this.$store
        .dispatch("fetchStudentInfo", this.searchParam)
        .then(data => {
          this.loading = false;
          self.sl_count = data.from;
        });
    },
    resetInputData() {
      this.searchParam.reg_no = '';
    }
  }
};
</script>

<style scoped></style>
