<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <!-- loging -->

                <!-- <v-expansion-panel popout
                v-model="expansion">
                  <v-expansion-panel-content>
                    <template v-slot:header>
                      <div>Login</div>
                </template>-->
                <v-card class="elevation-12 rounded">
                    <v-toolbar color="primary" dark flat>
                        <v-layout justify-start>
                            <v-toolbar-title>Reset Password</v-toolbar-title>
                        </v-layout>
                        <v-img
                                justify-end
                                wrap
                                :src="require('@/assets/nu_logos/nu_logo_r.png')"
                                contain
                                height="100"
                        ></v-img>
                    </v-toolbar>
                    <v-card-text class="pa-0 pt-3 pr-4 pl-3">
                        <div class="alert info"  id="alerts" v-if="messageShow">
                            <p class="text-md-center" style="padding: 5px;color: white;">{{ message }}</p>
                        </div> 
                        <div class="alert danger" v-if="messageErrorShow">
                            <p class="text-md-center"  style="padding: 5px;color: white;">{{ message }}</p>
                        </div>
                        <v-form>
                            <v-text-field
                                    v-model="user.username"
                                    label="Username"
                                    name="username"
                                    v-validate="'required'"
                                    :error-messages="errors.collect('username')"
                                    data-vv-name="username"
                                    prepend-icon="person"
                                    type="text"
                            ></v-text-field>
                        </v-form>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-layout justify-center class="pb-2">
                            <v-btn color="error" @click="goBack">
                                <v-icon left dark>account_circle</v-icon>Back
                            </v-btn>
                            <v-btn color="primary" @click="sendEmail" v-if="buttonStatus">
                              <v-icon left dark>account_circle</v-icon>{{ buttonText }}
                            </v-btn>
                            <v-btn color="primary" v-else>
                              <v-progress-circular
                                :width="3"
                                color="white"
                                indeterminate
                                style="width:20px"
                              ></v-progress-circular>
                            </v-btn>
                        </v-layout>
                    </v-card-actions>
                    <v-flex mb-4></v-flex>
                </v-card>
                <!-- </v-expansion-panel-content>
                </v-expansion-panel>-->

                <!-- <v-layout justify-center>
                  <v-btn color="primary" outline @click="goStart">Start</v-btn>
                </v-layout>-->
            </v-flex>
        </v-layout>
    </v-container>
</template>



<script>
  export default {
    $_veeValidate: {
      validator: "new"
    },
    data() {
      return {
        expansion: true,
        messageShow:false,
        messageErrorShow: false,
        message:'',
        buttonStatus:true,
        buttonText: 'SEND LINK',
        user: {
          username : ""
        }
      };
    },
    components: {},
    methods: {
      goBack() {
        this.$router.push("/cu-login");
      },
      sendEmail(){
        this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.buttonStatus = false;
          this.$store.dispatch("sendCollegeUserEmail", this.user).then((response) => {
            if (response.data.status == 'success') {
              this.buttonStatus = true;
              this.messageErrorShow = false;
              this.messageShow = true;
              this.message = response.data.message;
              this.buttonText = 'RESEND LINK';
            }
          }).catch(error => {
            if (error.response.data.status == 'failed') {
              this.buttonStatus = true;
              this.buttonText = 'RESEND LINK';
              this.messageShow = false;
              this.messageErrorShow = true;
              this.message = error.response.data.message;
            }
          });
        }});


        
      }
    },
    mounted() {
      localStorage.clear();
    }
  };
</script>

<style scoped>
    .rounded {
        border-radius: 15px;
    }
    .danger{
      background-color: red;
    }
</style>
