<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="490">
      <template v-slot:activator="{ on }">
        <v-btn fab small color="primary" v-on="on" :disabled="ifCenterUser">
          <v-icon @click="transferCenter(props.item)" class="mr-0" color="white"
            >send</v-icon
          >
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline">
          Transfer Invigilator
          <v-spacer></v-spacer>
          <v-btn color="red" fab small dark @click="clearData()"
            ><v-icon>close</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-layout wrap align-center>
            <v-flex xs12 sm12>
              <span> <b>Name: </b> </span>{{ item.name }} <br />
              <span><b>email: </b> </span> {{ item.email }} <br />
              <span><b>college: </b> </span> {{ item.college_code }} -
              {{ item.college }} <br />
              <span><b>center: </b> </span> {{ item.center_id }} -
              {{ item.center }}
            </v-flex>

            <v-flex xs12 sm12 d-flex style="margin-top: 20px">
              <v-autocomplete
                v-if="!ifCenterUser"
                class="ma-2"
                v-model="searchParam.center_code"
                :item-text="
                  (item) => item.center_code + ' - ' + item.center_name
                "
                item-value="center_code"
                :items="getCenterItems"
                menu-props="auto"
                label="Select Center to Transfer"
                prepend-icon="list"
              >
                <template v-slot:selection="data">
                  <v-chip
                    close
                    @input="searchParam.center_code = ''"
                    :selected="data.selected"
                    class="chip--select-multi"
                    >{{
                      data.item.center_code + " - " + data.item.center_name
                    }}</v-chip
                  >
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-tile-content
                      v-text="data.item"
                    ></v-list-tile-content>
                  </template>
                  <template v-else>
                    <v-list-tile-content>
                      <v-list-tile-title
                        v-html="
                          data.item.center_code + ' - ' + data.item.center_name
                        "
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            small
            @click="transferCenter()"
            style="margin-left: 39%"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import common_login_getters from "@/mixin/common_login_getters";
export default {
  mixins: [common_login_getters],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      searchParam: {},
      selectedCenter: {},
    };
  },
  computed: {
    getCenterItems() {
      return this.$store.getters.getTheoryCenterList;
    },
  },
  methods: {
    transferCenter() {
      if (this.searchParam.center_code) {
        this.selectedCenter = this.getCenterItems.find(
          (item) => item.center_code === this.searchParam.center_code
        );
        let peram = {};
        peram.id = this.item.id;
        peram.center_code = this.selectedCenter.center_code;
        peram.center_college_code = this.selectedCenter.center_college_code;
        console.log("Tanvir! this is center data", peram);
        //   this.dialog = true;
        this.$store.dispatch("transferInv", peram).then((data) => {
          this.dialog = true;
        });
      }else{
        console.log("Tanvir! Need to select center.");
      }
    },

    clearData(){
        this.searchParam.center_code = ''
        this.dialog = false;
    },
  },
};
</script>

<style>
</style>