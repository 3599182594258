import Axios from 'axios'
import {
    ExamReceivedItemList
} from '../../api'
import {
    GET_EXAM_STORE_RECEVIED_ITEM_LIST,
    GER_ITEM_RECEIVED_MESSAGE
} from '../../mutation-types'


const state = {
    examReceiveitems: [],
    itemReveivemess: {}
};

const getters = {
    getExaMRexeivedItemsList(state) {
        return state.examReceiveitems;
    },
    getExaMRexeivedItemsMess(state) {
        return state.itemReveivemess;
    }
};

const mutations = {
    [GET_EXAM_STORE_RECEVIED_ITEM_LIST](state, response) {
        state.examReceiveitems = response;
    },
    [GER_ITEM_RECEIVED_MESSAGE](state, response) {
        state.itemReveivemess = response;
    }
};

const actions = {
    fetchExamStoreReceivedItemList({
        commit
    }, searchParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary",
            });
            Axios.get(ExamReceivedItemList)
                .then((response) => {
                    commit("UNSET_LOADING");
                    var receivedList = response.data.data.data;
                    commit("GET_EXAM_STORE_RECEVIED_ITEM_LIST", receivedList);
                    resolve();
                })
                .catch((error)=>{
                    commit("UNSET_LOADING");
                    reject();
                  })
                })
        },

            postExamStoreReceivedItemListItem({
                commit
            }, itemListObject) {
                return Axios.post(ExamReceivedItemList, itemListObject)
                    .then(function (response) {
                        var mass = response.data;
                        commit("GER_ITEM_RECEIVED_MESSAGE", mass);
                        return mass;
                    })
                },
            };


    export default {
        state,
        getters,
        actions,
        mutations
    }