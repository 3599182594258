<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark fab small v-on="on"
          ><v-icon dark>edit</v-icon></v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field label="Current Paper code" v-bind:value="tableData.pap_id" disabled></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="commonData.new_paper_code"
                  label="New Paper Code"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="commonData.h_tims_id"
                  label="Head Examiner TMIS Id*"
                  hint="New paper Head Examiner TMIS Id"
                  persistent-hint
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="dialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" flat @click="changeExaminerPaper()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  props: {
    commonData: Object,
    tableData: Object,
  },
  data() {
    return {
      dialog: false,
    };
  },
  created(){
    this.commonData.old_paper_code = this.tableData.pap_id;
  },
  methods:{
    changeExaminerPaper(){
      console.log("Tanvir! This is examiner paper change data", this.commonData);
      this.dialog = true;
      // changeExaminerPapaerData
    }
  },
};
</script>
<style></style>
