import Axios from "axios";
import {
  GET_CMT_REGISTER_DATA,
  GET_CMT_REGISTER_DATA_GENERATE,
  GET_CMT_REGISTER_DATA_TRANSCRIPT,
  GET_CMT_REGISTER_DATA_CERTIFICATE,
  GET_CMT_REGISTER_DATA_MARKSHEET,


  GET_CMT_REGISTER_DATA_TRANSCRIPT_LIST,


  GET_CMT_REGISTER_DATA_CERTIFICATE_LIST,
  GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_SL,
  GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_REG,

  GET_CMT_REGISTER_DATA_MARKSHEET_LIST,
} from "../../mutation-types";
import {
  cmtRegisterData,
  cmtRegisterGenerate,
  certificatePrint,
  transcriptPrint,
  markSheetPrint,
} from "../../api";

const state = {
  listDataCmt: [],
  cmtGenerate: [],
  certificate: [],
  transcript: [],
  markSheet: [],

  certificatePrintData: [],
  certificatePrintDataSL: [],
  certificatePrintDataReg:[],
};

const getters = {
  generateCmtRegisterData() {
    return state.cmtGenerate;
  },

  getCmtRegisterData() {
    return state.listDataCmt;
  },

  getCertificateRegisterData() {
    return state.certificate;
  },

  getTranscriptRegisterData() {
    return state.transcript;
  },

  getMarkSheetRegisterData() {
    return state.markSheet;
  },

  getCertificateRegisterPrintData() {
    return state.certificatePrintData;
  },

  getCertificateRegisterPrintDataSl() {
    return state.certificatePrintDataSL;
  },

  getCertificateRegisterPrintDataReg() {
    return state.certificatePrintDataReg;
  },
};

const mutations = {
  [GET_CMT_REGISTER_DATA_GENERATE](state, result) {
    state.listDataCmt = result;
  },

  [GET_CMT_REGISTER_DATA](state, result) {
    state.listDataCmt = result;
  },

  [GET_CMT_REGISTER_DATA_TRANSCRIPT](state, result) {
    state.transcript = result;
  },
  [GET_CMT_REGISTER_DATA_CERTIFICATE](state, result) {
    state.certificate = result;
  },
  [GET_CMT_REGISTER_DATA_MARKSHEET](state, result) {
    state.markSheet = result;
  },

  [GET_CMT_REGISTER_DATA_CERTIFICATE_LIST](state, result) {
    state.certificatePrintData = result;
  },
  [GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_SL](state, result) {
    state.certificatePrintDataSL = result;
  },
  [GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_REG](state, result) {
    state.certificatePrintDataReg = result;
  },
};

const actions = {
  generateCMTregisterDataList({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      Axios.get(cmtRegisterGenerate, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          //   var result = response.data.data;
          //   console.log("Tanvir, this is generate Register data->", result);
          //   commit("GET_CMT_REGISTER_DATA_GENERATE", result);
          //   resolve(response.data.data);
          if (response.data.status == "success") {
            console.log("Tanvir, Admit Approve successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green",
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000,
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message,
            });
            reject(response.data.message);
          }
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },
  fetchCmtRegisterData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      Axios.get(cmtRegisterData, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing
          var result = response.data.data;
          console.log("Tanvir, this is CMT Register data->", result);
          commit("GET_CMT_REGISTER_DATA", result);
          resolve(response.data.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },

  fetchTranscriptRegisterData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      if (searchParam.is_count == "1") {
        Axios.get(transcriptPrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data;
            console.log("Tanvir, this is TRANSCRIPT Register data->", result);
            commit("GET_CMT_REGISTER_DATA_TRANSCRIPT", result);
            resolve(response.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      } else {
        Axios.get(transcriptPrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data.data;
            console.log("Tanvir, this is TRANSCRIPT Register data->", result);
            commit("GET_CMT_REGISTER_DATA_TRANSCRIPT", result);
            resolve(response.data.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      }
    });
  },

  fetchCertificateRegisterData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      if (searchParam.is_count == "1") {
        Axios.get(certificatePrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data;
            console.log("Tanvir, this is CERTIFICATE Register data->", result);
            commit("GET_CMT_REGISTER_DATA_CERTIFICATE", result);
            resolve(response.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      } else {
        Axios.get(certificatePrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data.data;
            console.log("Tanvir, this is CERTIFICATE Register data->", result);
            commit("GET_CMT_REGISTER_DATA_CERTIFICATE", result);
            resolve(response.data.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      }
    });
  },

  fetchCertificateRegisterPrintData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      if(searchParam.college_code_start  && searchParam.college_code_end){
        Axios.get(certificatePrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data.data;
            console.log("Tanvir, this is CERTIFICATE Register data->", result);
            commit("GET_CMT_REGISTER_DATA_CERTIFICATE_LIST", result);
            resolve(response.data.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      }else if(searchParam.com_start  && searchParam.com_end){
        Axios.get(certificatePrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data.data;
            console.log("Tanvir, this is CERTIFICATE Register data->", result);
            commit("GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_SL", result);
            resolve(response.data.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      }else if(searchParam.exam_roll_no  || searchParam.reg_no){
        Axios.get(certificatePrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data.data;
            console.log("Tanvir, this is CERTIFICATE Register data->", result);
            commit("GET_CMT_REGISTER_DATA_CERTIFICATE_LIST_REG", result);
            resolve(response.data.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      }
      
    });
  },

  fetchMarkSheetRegisterData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      if (searchParam.is_count == "1") {
        Axios.get(markSheetPrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data;
            console.log("Tanvir, this is MARKSHEET Register data->", result);
            commit("GET_CMT_REGISTER_DATA_MARKSHEET", result);
            resolve(response.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      } else {
        Axios.get(markSheetPrint, {
          params: searchParam,
        })
          .then(function (response) {
            commit("UNSET_LOADING"); // loading Closing
            var result = response.data.data;
            console.log("Tanvir, this is MARKSHEET Register data->", result);
            commit("GET_CMT_REGISTER_DATA_MARKSHEET", result);
            resolve(response.data.data);
          })
          .catch(function (error) {
            commit("UNSET_LOADING"); // loading Closing
            console.log(error);
            reject();
          });
      }
    });
  },

  postCMTregisterData({ commit }, peram) {
    console.log("This is from api", peram);
    commit("SET_ACTION_PROCESS");
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "Primary",
      });
      const apiEndpoint = `/${peram.id}?exam_code=${peram.exam_code}&exam_year=${peram.year}&sl_start=${peram.sl_start}&sl_end=${peram.sl_end}&rej_sl=${peram.rej_sl}&_method=PUT`;
      const apiUrl = cmtRegisterData + apiEndpoint;
      Axios.put(apiUrl)
        .then((response) => {
          commit("UNSET_LOADING");
          if (response.data.status == "success") {
            console.log("Tanvir, CMT register seved successfully!!");
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green",
            });
            commit("UNSET_FORMNOTIFY");
            resolve(response.data.message);
          } else {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "red",
              timeout: 10000,
            });
            commit("UNSET_FORMNOTIFY", {
              type: "error",
              msg: response.data.message,
            });
            reject(response.data.message);
          }
        })
        .catch((error) => {
          commit("UNSET_LOADING");
          commit("SET_SNACKBAR", {
            msg: "Subject assigne failed. Please try again !!!",
            color: "red",
          });
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
