<template>
  <v-container fluid>
    <div>
      <v-container fluid>
        <v-card color="white">
          <formDialog :dialogStatus.sync="formDialogStatus"></formDialog>
          <br />
          <v-layout row>
            <v-card flat>
              <v-badge left overlap>
                <template v-slot:badge>
                  <v-btn
                    round
                    color="primary"
                    fab
                    small
                    style="margin-top: -1.3em"
                    class="elevation-0"
                    v-if="getSchema.length > 0"
                    >{{ getSchema.length }}</v-btn
                  >
                </template>
                <v-card-text class="title">Slot List</v-card-text>
              </v-badge>
            </v-card>

            <v-text-field
              v-model="search"
              prepend-inner-icon="search"
              label="Search by table name"
              class="mt-0"
              hide-details
              solo
              flat
              single-line
            ></v-text-field>

            <v-btn color="primary" dark class="mb-2" @click="newItem"
              >New Item</v-btn
            >
            <v-btn small fab @click="getDataFromApi">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-layout>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="getSchema"
          class="elevation-1"
          :total-items="pagination.total"
          :pagination.sync="pagination"
          :loading="loading"
          :rows-per-page-items="[50, 100, 500]"
        >
          <template v-slot:items="props">
            <td class="text-xs-center">{{ props.index + 1 }}</td>
            <td class="text-xs-left">{{ props.item.degree_name }}</td>
            <td class="text-xs-left">{{ props.item.degree_group_name }}</td>
            <td class="text-xs-left">{{ props.item.subject_name }}</td>
            <td class="text-xs-left">{{ props.item.inst_no }}</td>
            <td class="text-xs-left">{{ props.item.table_name }}</td>
            <td class="text-xs-left">{{ props.item.total_students }}</td>
            <td class="text-xs-left">{{ props.item.remarks }}</td>
            <td class="text-xs-left">{{ props.item.added_by }}</td>
            <td class="text-xs-left">{{ props.item.added_on }}</td>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-container>
    </div>
  </v-container>
</template>
<script>
import formDialog from "@/components/registration/regDataInsertSlot.vue";
export default {
  components: {
    formDialog
  },
  data: () => ({
    detailDialog: false,
    studentDetail: "",
    pagination: {
      ascending: true,
      rowsPerPage: 50
    },
    formDialogStatus: false,
    loading: false,
    searchParam: {},
    search: "",

    headers: [
      { text: "Sl.no", align: "left", value: "" },
      { text: "Degree", align: "left", value: "" },
      { text: "Degree Group", align: "left", value: "" },
      { text: "Subject", align: "left", value: "" },
      { text: "Inst No.", align: "left", value: "" },
      { text: "Table Nme", align: "left", value: "" },
      { text: "Student Count", align: "left", value: "" },
      { text: "Remarks", align: "left", value: "" },
      { text: "Added By", align: "left", value: "" },
      { text: "Added On", align: "left", value: "" }
    ]
  }),

  computed: {
    getSchema() {
      return this.$store.getters.getRegDataSlot;
    },
    getLoggedInDegree() {
      return this.$store.getters.getLoggedInDegree;
    }
  },

  created() {
    this.$store.dispatch("fetchRegDataSlotList");
  },

  watch: {
    pagination() {
      this.getDataFromApi();
    },
    search: function(newQuery) {
      this.getDataFromApi(newQuery); // Call the debounced function
    }
  },

  methods: {
    setLimit() {
      const { sortBy, page, rowsPerPage } = this.pagination;
      this.searchParam.page = page;
      this.searchParam.limit = rowsPerPage;
      this.searchParam.sort = sortBy;
      this.searchParam.search = this.search;
    },
    getDataFromApi() {
      this.setLimit();
      this.loading = true;
      this.$store
        .dispatch("fetchRegDataSlotList", this.searchParam)
        .then(data => {
          // console.log("data.meta.total", data);
          this.loading = false;
          this.pagination.totalItems = data.meta.total;
        });
    },
    dialogOpen() {
      this.formDialogStatus = true;
    },
    newItem() {
      this.$store.dispatch("fetchAllDegreeData", {
        params: { degree_code: this.getLoggedInDegree.code }
      });
      this.dialogOpen();
    }
  }
};
</script>

<style scoped>
td {
  white-space: nowrap;
}
</style>
