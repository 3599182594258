<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          small
          color="#B71C1C"
          dark
          v-on="on"
          @click="examSubPepWiseAbcList(center_code, ex_date)"
          ><v-icon style="font-size:22px;">visibility</v-icon></v-btn
        >
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title
            >Absent Examinee List of ( {{ center_code }} - {{ center_name }} )
            for {{ getLoggedInExam.code }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              fab
              class="ma-1 pa-0"
              small
              color="red darken-3 white--text"
              @click="dialog = false; modalOpen=false"
              ><v-icon>close</v-icon></v-btn
            >
          </v-toolbar-items>
        </v-toolbar>
        <v-card
          style="
            padding-top: 40px;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 80px;
          "
        >
          <v-layout row>
            <v-card flat>
              <v-badge left overlap>
                <template v-slot:badge>
                  <v-btn
                    round
                    color="primary"
                    fab
                    small
                    style="margin-top: -1.3em"
                    class="elevation-0"
                    v-if="getAbsentExamineeListPeparWiseAdmin.total"
                    >{{ getAbsentExamineeListPeparWiseAdmin.total }}</v-btn
                  >
                </template>
                <!-- <v-card style="border-top:1px solid #000;" class="title">Absent Examinee List</v-card> -->
              </v-badge>
            </v-card>
          </v-layout>
          <v-data-table
            :loading="loading"
            :headers="Absentheaders"
            :items="getAbsentExamineeListPeparWiseAdmin.data"
            :pagination.sync="pagination"
            :rows-per-page-items="pagination.rowsPerPageItems"
            :total-items="getAbsentExamineeListPeparWiseAdmin.total"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td class="text-xs-center">{{props.index+1}}</td>
              <td>{{ props.item.student_name }}</td>
              <td class="text-xs-center">
                {{ props.item.exam_roll_no }}
              </td>
              <td class="text-xs-center">{{ props.item.reg_no }}</td>
              <td class="text-xs-center">
                {{ props.item.subject_code }} -
                {{ props.item.subject_name }}
              </td>
              <td class="text-xs-center">
                {{ props.item.paper_code }} -
                {{ props.item.paper_name }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import common_getters_mixin from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
// import common_exam_lg from "../../mixin/common_login_getters.vue";
export default {
  mixins: [common_getters_mixin, commonExamYear],
  props: ["total_absent", "center_code", "center_name", "ex_date"],
  data() {
    return {
      modalOpen:false,
      loading: false,
      Absentheaders: [
        { text: "SL", align: "center", value: "rn" },
        { text: "Examinee Name", align: "center", value: "student_name" },
        { text: "Exam Roll No", align: "center", value: "exam_roll_no" },
        { text: "Registration No", align: "center", value: "reg_no" },
        { text: "Subject Code - Name", align: "center", value: "subject_name" },
        { text: "Pepar Code - Name", align: "center", value: "paper_name" },
      ],
      AllFetchData: {},
      examperamdata: {
        center_Code: "",
        exam_date: "",
      },
      total_Absent: "",
      center_Code: "",
      exam_date: "",
      pagination: {
        page: 1,
        rowsPerPage: 50, // -1 for All",
        totalItems: 200,
        rowsPerPageItems: [50, 100, 150, { text: "All", value: -1 }],
      },
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },

  created() {},

  computed: {
    getAbsentExamineeListPeparWiseAdmin() {
      return this.$store.getters.peparWiseAbsentExamineeList
        ? this.$store.getters.peparWiseAbsentExamineeList
        : [];
    },
  },

  watch: {
    pagination(cv) {
      console.log("gugaga", cv);
      console.log("junjhuni", this.modalOpen)
      if(this.modalOpen){
        this.viewAbsentListPeparWiseAdmin();
      }
    },
  },

  methods: {
    viewAbsentListPeparWiseAdmin() {
      //console.log('Tanvir, This is Subject and Paper code-->', subjectCode , paperCode);
      this.modalOpen= true;
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.center_code = this.examperamdata.center_Code;
      peram.attendance_date = this.examperamdata.exam_date;
      peram.limit = this.pagination.rowsPerPage;
      peram.page = this.pagination.page;
      peram.download = 0;
      console.log("Tanvir, This is Pepar wise absent list peram-->", peram);
      this.loading = true;
      this.$store
        .dispatch("fetchExamineeabsentListPeparwiseAdmin", peram)
        .then(() => {
          this.loading = false;
        });
    },


    examSubPepWiseAbcList(center_Code, exam_date) {
      this.examperamdata = { center_Code, exam_date };
      console.log("This in next page---->", center_Code, exam_date);
      this.viewAbsentListPeparWiseAdmin();
    },
  },
};
</script>

<style></style>
