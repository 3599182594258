<template>
  <v-dialog v-model="dialog" persistent :max-width="options.width">
    <v-card style="border-radius:10px">
      <v-toolbar dark :color="options.color" class="my-tool pa-1" style flat>
        <v-spacer></v-spacer>
        <v-icon x-large style="font-size: 50px;">{{ options.icon }}</v-icon>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="title pa-4 text-xs-center">Are you sure that you want to process Payment Installment?</v-card-text>
      
      <!-- <v-form>
        <v-card-text>
          <v-checkbox
            v-model="cancel_previous_application"
            value="1"
            label="Cancel previous application which aren't confirmed yet"
            type="checkbox"
            color="indigo darken-3" success
          ></v-checkbox>
        </v-card-text>
      </v-form> -->
      
      <v-divider></v-divider>
      <v-card-actions class="pt-0 pb-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" flat text @click="agree">
          <div class="title text-xs-center">Yes</div>
        </v-btn>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="primary" text flat large @click="cancel">
          <div class="title text-xs-center">Cancel</div>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialogStatus","item"],
  data: () => ({
    dialog: false,
    cancel_previous_application: 0,
    item:{},
    options: {
      color: "primary",
      width: 490,
      icon: "warning",
      height: 500
    }
  }),

  watch: {
    dialogStatus(val) {
      this.cancel_previous_application = 0;
      this.dialog = val;
    },
    dialog(val) {
      if (!val) this.cancel();
    },
    item(item){
      this.item = item;
    }
  },
  methods: {
    agree() {
      this.dialog = false;
      var cancel_previous_application = { cancel_status: this.cancel_previous_application };
      this.$store.dispatch("processPaymentInstallment", Object.assign({}, this.item, cancel_previous_application));
    },
    cancel() {
      this.dialog = false;
      this.$emit("update:dialogStatus", false);
    }
  }
};
</script>
<style lang="scss">
.my-tool {
  .v-toolbar__content {
    padding: 0 0px;
  }
}
</style>
