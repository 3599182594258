<template>
  <div id="ExamWiseResultForCollege">
    <v-container>
      <resultDetails
      :dialogStatus.sync="detailsDialog"
      :detailData="detailData"
    ></resultDetails>

      <v-card color="white">
        <v-card-text class="title">Exam Wise Result</v-card-text>
      </v-card>
      <v-layout row wrap align-center>
        <v-flex>
          <v-card class="px-3">
            <CustomSearchForm :form="search"></CustomSearchForm>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap align-center>
        <v-flex>
          <v-card class="px-3">
            <v-card color="white">
              <v-layout row>
                <v-card flat>
                  <v-badge left overlap color="transparent">
                    <template v-slot:badge>
                      <v-btn
                        round
                        color="primary"
                        small
                        style="margin-top: -0.8em"
                        class="elevation-0"
                        v-if="pagination.totalItems"
                        >{{ pagination.totalItems }}</v-btn
                      >
                    </template>
                    <v-card-text class="title text-uppercase"
                      >Result List</v-card-text
                    >
                  </v-badge>
                </v-card>
                <v-divider class="my-2" vertical></v-divider>
                <v-spacer class="ml-5"></v-spacer>
                <v-card-actions>
                  <v-btn
                    v-if="resultStatus"
                    @click="downloadResult"
                    color="primary"
                    dark
                    >Download Result</v-btn
                  >
                </v-card-actions>
              </v-layout>
            </v-card>
            <v-spacer class="ml-5"></v-spacer>
            <v-data-table
              v-if="resultStatus"
              :headers="formResultListHeaders"
              :items="examWiseResultsForCollege"
              class="elevation-1"
              :total-items="pagination.totalItems"
              :pagination.sync="pagination"
              :rows-per-page-items="pagination.rowsPerPageItems"
              style="white-space: nowrap"
            >
              <template v-slot:items="props">
                <td class="text-xs-left">{{ sl_count + props.index }}</td>
                <td class="text-xs-left">
                  {{ props.item.subject_code }} -
                  {{ props.item.subject_name }}
                </td>
                <td class="text-xs">{{ props.item.exam_roll }}</td>
                <td class="text-xs">{{ props.item.reg_no }}</td>
                <td class="text-xs">{{ props.item.std_name }}</td>
                <td class="text-xs">{{ props.item.result_type_name }}</td>
                <td class="text-xs-center">
                  <v-btn
                    outline
                    color="info"
                    @click="showResultDetails(props.item)"
                  >
                    Result Details
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import CustomSearchForm from "@/components/collegePanel/CustomSearchForm.vue";
import resultDetails from "@/components/common/resultDetails.vue";

export default {
  created() {
    this.ExamWiseResultTableStatus = false;
    this.$store.commit("UN_SET_COLLEGE_EXM_WISE_RESULT");
  },
  components: {
    CustomSearchForm,
    resultDetails
  },
  computed: {
    examWiseResultsForCollege() {
      return this.$store.getters.examWiseResultsForCollege;
    },
    formResultListHeaders() {
      return [
        {
          text: "SL",
          value: "sl"
        },
        {
          text: "Subject",
          value: "subject_code"
        },
        {
          text: "Exam Roll",
          value: "exam_roll_no"
        },
        {
          text: "Registration No",
          value: "reg_no"
        },
        {
          text: "Examinee Name",
          value: "student_name"
        },
        {
          text: "Result Type",
          value: "result_type_name"
        },
        {
          text: "Course Details",
          value: "details",
          align: "center"
        }
      ];
    }
  },
  watch: {
    pagination() {
      this.submit();
    }
  },
  data() {
    return {
      detailsDialog: false,
      detailData: {},
      resultStatus: false,
      sl_count: 1,
      loading: false,
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 20, 30, 40, 50, 100, 500]
      },
      search: {
        data: {},
        show: {
          exam_code: true,
          degree_code: false,
          exam_year: true,
          degree_group: true,
          subject: false,
          reg_no: true
        },
        validation: {
          exam_code: {
            required: true
          },
          degree_code: {
            required: true
          },
          degree_group: {
            required: true
          },
          fee_title: {
            required: true,
            numeric: true,
            min: 6,
            max: 10
          },
          exam_year: {
            required: true
          },
          subject: {
            required: true
          }
        }
      }
    };
  },
  methods: {
    setLimit() {
      const { sortBy, page, rowsPerPage } = this.pagination;
      this.search.data.page = page;
      this.search.data.limit = rowsPerPage;
      this.search.data.sort = sortBy;
    },
    submit() {
      this.setLimit();
      this.loading = true;
      this.search.data.display_type = "display_data";
      this.$store
        .dispatch("examWiseResultsForCollege", {
          params: this.search.data
        })
        .then(data => {
          this.resultStatus = true;
          this.loading = false;
          this.sl_count = data.meta.from;
          this.pagination.totalItems = data.meta.total;
        });
    },
    showResultDetails(data) {
      console.log(data);
      this.detailData.exam_code = data.exam_code;
      this.detailData.exam_year = data.exam_year;
      this.detailData.exam_display_name =
        data.exam_name + " Examination " + "-" + data.exam_year;
      this.detailData.student_name = data.std_name;
      this.detailData.student_father_name = data.father_name;
      this.detailData.student_mother_name = data.mother_name;
      this.detailData.college_display_name =
        "[" +
        data.form_fillup_info.college.college_code +
        "] " +
        data.form_fillup_info.college.college_name +
        ", " +
        data.form_fillup_info.college.districts.district_name;

      this.detailData.exam_roll = data.exam_roll;
      this.detailData.reg_no = data.reg_no;
      this.detailData.student_degree_code = data.degree_code;
      this.detailData.ac_session = data.ac_session;
      this.detailData.student_type = data.student_type;
      this.detailData.result_type = data.result_type;
      this.detailData.result_type_name = data.result_type_name;
      this.detailData.course_grade_title = data.course_grade_title;

      const result_details = [];
      data.combine_result_details.forEach(paper => {
        result_details.push({
          paper_code: paper.paper_code,
          paper_name: paper.paper.paper_name,
          credit: paper.credit,
          letter_grade: paper.letter_grade
        });
      });

      this.detailData.result_details = result_details;
      this.detailsDialog = true;
    },
    downloadResult() {
      this.search.data.display_type = "download_pdf";
      this.$store.dispatch("colAndExamWiseResultDownload", {
        params: this.search.data,
        responseType: "blob"
      });
    }
  }
};
</script>
