<template>
  <div id="PaymentSlip">
    <v-container fluid>
      <v-expansion-panel v-model="panel" expand>
        <v-expansion-panel-content class="mycustomExpansionPanel">
          <template v-slot:actions>
            <v-icon large color="primary">$vuetify.icons.expand</v-icon>
          </template>
          <template v-slot:header>
            <div>
              <v-icon color="primary">search</v-icon>
              <span class="pl-2 subheading">Custom Search Panel</span>
            </div>
          </template>
          <v-layout row wrap align-center>
            <v-flex>
              <v-card class="px-3">
                <v-layout row wrap align-center class="py-3">
                  <v-flex md6>
                    <v-autocomplete
                      v-model="getSchema.exam_initiate_id"
                      :items="getExamMasterList"
                      item-text="exam_display_name"
                      item-value="id"
                      label="Select Exam"
                      data-vv-name="exam_name"
                      :error-messages="errors.collect('id')"
                      required
                      prepend-icon="subject"
                    ></v-autocomplete>
                  </v-flex>
                  <!-- <v-flex md3>
                    <v-select
                      v-model="getProbableSchema.degree_group_code"
                      :items="getCollegeDegreeWiseDegreeGroup"
                      item-text="degree_group_name"
                      item-value="degree_group_code"
                      label="Select Degree Group"
                      data-vv-name="degree_group_code"
                      :error-messages="errors.collect('degree_group_code')"
                      required
                      prepend-icon="subject"
                    ></v-select>
                  </v-flex>
                  <v-flex md3 v-if="getProbableSchema.degree_code!=1">
                    <v-select
                      v-model="getProbableSchema.subject_code"
                      :items="getCollegeDegreeGroupWiseSubject"
                      item-text="subject_name"
                      item-value="subject_code"
                      label="Select Subject"
                      data-vv-name="subject_code"
                      :error-messages="errors.collect('subject_code')"
                      required
                      prepend-icon="subject"
                    ></v-select>
                  </v-flex>-->

                  <v-flex xs12 sm12 md12 style="margin-top:40px;">
                    <v-layout justify-center row fill-height align-center>
                      <v-btn color="primary" large @click="search()"
                        >Search</v-btn
                      >
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>
    <v-container fluid v-if="getCollegePaymentSlip.hasOwnProperty('all_std_fee')">
      <v-card color="white">
        <v-layout row>
          <v-card flat>
            <v-card-text class="title">Fee Summary</v-card-text>
          </v-card>
          <!-- <v-divider class="my-2" vertical></v-divider> -->
          <!-- <v-text-field
            v-model="search"
            prepend-inner-icon="search"
            label="Search"
            class="mt-0"
            hide-details
            solo
            flat
            single-line
          ></v-text-field>-->
          <v-spacer />
          <!-- <v-btn @click="downloadPdf" color="primary" dark>
            Export As PDF
            <v-icon right dark>cloud_download</v-icon>
          </v-btn> -->
        </v-layout>
      </v-card>
      <table class="v-datatable v-table theme--light">
          <thead>
            <tr>
              <th
                role="columnheader"
                scope="col"
                width="10"
                aria-label="SL.: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                SL.
              </th>
              <th
                role="columnheader"
                scope="col"
                width="10"
                aria-label="SL.: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                Installment
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Number of Candidate(s): Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                No. of Candidate(s)
              </th>
              <!-- <th
                role="columnheader"
                scope="col"
                aria-label="Reference Id: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
                >
                Reference Id
              </th> -->
              <!-- <th
                role="columnheader"
                scope="col"
                aria-label="Payment Status: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
                >
                Payment Status
              </th> -->
              <th
                role="columnheader"
                scope="col"
                aria-label="Total: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                Total Amount
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Action: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <slot v-if="getCollegePaymentSlip.hasOwnProperty('all_std_fee')">
              <tr
                v-for="(item, index) in getCollegePaymentSlip.all_std_fee" :key="index">         
                <td>{{ index + 1 }}</td>               
                <td class="text-xs-left" style="white-space:nowrap">Installment - <span v-if="item.no_of_installment != null">{{ item.no_of_installment }}</span><span v-else-if="getCollegePaymentSlip.all_std_fee.length > 2">{{ (Number(getCollegePaymentSlip.all_std_fee[index-1].no_of_installment) + 1) }}</span><span v-else>{{ index + 1 }}</span></td>
                <td class="text-xs-left">{{ item.total_student }}</td>
                <!-- <td class="text-xs-left">{{ item.payment_reference_id }}</td> -->
                <!-- <td class="text-xs-left">
                  <span v-if="item.pms_payment_confirm == 0"> <v-chip class="ma-2" color="red" text-color="white">Unpaid</v-chip></span>
                  <span v-else> <v-chip class="ma-2" color="primary" text-color="white">Paid</v-chip>
                  </span>
                </td> -->
                <td class="text-xs-left">{{ item.fee }}</td>
                <td class="text-xs-left">
                  <!-- <v-btn small @click="" color="green" style="color:white">View</v-btn> -->
                  <v-btn small color="primary" @click="showPaymentSlip(item.payment_reference_id)">Fee Details</v-btn>
                  <v-btn small v-if="item.payment_reference_id != null" @click="downloadPaySlipPdf(item.payment_reference_id)">Download Fee Summary</v-btn>
                  <!-- <v-btn small v-else @click="callPMSApi" color="primary" dark>Generate Payment slip
                  <v-icon right dark>sync</v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
    </v-container>
  </div>
</template>
<script>
import commonGet from "@/mixin/college_common_getters_mixin";
export default {
  mixins: [commonGet],
  components: {},
  computed: {
    getSchema() {
      return this.$store.getters.collegePaymentSlipSchema;
    },
    getCollegePaymentSlip() {
      return this.$store.getters.getCollegePaymentSlip;
    }
  },
  data() {
    return {
      panel: [true]
    };
  },
  created() {
    this.$store.commit("UNSET_FEE_SUMMARY");
    this.$store.dispatch("getCollegeExamMasterList");
    // this.$store.dispatch("getCollegePaymentSlip");
  },
  methods: {
    search() {
      this.$store.dispatch("getCollegePaymentSlip");
    },
    downloadPaySlipPdf(TrxId) {
      this.$store.dispatch("collegePaymentSlipDownloadPdf", {
        params: this.getSchema,
        responseType: "blob",
        trxid: TrxId,
      });
    },
    showPaymentSlip(referenceId){
      let exam_initiate_id = this.getSchema.exam_initiate_id;
      this.$router.push(`/college/ff/details_payment_slip/${exam_initiate_id}/${referenceId}`);
    }
  },
  mounted() {
    if (!this.getSchema.exam_initiate_id && this.getExamMasterList.length > 0) {
      this.getSchema.exam_initiate_id = this.getExamMasterList["0"].id;
    }
  }
};
</script>
