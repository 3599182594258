<template>
  <v-container fluid>
    <v-dialog v-model="InstallmentDetailStatus" fullscreen>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Installment Details - {{ exam_code }} - {{ exam_year }} - Installment - {{installment_no}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark flat @click="close">Close</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card style="background-color: white;">
        <InstallmentDetails :installmentDialogStatus.sync="InstallmentDetailStatus"></InstallmentDetails>
      </v-card>
    </v-dialog>
    <div>
      <v-container fluid>
        <v-card color="white">
          <formDialog
            :dialogStatus.sync="formDialogStatus"
            :indexEdited.sync="editedIndex"
          ></formDialog>

          <formDialogConfirm
            :dialogStatus.sync="confirmFormDialogStatus"
            :item.sync="item"
          ></formDialogConfirm>

          <v-layout row>
            <v-card flat>
              <v-card-text class="title">Payment Installment</v-card-text>
            </v-card>
            <v-divider class="my-2" vertical></v-divider>
            <v-text-field
              v-model="search"
              prepend-inner-icon="search"
              label="Search"
              class="mt-0"
              hide-details
              solo
              flat
              single-line
            ></v-text-field>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="newItem"
              >New Item</v-btn
            >
            <v-btn small fab @click="getSearchApi()">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-layout>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="getSchema"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:items="props">
            <td class="justify-left  px-0">
              <div class="layout">
                <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    small
                    color="success"
                    @click="confirmDialogOpen(props.item)"
                    v-on="on"
                  >
                    <v-icon dark>send</v-icon>
                  </v-btn>
                </template>
                <span>Process</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="!props.item.rel_ff_payment_installment_details.length"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    small
                    color="primary"
                    @click="editItem(props.item)"
                    v-on="on"
                  >
                    <v-icon dark>edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
             
              
        
                <v-tooltip right v-if="props.item.rel_ff_payment_installment_details.length">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      small
                      color="blue"
                      
                      @click="installmentDetailShow(props.item)"
                      v-on="on"
                    >
                      <v-icon dark>visibility</v-icon>
                    </v-btn>
                  </template>
                  <span>Show Details</span>
                </v-tooltip>
              </div>
            </td>
            <td class="text-xs-center">{{ props.index + 1 }}</td>
            <td class="text-xs-center">{{ props.item.title }}</td>
            <td class="text-xs-center">{{ props.item.exam_code }}</td>
            <td class="text-xs-center">{{ props.item.exam_year }}</td>
            <td class="text-xs-center">{{ props.item.no_of_installment }}</td>
            <td class="text-xs-center">
              {{ props.item.ff_start_date | dateFormat }}
            </td>
            <td class="text-xs-center">
              {{ props.item.ff_end_date | dateFormat }}
            </td>
            <td class="text-xs-center">
              {{ props.item.ff_form_fill_up_count }}
            </td>
            <td class="text-xs-center">
              {{ props.item.status === "1" ? "Active" : "Inactive" }}
            </td>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </v-container>
    </div>
  </v-container>
</template>
<script>
import formDialog from "@/components/formFillup/admin/paymentInstallmentForm.vue";
import formDialogConfirm from "@/components/formFillup/admin/confirm.vue";
import InstallmentDetails from "@/components/formFillup/admin/paymentInstallmentDetails.vue";
export default {
  components: {
    formDialog,
    formDialogConfirm,
    InstallmentDetails
  },
  data: () => ({
    installment_no: '',
    exam_code: '',
    exam_year: '',
    searchParam: {},
    InstallmentDetailStatus: false,
    formDialogStatus: false,
    installmentDialogStatus:false,
    confirmFormDialogStatus:false,
    confirm:false,
    search: "",
    item:{},
    headers: [
      { text: "Actions", align: "center", value: "name", sortable: false },
      { text: "SL", align: "center", sortable: false, value: "index" },
      { text: "Title", align: "center", value: "title" },
      { text: "Exam Code", align: "center", value: "exam_code" },
      { text: "Exam Year", align: "center", value: "exam_year" },
      { text: "Inst.", align: "center", value: "no_of_installment" },
      { text: "C.C Start Date", align: "center", value: "ff_start_date" },
      { text: "C.C. Close Date", align: "center", value: "ff_end_date" },
      { text: "No. of Student", align: "center", value: "ff_form_fill_up_count" },
      { text: "status", align: "center", value: "status" },
    ],

    editedIndex: -1,
  }),

  computed: {
    getSchema() {
      return this.$store.getters.getAllPaymentInstallment;
    },
    getLoggedInExam() {
      return this.$store.getters.getLoggedInExam;
    },
    getLoggedInYear() {
      return this.$store.getters.getLoggedInYear;
    },
    getExamInitiateId() {
      return this.$store.getters.getExamInitiateId;
    },
  },

  created() {
    this.getSearchApi();
  },

  methods: {
    dialogOpen() {
      this.formDialogStatus = true;
    },
    confirmDialogOpen(item) {
      this.item = item;
      this.confirmFormDialogStatus = true;
    },
    installmentDetailShow(item){
      this.installment_no = item.no_of_installment
      this.exam_code = item.exam_code
      this.exam_year = item.exam_year
      this.$store.commit("SET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA", item.rel_ff_payment_installment_details);
      this.InstallmentDetailStatus = true; 
    },
    newItem() {
      this.editedIndex = -1;
      this.$store.commit("UNSET_PAYMENT_INSTALLMENT_FORM_SCHEMA",this.searchParam);
      this.dialogOpen();
    },
    editItem(item) {
      this.$store.commit(
        "SET_PAYMENT_INSTALLMENT_FORM_SCHEMA",
        Object.assign({}, item)
      );
      this.editedIndex = this.getSchema.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.formDialogStatus = true;
    },
    getSearchApi() {
      this.initSearchParam();
      this.$store.dispatch("getAllPaymentInstallment", this.searchParam);
    },
    initSearchParam() {
      this.searchParam.exam_code = this.getLoggedInExam.code;
      this.searchParam.exam_year = this.getLoggedInYear.year;
      this.searchParam.exam_initiate_id = this.getExamInitiateId;
    },
    close() {
      this.$store.commit("UNSET_PAYMENT_INSTALLMENT_DETAILS_SCHEMA");
      this.InstallmentDetailStatus = false;
    },
  },
  mounted() {
    this.initSearchParam();
    this.getSearchApi();
  },
};
</script>

<style scoped></style>
