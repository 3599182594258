<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn fab dark small color="green" v-on="{ ...on, ...tooltipOn }">
            <v-icon dark>start</v-icon>
          </v-btn>
        </template>
        <span>Single Probable Generate</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title style="background-color: #3f51b5; color: #fff">
        <span class="headline"
          >Single Probable Generate (Registration wise)</span
        >
        <v-spacer></v-spacer>
        <v-btn color="red" fab small dark @click="clearData()"
          ><v-icon>close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field
              class="custom-multiline-text-field"
              v-model="formattedNumber"
              label="Enter Registration Number"
              @input="formatInput"
              :error-messages="errorMessage"
              textarea
            ></v-text-field>
          </v-flex>
        </v-layout>
        <small style="color: red; font-size: 13px"
          >Enter Registration Number With
          <strong>Comma (,)</strong> separated</small
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="showBot == 1 && formattedNumber != ''"
          color="blue darken-1"
          flat
          @click="$event.preventDefault();
          $event.stopPropagation();
          
           generateProb()"
          >submits</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      number: "",
      formattedNumber: "",
      errorMessage: "",
      showBot: 1,
    };
  },
  created: {},
  computed: {},
  methods: {
    formatInput() {
      if (/[A-Za-z]/.test(this.formattedNumber)) {
        this.errorMessage = "Only numbers are accepted";
        this.showBot = 0;
        return;
      } else {
        this.errorMessage = "";
        this.showBot = 1;
      }
      const plainNumber = this.formattedNumber.replace(/,/g, "");
      const formattedValue = plainNumber.replace(/(.{11})(?!$)/g, "$1,");
      this.formattedNumber = formattedValue;
      this.number = plainNumber;
    },

    generateProb() {
      let param = {};
      param.criteria_id = this.item.id;
      param.exam_code = this.item.exam_code;
      param.exam_year = this.item.exam_year;
      param.exam_initiate_id = this.item.exam_initiate_id;
      param.reg_no = this.formattedNumber;
      console.log("Tanvir! This is reg data", param);
      this.$store.dispatch("startGenSingleProbable", param);
      this.dialog = true;
    },

    clearData() {
      this.formattedNumber = "";
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.custom-multiline-text-field .v-input__control {
  height: 160px; /* Adjust height as needed */
}

.custom-multiline-text-field .v-input__slot {
  white-space: pre-wrap; /* Maintains line breaks in the text */
}
</style>
