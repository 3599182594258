<template lang="">
  <v-container fluid>
    <v-layout row wrap align-center>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-layout row>
            <v-card-text class="title" style="margin-top: 14px"
              >C/M/T Register</v-card-text
            >
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
            <v-flex
              xs4
              sm4
              d-flex
              style="margin-top: 10px; margin-left: 24px; margin-right: 24px"
            >
              <v-btn
                color="primary"
                @click="getCMTregisterData()"
                :disabled="getcmtRegisterData.data.length > 1"
                >Generate</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
        <v-card class="px-3">
          <v-data-table
            :headers="headers"
            :items="getcmtRegisterData.data"
            class="elevation-1"
            :total-items="pagination.totalItems"
            :pagination.sync="pagination"
            :rows-per-page-items="pagination.rowsPerPageItems"
          >
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.index + 1 }}</td>
              <td class="text-xs-left" style="width: 400px">
                [{{ props.item.col_code ? props.item.col_code : "" }}] -
                {{ props.item.col_name ? props.item.col_name : "" }},
                {{ props.item.dis_name ? props.item.dis_name : "" }}
              </td>
              <td class="text-xs-left">
                [{{ props.item.sub_code ? props.item.sub_code : "" }}]
                {{ props.item.sub_name ? props.item.sub_name : "" }}
              </td>
              <td class="text-xs-center">
                {{ props.item.com_start ? props.item.com_start : "" }}
              </td>
              <td class="text-xs-center">
                {{ props.item.com_end ? props.item.com_end : "" }}
              </td>
              <td class="text-xs-center">
                {{ props.item.total_student ? props.item.total_student : "" }}
              </td>
              <td class="text-xs-left">
                <v-text-field
                  style="width: 200px"
                  v-model="props.item.sl_start"
                  label="Serial start"
                  prepend-icon="edit"
                ></v-text-field>
              </td>
              <td class="text-xs-center">
                <v-text-field
                  style="width: 200px"
                  v-model="props.item.sl_end"
                  label="Serial End"
                  prepend-icon="edit"
                ></v-text-field>
              </td>
              <td class="text-xs-center">
                <v-text-field
                  style="width: 200px"
                  v-model="props.item.rej_sl"
                  label="Reject"
                  prepend-icon="edit"
                ></v-text-field>
              </td>
              <td class="text-xs-left">
                <!-- <div style="display:inline; padding-top:24px;"> -->
                <v-btn
                  color="success"
                  @click="
                    getTranscriptRegister(
                      props.item.col_code,
                      props.item.sub_code
                    )
                  "
                  >Transcript</v-btn
                >
                <v-btn
                  color="primary"
                  @click="
                    getMarkSheetRegister(
                      props.item.col_code,
                      props.item.sub_code
                    )
                  "
                  >Marksheet</v-btn
                >
                <v-btn
                  color="warning"
                  @click="
                    getCertificateRegister(
                      props.item.col_code,
                      props.item.sub_code
                    )
                  "
                  >Certificket</v-btn
                >
                <v-btn
                  color="info"
                  @click="
                    saveCMTregister(
                      props.item.id,
                      props.item.sl_start,
                      props.item.sl_end,
                      props.item.rej_sl
                    )
                  "
                  >Save Register</v-btn
                >
                <!-- </div> -->
              </td>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="error" icon="warning">
                Sorry, nothing to display here :(
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import common_login_getters from "@/mixin/common_login_getters";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import commonGet from "../../mixin/common_getters_mixin";

export default {
  mixins: [common_login_getters, commonGet, commonExamYear],
  data() {
    return {
      headers: [
        {
          text: "SL",
          align: "center",
          sortable: false,
          value: "",
        },
        {
          text: "College",
          value: "",
          align: "center",
          sortable: false,
        },
        { text: "Subject", value: "sub_code", align: "center" },
        { text: "Com Start", value: "com_start", align: "center" },
        { text: "Com End", value: "com_end", align: "center" },
        { text: "Total", value: "total_student", align: "center" },
        { text: "From Serial", value: "sl_start", align: "center" },
        { text: "To Serial", value: "sl_end", align: "center" },
        { text: "Reject", value: "rej_sl", align: "center" },
        { text: "Action", value: "", align: "center" },
      ],
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [
          10,
          25,
          {
            text: "All",
            value: -1,
          },
        ],
      },
    };
  },

  created() {
    this.cmtRegisterData();
  },

  watch: {
    pagination() {
      this.cmtRegisterData();
    },
  },

  computed: {
    getcmtRegisterData() {
      return this.$store.getters.getCmtRegisterData;
    },
  },

  methods: {
    getCMTregisterData() {
      this.loading = true;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.exam_year = this.getLoggedInYear.year;
      this.$store
        .dispatch("generateCMTregisterDataList", param)
        .then((data) => {
          this.loading = false;
        });
    },
    cmtRegisterData() {
      this.loading = true;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.exam_year = this.getLoggedInYear.year;
      param.page = this.pagination.page;
      param.limit = this.pagination.rowsPerPage;
      
      console.log("Tanvir data of pagination search", param);
      this.$store.dispatch("fetchCmtRegisterData", param).then((data) => {
        this.loading = false;
        this.sl_count = data.meta.from;
        this.pagination.totalItems = data.meta.total;
      });
    },

    getTranscriptRegister(crt_col, sub_code) {
      this.loading = true;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      param.exam_initiate_id = this.getExamInitiateId;
      param.college_code = crt_col;
      param.subject_code = sub_code;
      this.$store
        .dispatch("fetchTranscriptRegisterData", param)
        .then((data) => {
          window.open(data);
          console.log("Tanvir data of CMT", data);
        });
    },

    getCertificateRegister(crt_col, sub_code) {
      this.loading = true;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      param.exam_initiate_id = this.getExamInitiateId;
      param.college_code = crt_col;
      param.subject_code = sub_code;
      this.$store
        .dispatch("fetchCertificateRegisterData", param)
        .then((data) => {
          window.open(data);
          console.log("Tanvir data of CMT", data);
        });
    },

    getMarkSheetRegister(crt_col, sub_code) {
      this.loading = true;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      param.exam_initiate_id = this.getExamInitiateId;
      param.college_code = crt_col;
      param.subject_code = sub_code;
      this.$store.dispatch("fetchMarkSheetRegisterData", param).then((data) => {
        window.open(data);
        console.log("Tanvir data of CMT", data);
      });
    },

    saveCMTregister(id, sl_start, sl_end, rej_sl) {
      this.loading = true;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      param.id = id;
      param.sl_start = sl_start;
      param.sl_end = sl_end;
      param.rej_sl = rej_sl;
      param._method = "put";
      console.log("Tanvir data of CMT register", param);
      this.$store.dispatch("postCMTregisterData", param).then((data) => {
        console.log("Tanvir data of CMT", data);
      });
    },
  },
};
</script>
<style lang=""></style>
