import Axios from "axios";
import {
  REG_DATA_SLOT_LIST,
  REG_STUDENT_DATA,
  REG_STUDENT_SEARCH_PARAM
} from "../../mutation-types";
import {
  RegistrationBasics,
  RegDataSlotList,
  storeRegDataInsertSlot
} from "../../api";
import { saveMsg } from "../../erpUtil";
import { failedMsg } from "../../erpUtil";

const state = {
  regDataSlotList: [],
  registerStudentList: [],
  schema: {
    std_id: "",
    reg_no: "",
    name: "",
    college_code: "",
    ac_session: "",
    degree_code: "",
    degree_group_code: "",
    subject_code: ""
  }
};

const getters = {
  getRegDataSlot(state) {
    return state.regDataSlotList;
  },
  getRegStudentData(state) {
    return state.registerStudentList;
  },
  getSearchData(state) {
    return state.schema;
  }
};

const mutations = {
  //get data from api
  [REG_DATA_SLOT_LIST](state, result) {
    state.regDataSlotList = result;
  },
  [REG_STUDENT_DATA](state, result) {
    state.registerStudentList = result;
  },
  [REG_STUDENT_SEARCH_PARAM](state, result) {
    state.schema = result;
  }
};

const actions = {
  fetchRegDataSlotList({ commit }, searchParam) {
    return new Promise(function(resolve, reject) {
      // Check if the 'search' key exists and has a value
      const isSearch = searchParam && searchParam.search;
      // Only show loading if it's not a search operation
      if (!isSearch) {
        commit("SET_LOADING", { color: "primary" });
      }
      Axios.get(RegDataSlotList, { params: searchParam })
        .then(function(response) {
          commit("UNSET_LOADING");
          let result = response.data.data;
          commit("REG_DATA_SLOT_LIST", result);
          resolve(response.data);
        })
        .catch(function(error) {
          console.log(error);
          reject();
        });
    });
  },
  storeRegDataInsertSlot({ commit }, schema) {
    let data = schema;
    return new Promise((resolve, reject) => {
      Axios.post(storeRegDataInsertSlot, data)
        .then(function(response) {
          console.log("the response comes", response);
          commit("SET_LOADING", { color: "primary" });
          if (response.data.code === 200) {
            commit("SET_SNACKBAR", {
              msg: response.data.message,
              color: "green"
            });
            commit("UNSET_LOADING"); // loading Closing
            resolve();
          } else {
            commit("UNSET_LOADING"); // loading Closing
            reject(response.data.errors || {});
          }
        })
        .catch(function(error) {
          console.log("the error comes", error);
          commit("UNSET_LOADING"); // Ensure loading is closed
          if (error.response && error.response.status === 422) {
            // Pass validation errors to the component
            reject(error.response.data.errors);
          } else {
            // Handle other errors (optional)
            reject({ general: "An unexpected error occurred." });
          }
        });
    });
  },

  fetchRegStudentList({ commit }, searchParam) {
    return new Promise(function(resolve, reject) {
      commit("SET_LOADING", { color: "primary" });
      Axios.get(RegistrationBasics, { params: searchParam })
        .then(function(response) {
          commit("UNSET_LOADING");
          let result = response.data.data;
          //console.log(response);
          commit("REG_STUDENT_DATA", result);
          resolve(response.data);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
          reject();
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
